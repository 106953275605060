// import './index.css';

import Rupee from '@components/Rupee';
import closeDropDown from '@images/minusDark.svg';
import DropDown from '@images/plusDark.svg';
import FormCheckboxRadioGroup from '@lib/FormCheckboxRadioGroup';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CategoryContext } from '../../../../common/context/CategoryContext';
import {
  useCategoryAttributesForm,
  UseCategoryAttributesReturnType,
  useCategoryPrice,
} from '../../../../common/context/CategoryContext/hooks';

interface ProductAttributesFormProps {
  categoryAttributesForm: UseCategoryAttributesReturnType;
}

const ProductAttributesForm: React.FC<ProductAttributesFormProps> = ({ categoryAttributesForm }) => {
  const { priceLimits, categoryAttributes, categoryProductsSlice } = useContext(CategoryContext);
  const dispatch = useDispatch();
  const [isFiltersDropdownOpen, setIsFiltersDropdownOpen] = useState({});
  const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
  const { form } =
    categoryAttributesForm ||
    useCategoryAttributesForm({
      categoryProductsSlice,
      categoryAttributes,
      dispatch,
      shouldFetchOnFacetChange: true,
      shouldFetchOnPriceChange: true,
    });

  const { values, touched, submissions, handleChange, setValues, handleBlur, errors, asyncErrors } = form;
  const { PRICE_RANGES } = useCategoryPrice({
    priceLimits,
    noOfRanges: 5,
    currentMinPrice: values.minPrice,
    currentMaxPrice: values.maxPrice,
  });

  return (
    <form action="">
      {PRICE_RANGES?.length ? (
        <div className="mt-0">
          <div
            className="flex items-center justify-between text-16 md:text-20 leading-24 font-semibold cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setIsPriceDropdownOpen(!isPriceDropdownOpen);
            }}
          >
            <div
              className="justify-self-start text-textColor leading-19 md:leading-22 text-20 font-semibold mb-2 flex"
              font-customisation="section-heading"
              color-customisation="page-heading"
            >
              Price
            </div>
            <div className="justify-self-end">
              <div className="bg-navbar h-44 w-44 rounded-10 flex justify-center items-center">
                {isPriceDropdownOpen ? (
                  <img src={closeDropDown} alt="up" className="" />
                ) : (
                  <img src={DropDown} alt="Down" className="" />
                )}
              </div>
            </div>
          </div>
          {isPriceDropdownOpen && (
            <div className="md:overflow-y-scroll cursor-pointer">
              {PRICE_RANGES.map((priceRange, index) => {
                return (
                  <div key={index}>
                    <div
                      className={classnames(
                        'sideBar flex items-center relative mt-20 cursor-pointer text-disabled text-18 leading-22 font-normal',
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        setValues({
                          ...values,
                          ...(priceRange.isSelected
                            ? { minPrice: undefined, maxPrice: undefined }
                            : { minPrice: priceRange.minPrice, maxPrice: priceRange.maxPrice }),
                        });
                      }}
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      <input
                        className={classnames('hidden')}
                        id={index.toString()}
                        type="radio"
                        value={index}
                        checked={priceRange.isSelected}
                      />
                      <label className="flex">
                        <Rupee value={priceRange.minPrice} defaultClass={false} /> <span className="mx-2">-</span>
                        <Rupee value={priceRange.maxPrice} defaultClass={false} />
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}
      {categoryAttributes?.categoryAttributes?.results?.map((categoryAttribute) => (
        <div key={categoryAttribute.id}>
          {categoryAttribute?.filterType === 'radio' || categoryAttribute.filterType === 'checkbox' ? (
            <div className="leading-6 mt-40">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setIsFiltersDropdownOpen({
                    ...isFiltersDropdownOpen,
                    [categoryAttribute.id]: !isFiltersDropdownOpen[categoryAttribute.id],
                  });
                }}
                className="flex mb-2 items-center justify-between cursor-pointer"
              >
                <div
                  className="justify-self-start text-textColor leading-19 md:leading-22 text-20 font-semibold mb-2 flex flex-wrap w-220"
                  font-customisation="section-heading"
                  color-customisation="page-heading"
                >
                  {categoryAttribute.name}
                </div>
                <div className="justify-self-end">
                  <div className="bg-navbar h-44 w-44 rounded-10 flex justify-center items-center">
                    {isFiltersDropdownOpen[categoryAttribute.id] ? (
                      <img src={closeDropDown} alt="up" className="" />
                    ) : (
                      <img src={DropDown} alt="Down" className="" />
                    )}
                  </div>
                </div>
              </div>
              {isFiltersDropdownOpen[categoryAttribute.id] && (
                <div className="md:max-h-204 md:overflow-y-scroll sideBar">
                  <FormCheckboxRadioGroup
                    name={categoryAttribute.idString}
                    type={categoryAttribute.filterType}
                    value={values[categoryAttribute.idString] || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={categoryAttribute.values?.map((value) => ({ id: value.id, value: value.id }))}
                    inputClass="flex"
                    optionsClass="relative cursor-pointer text-disabled text-18 leading-22 font-normal mt-20 space-x-0"
                    optionsActiveClass="relative text-disabled text-18 leading-22 font-normal mt-20"
                    spaceY="space-y-20"
                    spaceX="space-x-0"
                    error={
                      (submissions || touched[categoryAttribute.idString]) &&
                      (errors[categoryAttribute.idString] || asyncErrors[categoryAttribute.idString])
                    }
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      ))}
    </form>
  );
};

export default ProductAttributesForm;
