import React from 'react';
import { Helmet } from 'react-helmet';

const NoIndex: React.FunctionComponent = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default NoIndex;
