import ProductAddToCartHOC from '@common/components/ProductAddToCartHOC';
import Product from '@common/models/Product';
import { addProductToCart } from '@common/store/cart/slice';
import { subtractProductFromCart } from '@common/store/cart/slice';
import { addToast } from '@common/store/toasts/slice';
import Gallery from '@components/Gallery';
import ProductAddToCart from '@components/ProductAddToCart';
// import Rating from '@components/Rating';
import StarRating from '@components/Rating/StarRating';
import Share from '@components/Share';
import Editor from '@lib/Editor';
// import StarRating from '@components/Rating/StarRating';
// import Share from '@components/Share';
// import Close from '@images/Close.svg';
// import Rupee from '@components/Rupee';
// import Editor from '@lib/Editor';
import classnames from 'classnames';
import React from 'react';

interface EventProps {
  product: Product;
  addProductToCart: typeof addProductToCart;
  cartProductQuantity: number;
  addToast: typeof addToast;
  storeType: string;
  subtractProductFromCart: typeof subtractProductFromCart;
  quickView?: boolean;
  onClose?: () => void;
  reviews: number;
  review: number;
  scrollToReview?: () => void;
  rating?: unknown;
  wishListFeature: boolean;
  productVideoFeature: boolean;
  storeInfo: boolean;
}

const Event: React.FC<EventProps> = ({
  product,
  addProductToCart,
  subtractProductFromCart,
  cartProductQuantity,
  addToast,
  storeType,
  review,
  // reviews,
  // rating,
  // onClose,
  quickView,
  wishListFeature,
  productVideoFeature,
  storeInfo,
}) => {
  return (
    <ProductAddToCartHOC
      variantSetToMinQuantity={true}
      disableSuccessToasts={() => false}
      product={product}
      render={(hocArgs) => {
        const { finalProduct } = hocArgs;
        const StarRatingValue = finalProduct.averageRating;

        // const StarRatingValue = finalProduct.averageRating;
        return (
          <div className={classnames('w-full', { '': !quickView, 'pt-0': quickView })}>
            <div className={classnames(`bg-white rounded-md `)}>
              <div className="flex flex-wrap md:flex-no-wrap">
                <div className={classnames('w-full md:w-fit')}>
                  <Gallery
                    isZoomEnabled={false}
                    images={finalProduct.images.length ? finalProduct.images : product.images}
                    quickView={quickView}
                    inStock={finalProduct.inStock}
                    imageDescription={`${finalProduct.images.length ? finalProduct.title : product.title}${
                      finalProduct?.optionValues?.length ? '_' + finalProduct?.optionValues.join('_') : ''
                    }`}
                    productVideoUrl={finalProduct.productVideoUrl}
                    productVideoEnabled={productVideoFeature ? finalProduct.productVideoEnabled : false}
                  />
                </div>
                <div
                  className={classnames(`flex flex-col mt-20 md:mt-0 w-full`, {
                    'md:ml-30 xl:ml-64': !quickView,
                    'md:ml-58 max-w-450 py-10': quickView,
                  })}
                >
                  <div>
                    <div
                      className={classnames('flex font-bold justify-center md:justify-start text-center md:text-left', {
                        'text-textColor text-28 md:text-34 leading-36 md:leading-40': true,
                        'mb-15': StarRatingValue > 0,
                      })}
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                    >
                      {finalProduct.title}
                    </div>
                    <div
                      className={classnames('flex flex-col md:flex-row items-center flex-no-wrap ', {
                        'md:border-b md:border-slideBorder md:pb-24': StarRatingValue > 0,
                      })}
                    >
                      {parseInt(StarRatingValue) > 0 ? (
                        <div className="flex md:pr-6 ">
                          <StarRating
                            rating={parseInt(StarRatingValue) ? parseInt(StarRatingValue) : 0}
                            halfRating={parseInt(StarRatingValue) ? (Number.isInteger(StarRatingValue) ? 0 : 1) : 0}
                            total={5}
                            isInteractive={false}
                            className="mr-4"
                          />
                        </div>
                      ) : null}
                      {StarRatingValue > 0 ? (
                        <div className="font-normal items-center leading-24 text-18 flex text-disabled mt-16 md:mt-2">
                          <div>
                            {StarRatingValue}
                            <span className="font-normal text-disabled text-opacity-30 ml-8 mr-8">|</span>
                          </div>
                          <div
                            onClick={() => {
                              if (!quickView) {
                                const elmntToView = document.getElementById('Review');
                                elmntToView.scrollIntoView();
                              }
                            }}
                            className=" cursor-pointer"
                          >
                            {review > 1 ? review + ' ' + 'Reviews' : review + ' ' + 'Review'}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <ProductAddToCart
                      taxIncludedInventory={storeInfo?.storeInfo?.tax_included_inventory}
                      product={product}
                      hocArgs={hocArgs}
                      addToast={addToast}
                      cartProductQuantity={cartProductQuantity}
                      addProductToCart={addProductToCart}
                      subtractProductFromCart={subtractProductFromCart}
                      storeType={storeType}
                      isEvent={true}
                      quickView={quickView}
                      review={review}
                      wishListFeature={wishListFeature}
                    />
                  </div>
                  {!quickView && (
                    <>
                      {product?.description ? (
                        <div className="mt-40 md:mt-44">
                          <div
                            className="text-textColor text-21 leading-30 mb-30 md:mb-25 font-bold"
                            font-customisation="section-heading"
                            color-customisation="page-heading"
                          >
                            Event Description
                          </div>
                          <div className="description px-0">
                            <Editor
                              name="productLongDescription"
                              value={finalProduct.description}
                              readonly={true}
                              isDefaultFont={true}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="w-full flex md:hidden justify-center">
                        <div className="bg-white rounded-10 p-24 min-w-full w-full items-center md:mx-auto mt-40 border border-share-border">
                          <Share className="w-full" product={product} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default Event;
