import ProductCards from '@common/components/ProductCards';
import { computeProductLink, PRODUCT_REFERRED_FROM, PRODUCT_TYPE } from '@common/models/Product';
import { RootState } from '@common/store';
import { addProductToCart, subtractProductFromCart } from '@common/store/cart/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import {
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import history from '@common/utils/history';
import { getMerchantJWTToken } from '@common/utils/token';
import Event from '@components/Event';
import QuickViewProduct from '@components/Product';
import ProductCardOverlay from '@components/ProductCardOverlay';
import Stepper from '@components/Stepper';
import Toasts from '@components/Toasts';
import Button from '@lib/Button';
import Image from '@lib/Image';
import Cart from '@Spring/images/CartImg.svg';
import Close from '@Spring/images/Close.svg';
import { LAYOUT_CONFIG } from '@theme/utils/constants';
import classnames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { EmptyProductCard } from '../../../../common/components/EmptyProductCard';
import Modal from './Modal';

export interface ProductCardProps {
  product: Product;
  storeInfo: StoreInfoSliceType;
  cartProductQuantity: number;
  productDetails: ProductDetailsSliceType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  hideQuickView?: boolean;
  hideCTA?: boolean;
  referredFrom?: PRODUCT_REFERRED_FROM;
  shopAll?: string;
  truncate?: number;
  features: FeaturesSliceType;
  showAddToCartButton?: boolean;
}

const ProductCard: React.FunctionComponent<ProductCardProps> = ({
  product,
  addProductToCart,
  subtractProductFromCart,
  cartProductQuantity,
  productDetails,
  toasts,
  removeToast,
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  storeInfo,
  hideQuickView,
  hideCTA,
  referredFrom,
  shopAll,
  features,
  showAddToCartButton,
}) => {
  let dimensionW, dimensionH;
  if (shopAll === 'shopAll') {
    dimensionH = 'h-165 md:h-280';
    dimensionW = 'w-165 md:w-280';
  } else {
    dimensionH = 'h-220 md:h-280';
    dimensionW = 'w-220 md:w-280';
  }

  if (!product) {
    return <EmptyProductCard dimensionW={dimensionW} />;
  }

  const [CloseDetails, SetCloseDetails] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Hover, SetHover] = useState(false);
  const computedProductLink = computeProductLink({ product, referredFrom });
  return (
    <div>
      {CloseDetails && productDetails.productDetails ? (
        <Modal
          heading=""
          onClose={() => {
            document.body.style.overflow = 'scroll';
            SetCloseDetails(!CloseDetails);
          }}
          closeModal={() => {
            document.body.style.overflow = 'scroll';
            SetCloseDetails(false);
          }}
          className={classnames('zoomIn modal-open QuickView md:w-fit z-999 md:mx-0 md:mb-100 rounded-20', {
            'mt-40 md:mt-0': !getMerchantJWTToken(),
            'mt-120 md:mt-0': getMerchantJWTToken(),
          })}
        >
          <div className="relative">
            {toasts.length ? (
              <div className="w-369 max-w-410 hidden md:block max-auto" id="QuickViewToasts">
                <Toasts toasts={toasts} addToast={addToast} removeToast={removeToast} quickView={true} />
              </div>
            ) : null}
            <div className="flex justify-between mb-30 md:mb-16 items-center">
              <Image
                src={Close}
                alt="Close"
                className="float-right absolute hidden md:block right-0 mt-10 cursor-pointer h-12 w-12 md:h-14 md:w-14"
                onClick={() => {
                  document.body.style.overflow = 'scroll';
                  SetCloseDetails(!CloseDetails);
                }}
                noDefaultDimensions
                noDefaultFit
              />
            </div>
            <div className="overflow-x-auto hideScroll">
              {productDetails.productDetails.isEvent ? (
                <Event
                  subtractProductFromCart={subtractProductFromCart}
                  product={productDetails.productDetails}
                  addProductToCart={addProductToCart}
                  addToast={addToast}
                  cartProductQuantity={cartProductQuantity}
                  isZoomEnabled={false}
                  storeType={storeInfo.storeInfo?.storeType}
                  quickView={true}
                  review={productDetails?.reviews?.length}
                  productVideoFeature={features.features?.productVideo}
                />
              ) : (
                <QuickViewProduct
                  subtractProductFromCart={subtractProductFromCart}
                  product={productDetails.productDetails}
                  addProductToCart={addProductToCart}
                  addToast={addToast}
                  cartProductQuantity={cartProductQuantity}
                  isZoomEnabled={false}
                  storeType={storeInfo.storeInfo?.storeType}
                  review={productDetails.reviews?.length}
                  quickView={true}
                  productVideoFeature={features.features?.productVideo}
                />
              )}
            </div>
            <div className="md:hidden pt-24"></div>
          </div>
        </Modal>
      ) : null}

      <ProductCards
        product={product}
        addProductToCart={addProductToCart}
        referredFrom={referredFrom}
        cartProductQuantity={cartProductQuantity}
        subtractProductFromCart={subtractProductFromCart}
        imageDivClassNames="rounded-20 border border-black border-opacity-10"
        discountPercentClassNames={classnames(
          'top-0 right-0 bg-productCard-badgeBg rounded-full text-productCardDiscountPercentSm md:text-productCardDiscountPercentMd text-productCard-badgeText py-6 px-10 m-12 font-bold',
          {
            hidden: LAYOUT_CONFIG?.productList?.showDiscountLabel === false,
          },
        )}
        titleClassName={`font-semibold text-productCardTitleSm md:text-productCardTitleMd md:w-260 text-productCard-title md:leading-28 leading-24 mb-24 md:mb-16 line-clamp-2 break-word-only`}
        priceClassName={
          'font-semibold leading-22 md:leading-20 text-red text-productCardPriceSm md:text-productCardPriceMd mr-10 text-productCard-finalPrice'
        }
        discountPriceClassName={
          'line-through leading-22 md:leading-20 text-productCardDiscountPriceSm md:text-productCardDiscountPriceMd text-productCard-mrp'
        }
        includeTaxClassName={'hidden'}
        infoMainDivClassName="px-10 pt-20"
        dimensionH={dimensionH}
        dimensionW={dimensionW}
        quickViewDiv={
          !hideQuickView && (
            <ProductCardOverlay
              product={product}
              fetchProductDetailsRequest={fetchProductDetailsRequest}
              fetchProductReviewsRequest={fetchProductReviewsRequest}
              removeToast={removeToast}
              toasts={toasts}
              CloseDetails={CloseDetails}
              SetCloseDetails={SetCloseDetails}
              computedProductLink={computedProductLink}
            />
          )
        }
        viewDetailsButton={
          product.type && product.type !== PRODUCT_TYPE.DIGITAL && product.inStock && !hideCTA ? (
            <div className="mt-20">
              {product.payWhatYouWant || product.hasOptions || product.hasVariants ? (
                <Button
                  className="rounded w-150 px-16 py-12 justify-center font-semibold items-center border border-optionBorder text-textColor text-productCardViewDetailsSm md:text-productCardViewDetailsMd"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(computedProductLink);
                  }}
                  button-customisation="page-button"
                  font-customisation="para-text"
                >
                  View Details
                </Button>
              ) : cartProductQuantity ? (
                <Stepper
                  className="border-optionBorder border px-16 py-10"
                  product={product}
                  subtractProductFromCart={subtractProductFromCart}
                  addProductToCart={addProductToCart}
                  quantity={cartProductQuantity}
                  cartProductQuantity={cartProductQuantity}
                  mixpanelProperties={{ source: 'theme-listing' }}
                  productCard={true}
                />
              ) : (
                showAddToCartButton && (
                  <Button
                    className="rounded w-158 px-16 py-12 flex justify-center font-semibold items-center border border-optionBorder text-textColor text-productCardAddToCardSm md:productCardAddToCardMd"
                    onClick={(e) => {
                      e.preventDefault();
                      addProductToCart({
                        product,
                        disableSuccessToasts: true,
                        mixpanelProperties: {
                          source: 'theme-listing',
                        },
                      });
                    }}
                    button-customisation="addToCart-button"
                    font-customisation="para-text"
                  >
                    <Image src={Cart} className="h-14 w-14 mr-12" alt="cart" noDefaultDimensions noDefaultFit />
                    Add to Cart
                  </Button>
                )
              )}
            </div>
          ) : (
            <div className="mt-20">
              <Button
                className="rounded w-150 px-16 py-12 justify-center items-center border border-optionBorder text-textColor font-semibold text-productCardViewDetailsSm md:text-productCardViewDetailsMd"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(computedProductLink);
                }}
                font-customisation="para-text"
                button-customisation="page-button"
              >
                View Details
              </Button>
            </div>
          )
        }
      />
    </div>
  );
};

export default connect(
  ({ productDetails, toasts, features }: RootState) => ({
    productDetails,
    toasts,
    features,
  }),
  {
    fetchProductDetailsRequest,
    fetchProductReviewsRequest,
    removeToast,
  },
)(ProductCard);
