import { ReactComponent as EmptyStarIcon } from '@common/icons/EmptyStar.svg';
import { ReactComponent as StarIcon } from '@common/icons/FullStar.svg';
import React from 'react';

export interface StarRatingProps {
  className?: string;
  rating?: number;
  total?: number;
  isInteractive?: boolean;
  onChange?: (val: number) => void;
}

const StarRating: React.FunctionComponent<StarRatingProps> = ({
  className = '',
  rating = 0,
  total,
  isInteractive = false,
  onChange,
}) => {
  return (
    <div className={`flex ${className}`}>
      {new Array(rating).fill(rating).map((value, index) => (
        <StarIcon
          className="mr-4"
          key={`active-${index}`}
          onClick={() => {
            if (isInteractive) {
              onChange(index + 1);
            }
          }}
        />
      ))}
      {new Array(total - rating).fill(total - rating).map((value, index) => (
        <EmptyStarIcon
          className="mr-4"
          key={`empty-${index}`}
          onClick={() => {
            if (isInteractive) {
              onChange(rating + index + 1);
            }
          }}
        />
      ))}
    </div>
  );
};

export default StarRating;
