import Product from '@common/models/Product';
import { removeProductFromCart } from '@common/store/cart/slice';
import { CloseCircleIcon } from '@instamojo/icons';
import Modal from '@lib/Modal';
import classNames from 'classnames';
import React from 'react';

export interface DeleteBundleProductProps {
  className?: string;
  textClassName?: string;
  showRemoveItemAlert?: boolean;
  bundleProduct: Product;
  handleCloseRemoveAlert: () => void;
  setShowRemoveItemAlert: (flag: boolean) => void;
  removeProductFromCart: typeof removeProductFromCart;
}

const DeleteProductBundleModal: React.FunctionComponent<DeleteBundleProductProps> = ({
  showRemoveItemAlert,
  bundleProduct,
  handleCloseRemoveAlert,
  setShowRemoveItemAlert,
  removeProductFromCart,
  className,
  textClassName,
}) => {
  return (
    <>
      {showRemoveItemAlert && (
        <Modal closeModal={handleCloseRemoveAlert} className="md:w-360">
          <div className="flex justify-between">
            <h2 className="text-18 font-medium">Remove Product</h2>
            <button onClick={handleCloseRemoveAlert} className="focus:outline-none">
              <CloseCircleIcon className="w-24 h-24 text-red" />
            </button>
          </div>
          <p className="text-button-red text-14 pt-16">
            This product is part of an offer deal. Deleting this product will also remove the offers applied to all
            other products in this deal.
          </p>
          <p className="pt-10 pb-32 text-14">Are you sure you want to remove this Product?</p>
          <div className="flex flex-col">
            <button
              className={classNames('px-24 border flex-1  py-12 text-14 text-white rounded', {
                [className]: className,
                'bg-theme border-theme': !className,
              })}
              onClick={() => {
                setShowRemoveItemAlert(false);
                removeProductFromCart({ product: bundleProduct });
              }}
            >
              Yes, Remove
            </button>
            <button
              className={classNames('px-24 py-12 mt-16 border-none flex-1   rounded text-14 font-medium', {
                [textClassName]: textClassName,
                'text-theme': !textClassName,
              })}
              onClick={handleCloseRemoveAlert}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteProductBundleModal;
