import ProductBundle from '@common/models/ProductBundle';
import { InfoIcon } from '@instamojo/icons';
import Rupee from '@instamojo/rupee';
import classnames from 'classnames';
import React from 'react';

import ProductBundleCard from '../ProductBundleCard';

export interface ProductBundleProps {
  className?: string;
  priceClassName?: string;
  borderClassName?: string;
  productsBundle: ProductBundle;
  productId: number;
  addToBundleProductInCart: (bundle: ProductBundle) => void;
  deletePopupShow: boolean;
  discountPercentageColor?: string;
}

const ProductBundleData: React.FunctionComponent<ProductBundleProps> = ({
  productsBundle,
  addToBundleProductInCart,
  priceClassName,
  className,
  borderClassName,
  discountPercentageColor,
}) => {
  let productTotalPrice = null;
  let productBundleDiscountedAmount = null;

  productsBundle?.products.forEach(function (product) {
    if (!product.bundleDetails.primaryProduct) {
      productTotalPrice += product.price;
      productBundleDiscountedAmount += product.bundleDetails?.discountedPrice;
    }
  });

  const calcPercentage = Math.round(
    ((productsBundle.bundlePriceWithoutDiscount - productsBundle.bundleDiscountedPrice) /
      productsBundle.bundlePriceWithoutDiscount) *
      100,
  );

  return (
    <>
      <div className="w-full">
        <div className="md:text-32 text-18 font-semibold text-productBundle-heading">{productsBundle.title}</div>
        <p className="md:text-24 text-15 text-productBundle-gray md:mb-50 mb-16">{productsBundle.description}</p>
        <div className="overflow-x-auto">
          <div className="flex w-1140 md:w-full">
            {productsBundle?.products?.map(
              (product) =>
                product.bundleDetails.primaryProduct && (
                  <div className="w-3/12 opacity-50" key={product.id}>
                    <ProductBundleCard
                      product={product}
                      priceClassName={priceClassName}
                      discountPercentageColor={discountPercentageColor}
                      calcPercentage={calcPercentage}
                      isBaseProduct={true}
                      borderClassName={borderClassName}
                      cartAlign={'justify-between'}
                    />
                  </div>
                ),
            )}
            <span className="flex min-h-full items-center pl-10 pr-6 text-32">+</span>
            <div className="flex flex-wrap items-stretch justify-between bg-productBundle-lightGray p-32 rounded-lg bundleproduct-list ml-32">
              {productsBundle?.products?.map(
                (product) =>
                  !product.bundleDetails.primaryProduct && (
                    <>
                      <div className="md:block md:w-220 bundle-product" key={product.id}>
                        <ProductBundleCard
                          product={product}
                          priceClassName={priceClassName}
                          discountPercentageColor={discountPercentageColor}
                          calcPercentage={calcPercentage}
                          isBaseProduct={false}
                          cartAlign={'justify-between'}
                        />
                      </div>
                    </>
                  ),
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center border-2 border-divider border-l-0 border-r-0 mt-50 py-8 md:flex-row flex-col">
          <div className="flex justify-center md:justify-between w-full md:w-7/12 items-center md:flex-row flex-col md:text-left text-center">
            <div>
              <div className="text-20 font-semibold text-productBundle-dark">1 Item</div>
              {productsBundle?.products?.map(
                (product) =>
                  product.bundleDetails.primaryProduct && (
                    <div className="flex flex-row-reverse items-center" key={product.id}>
                      {product.bundleDetails.discountedPrice && (
                        <>
                          <div className="line-through font-normal text-20 text-productBundle-gray">
                            <span className="rupee-container " data-testid="rupee-value-container">
                              ₹{product?.price}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <div
                              className={classnames(
                                'font-medium text-20 mr-14',
                                priceClassName ? priceClassName : 'text-theme',
                              )}
                            >
                              <span className="rupee-container " data-testid="rupee-value-container">
                                <Rupee value={product.bundleDetails.discountedPrice} removeSpaceAfterRupee />
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ),
              )}
            </div>
            <div className="text-32 opacity-50">+</div>
            <div>
              <div className="text-20 font-semibold text-productBundle-dark">
                {productsBundle?.products.length - 1}
                {productsBundle?.products.length == 2 ? ' item' : ' items'}
              </div>
              <div className="flex flex-row-reverse items-center justify-end">
                <>
                  <div className="line-through font-normal text-20 text-productBundle-gray">
                    <span className="rupee-container " data-testid="rupee-value-container">
                      <Rupee value={productTotalPrice} removeSpaceAfterRupee />
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div
                      className={classnames(
                        'font-medium text-20 mr-14',
                        priceClassName ? priceClassName : 'text-theme',
                      )}
                    >
                      <span className="rupee-container " data-testid="rupee-value-container">
                        <Rupee value={productBundleDiscountedAmount} removeSpaceAfterRupee />
                        {/* ₹{Math.round(productBundleDiscountedAmount).toFixed(2)} */}
                      </span>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div className="text-32 opacity-50">=</div>
            <div>
              <div className="text-20 font-semibold text-productBundle-dark">Deal Price</div>
              <div className="flex flex-row-reverse items-center justify-end">
                {productsBundle?.bundleDiscountedPrice ? (
                  <>
                    <div className="line-through font-normal text-20 text-productBundle-gray">
                      <span className="rupee-container " data-testid="rupee-value-container">
                        <Rupee value={productsBundle?.bundlePriceWithoutDiscount} removeSpaceAfterRupee />
                      </span>
                    </div>
                    <div className="flex items-center">
                      <div
                        className={classnames(
                          'font-medium text-20 mr-14',
                          priceClassName ? priceClassName : 'text-theme',
                        )}
                      >
                        <span className="rupee-container " data-testid="rupee-value-container">
                          <Rupee value={productsBundle?.bundleDiscountedPrice} removeSpaceAfterRupee />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center">
                    <div className="text-theme font-medium text-20 mr-14">
                      <span className="rupee-container " data-testid="rupee-value-container">
                        <Rupee value={productsBundle?.bundlePriceWithoutDiscount} removeSpaceAfterRupee />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center md:justify-between">
            <button
              type="submit"
              className={classnames(
                'rounded  min-w-260 h-40 px-12 flex justify-center items-center  disabled:cursor-not-allowed disabled:bg-disabled my-24 w-full',
                className ? className : 'bg-theme text-white',
              )}
              onClick={() => addToBundleProductInCart({ ...productsBundle, location: 'Product Details' })}
            >
              <span>Add This Deal To Cart</span>
            </button>
          </div>
        </div>
        <p className="text-productBundle-darkGray font-medium	 text-16 mt-24 flex">
          <InfoIcon className="inline-block align-middle" color="#38383E" />
          Buy all products in this deal to avail the offer
        </p>
      </div>
    </>
  );
};

export default ProductBundleData;
