import './index.css';

import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, useState } from 'react';

import useDimensions from '../../common/hooks/useDimensions';

export interface FormTextAreaProps {
  name: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (e: FocusEvent<HTMLTextAreaElement>) => void;
  value: string;
  className?: string;
  error?: React.ReactNode;
  hint?: React.ReactNode;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  value,
  label,
  onChange,
  className = '',
  error,
  hint,
  onBlur,
}) => {
  const [ref, dimensions] = useDimensions();
  const [isFocused, setIsFocused] = useState(false);
  const labelAtTop = isFocused || value;
  return (
    <div className={className}>
      <div
        ref={ref}
        className={classnames(
          'form-textarea-wrapper text-15 relative border rounded border-input-border focus-within:border-theme focus-within:border-2',
          {
            'form-textarea-wrapper--error border-input-error focus-within:border-input-error': error,
          },
        )}
      >
        <textarea
          className="block w-full focus:outline-none bg-transparent px-16 py-10 h-full rounded"
          rows={5}
          id={name}
          name={name}
          placeholder=" "
          autoComplete="new-password"
          onFocus={() => setIsFocused(true)}
          value={value}
          onChange={(e) => onChange(e)}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur(e);
          }}
        />
        <label
          className="block top-0 px-16 mt-14 absolute pointer-events-none text-input-label opacity-75 overflow-auto"
          htmlFor={name}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: labelAtTop ? 'nowrap' : 'normal',
            // eslint-disable-next-line
            // @ts-ignore
            maxWidth: `${dimensions?.width - (labelAtTop ? 28 : 0)}px`,
          }}
        >
          {label}
        </label>
      </div>
      <div className="text-input-hint opacity-70 text-12 mt-2">
        {error ? <span className="text-input-error opacity-100">{error}</span> : null}
        {hint && !error ? <span>{hint}</span> : null}
      </div>
    </div>
  );
};

export default FormTextArea;
