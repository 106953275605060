import { postData, URLs } from '@common/api';
import StarRating from '@common/components/Rating/StarRating';
import { useConstructor } from '@common/hooks/useConstructor';
import { ReactComponent as BreadcrumbRightArrow } from '@common/icons/BreadcrumbRightArrow.svg';
import { RootState, store } from '@common/store';
import {
  fetchProductDetailsRequest,
  // fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import { addToast } from '@common/store/toasts/slice';
import { smoothScroll } from '@common/utils';
// import { addToast } from '@common/store/toasts/slice';
import PageLayout from '@components/PageLayout';
import Button from '@lib/Button';
import FormInput from '@lib/FormInput';
import FormTextArea from '@lib/FormTextArea';
import Image from '@lib/Image';
import Link from '@lib/Link';
import { ToastVariant } from '@lib/Toast';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';

interface MatchParams {
  productId: string;
  productSlug: string;
}

interface ProductReviewsProps extends RouteComponentProps<MatchParams> {
  productDetails: ProductDetailsSliceType;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
}

const ProductReviews: React.FC<ProductReviewsProps> = ({ productDetails, fetchProductDetailsRequest }) => {
  const { productId, productSlug } = useParams<MatchParams>();
  const [name, setName] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  useConstructor(() => {
    if (productDetails.productId !== productId) {
      fetchProductDetailsRequest(productSlug);
    }
    smoothScroll(0, 0);
  }, [productId]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!rating) {
        store.dispatch(
          addToast({
            content: 'Please add the Star rating',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
        return;
      }
      if (!name) {
        store.dispatch(
          addToast({
            content: 'Please add your name',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
        return;
      }
      if (!review) {
        store.dispatch(
          addToast({
            content: 'Please add the review',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
        return;
      }

      await postData({
        url: URLs.POST_PRODUCT_REVIEW,
        data: {
          product__slug: productSlug,
          customer_name: name,
          rating,
          review,
        },
      });
      store.dispatch(
        addToast({
          content: 'Successfully added the review',
          variant: ToastVariant.success,
          dismissAfterMillis: 5000,
        }),
      );
    } catch (e) {
      store.dispatch(
        addToast({
          content: (e?.response?.data?.product || [])[0] || 'Could not add the review',
          variant: ToastVariant.error,
          dismissAfterMillis: 5000,
        }),
      );
    }
  };

  const product = productDetails.productDetails;

  return (
    <PageLayout>
      <div className="container mx-auto my-24">
        <div className="flex items-center text-14 mb-24" font-customisation="para-text">
          <Link to="/" className="mr-12 text-theme cursor-pointer" color-customisation="page-body-text">
            Home
          </Link>
          <BreadcrumbRightArrow className="mr-14" />
          <Link
            className="mr-12 text-theme cursor-pointer"
            to={`/product/${product?.slug}`}
            color-customisation="page-body-text"
          >
            {product?.title ?? ''}
          </Link>
          <BreadcrumbRightArrow className="mr-14" />
          <div className="text-label" color-customisation="page-body-text">
            Review
          </div>
        </div>
        <div className="shadow px-16 py-16 bg-white rounded-sm">
          <div className="flex border border-divider border-t-0 border-l-0 border-r-0 border-blur py-16 mb-32">
            <div className="w-120 h-120 mr-16">
              <Image
                src={product?.images[0]}
                alt={product?.title}
                className="mr-16 rounded border border-gallery-normal"
              />
            </div>
            <div
              className="font-bold text-24 break-all"
              font-customisation="section-heading"
              color-customisation="page-heading"
              style={{ width: 'calc(100% - 136px)' }}
            >
              {product?.title}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              className="text-label text-14 mb-8"
              font-customisation="para-text"
              color-customisation="page-body-text"
            >
              Overall rating for this product
            </div>
            <StarRating
              rating={rating}
              total={5}
              isInteractive={true}
              onChange={(rating) => setRating(rating)}
              className="mb-32"
            />

            <div
              className="text-label text-14 mb-8"
              font-customisation="para-text"
              color-customisation="page-body-text"
            >
              Your Name
            </div>
            <FormInput
              name="issue"
              className="md:pr-12 w-full md:w-1/2 mb-32"
              label=""
              type="text"
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onBlur={() => {
                return null;
              }}
            />
            <div
              className="text-label text-14 mb-8"
              font-customisation="para-text"
              color-customisation="page-body-text"
            >
              Write Your Review
            </div>

            <FormTextArea
              name="message"
              className="md:pr-12 w-full md:w-1/2"
              label=""
              value={review || ''}
              onChange={(e) => {
                setReview(e.target.value);
              }}
              onBlur={() => {
                return null;
              }}
            />
            <Button
              type="submit"
              className="font-medium text-14 bg-theme text-white mt-32 h-40 w-220 flex justify-center items-center"
              button-customisation="page-button"
              font-customisation="para-text"
            >
              Submit Review
            </Button>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default connect(
  ({ productDetails }: RootState) => ({
    productDetails,
  }),
  {
    fetchProductDetailsRequest,
  },
)(ProductReviews);
