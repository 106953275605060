import CustomLink from '@common/components/CustomLink';
import Category from '@common/models/Category';
import { categoryParentClassName } from '@common/utils/constants';
import Arrow from '@images/DropDown.svg';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';

export interface CategoryDropdownProps {
  className?: string;
  category: Category;
}

const CategoryDropdown: React.FunctionComponent<CategoryDropdownProps> = ({ category }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(-1);
  const [subDropdownOpen, setsubDropdownOpen] = useState(-1);
  const [subsubDropdownOpen, setsubsubDropdownOpen] = useState(-1);
  const divRef = useRef(null);
  return (
    <div
      onMouseLeave={() => {
        setIsDropdownOpen(false);
        setsubDropdownOpen(false);
        setsubsubDropdownOpen(false);
      }}
      className="cursor-pointer"
    >
      <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(-1)}>
        <div
          onMouseOver={() => {
            if (category?.childCategories?.length) {
              setIsDropdownOpen(category.id);
            }
          }}
          onClick={() => {
            if (category?.childCategories?.length) {
              setIsDropdownOpen(category.id);
            }
          }}
          ref={divRef}
          className={`select-none md:h-70 capitalize flex justify-between items-center relative cursor-pointer ${categoryParentClassName(
            category,
          )}`}
        >
          <CustomLink link={category?.storeLink} isExternal={category?.isExternalLink} category={category}>
            <span
              className="whitespace-no-wrap text-16 text-nav"
              font-customisation="section-heading"
              color-customisation="header-links"
              id={'mobile-' + category.name.toLowerCase().split(' ').join('-')}
            >
              {category?.id === 0 ? 'Categories' : category?.name}
            </span>
          </CustomLink>
        </div>
        {category?.childCategories?.length && isDropdownOpen === category.id ? (
          <div className="absolute z-10 mt-4 md:-mt-20">
            <div className="bg-white border border-storeName border-opacity-20 rounded-2 shadow-sm">
              {category?.childCategories?.map((subCategory) => (
                <div key={subCategory.id}>
                  <div
                    className="flex justify-between relative items-center px-20 hover:bg-optionHover  border-b border-sortBorder"
                    onClick={() => {
                      if (subCategory?.childCategories?.length) {
                        setsubDropdownOpen(subDropdownOpen === subCategory.id ? -1 : subCategory.id);
                      }
                    }}
                  >
                    <CustomLink
                      className="text-14 leading-25 text-textColor hover:text-nav p-8"
                      link={subCategory.storeLink}
                      isExternal={subCategory?.isExternalLink}
                      font-customisation="section-heading"
                      color-customisation="header-links"
                    >
                      {subCategory.name}
                    </CustomLink>
                    {subCategory?.childCategories?.length ? (
                      <div
                        className="w-full text-14 text-right menu-arrow"
                        onClick={() => {
                          if (subCategory?.childCategories?.length) {
                            setsubDropdownOpen(subDropdownOpen === subCategory.id ? -1 : subCategory.id);
                          }
                        }}
                      >
                        <img
                          src={Arrow}
                          alt="Arrow"
                          className={classnames('w-10 h-6 menu-arrow inline-block', {
                            'transform rotate-0': subDropdownOpen === subCategory.id,
                            'transform -rotate-90': subDropdownOpen != subCategory.id,
                          })}
                        />
                      </div>
                    ) : null}
                  </div>
                  {subCategory?.childCategories?.length && subDropdownOpen === subCategory.id ? (
                    <div className={classnames('w-full')}>
                      {subCategory.childCategories?.map((subSubCategory) => (
                        <div
                          key={subSubCategory.id}
                          className=""
                          onClick={() => {
                            if (subSubCategory?.childCategories?.length) {
                              setsubsubDropdownOpen(subsubDropdownOpen === subSubCategory.id ? -1 : subSubCategory.id);
                            }
                          }}
                        >
                          <div className="flex items-center justify-between px-20 hover:bg-optionHover  border-b border-sortBorder">
                            <CustomLink
                              className="text-textColor text-14 leading-25 pl-10 hover:text-nav"
                              link={subSubCategory.storeLink}
                              isExternal={subSubCategory?.isExternalLink}
                              font-customisation="section-heading"
                              color-customisation="header-links"
                            >
                              {subSubCategory.name}
                            </CustomLink>
                            {subSubCategory?.childCategories?.length ? (
                              <div
                                className="w-full text-right menu-arrow"
                                onClick={() => {
                                  if (subSubCategory?.childCategories?.length) {
                                    setsubsubDropdownOpen(
                                      subsubDropdownOpen === subSubCategory.id ? -1 : subSubCategory.id,
                                    );
                                  }
                                }}
                              >
                                <img
                                  src={Arrow}
                                  alt="Arrow"
                                  className={classnames('w-10 h-6 inline-block menu-arrow', {
                                    'transform rotate-0': subsubDropdownOpen === subSubCategory.id,
                                    'transform -rotate-90': subsubDropdownOpen != subSubCategory.id,
                                  })}
                                />
                              </div>
                            ) : null}
                          </div>
                          {subSubCategory?.childCategories?.length && subsubDropdownOpen === subSubCategory.id ? (
                            <div className="w-full">
                              {subSubCategory.childCategories?.map((cat) => (
                                <div key={cat.id} className="hover:bg-optionHover  border-b border-sortBorder">
                                  <CustomLink
                                    className="p-10 pl-30 flex w-full text-textColor hover:text-nav text-14 leading-25"
                                    link={cat.storeLink}
                                    isExternal={cat?.isExternalLink}
                                    font-customisation="section-heading"
                                    color-customisation="header-links"
                                  >
                                    {cat.name}
                                  </CustomLink>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </OutsideClickHandler>
    </div>
  );
};

export default CategoryDropdown;
