import { UseProductAttributesReturnType } from '@common/context/ShopAllContext/hooks';
import React from 'react';

import ProductAttributesForm from './ProductAttributesForm';

interface ShopAllSidebarProps {
  shopAllAttributesForm?: UseProductAttributesReturnType;
  hideForms?: boolean;
}

const ShopAllSidebar: React.FC<ShopAllSidebarProps> = ({ shopAllAttributesForm, hideForms }) => {
  return (
    <div className="text-black">
      {!hideForms ? <ProductAttributesForm shopAllAttributesForm={shopAllAttributesForm} /> : null}
    </div>
  );
};

export default ShopAllSidebar;
