import SearchImg from '@common/icons/Search.svg';
import { fetchSearchRequest, getSearchQueryParamsInitialState, SearchSliceType } from '@common/store/search/slice';
// import { isMobile } from '@common/utils';
import { SEARCH_KEY } from '@common/utils/constants';
import { ERROR_TEXT } from '@common/utils/errorContent';
import history from '@common/utils/history';
// import Rupee from '@common/components/Rupee';
import Button from '@lib/Button';
import Error from '@lib/Error';
import Image from '@lib/Image';
import Link from '@lib/Link';
import OutsideClick from '@lib/OutsideClickHandler';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

interface SearchProps {
  className?: string;
  fetchSearchRequest: typeof fetchSearchRequest;
  search: Record<string, SearchSliceType>;
  closeSearch?: () => void;
  searchInputId: string;
  searchBtnId: string;
}

const Search: React.FC<SearchProps> = ({ className = '', fetchSearchRequest, search, searchBtnId, searchInputId }) => {
  const [searchText, setSearchText] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const getSearchSuggestions = useCallback(
    debounce((searchText) => {
      if (searchText) {
        fetchSearchRequest({
          storeKey: SEARCH_KEY.SUGGESTION,
          queryParams: getSearchQueryParamsInitialState(searchText),
        });
        setIsDropdownOpen(true);
      }
    }, 100),
    [],
  );

  useEffect(() => {
    getSearchSuggestions(searchText);
    return () => {
      getSearchSuggestions.cancel();
    };
  }, [searchText]);

  const limit = 5;

  const totalResults = search[SEARCH_KEY.SUGGESTION]?.search?.count || 0;
  const limitedSuggestions = search[SEARCH_KEY.SUGGESTION]?.search?.results?.filter((_, index) => index < limit);

  const remainingResults = totalResults - limitedSuggestions?.length;
  return (
    <div className={`relative text-14 ${className}`}>
      <div className="w-full md:w-308 h-44 bg-white border md:my-22 rounded-full border-searchBorder flex items-center">
        <input
          className="w-full rounded-full bg-searchInput pl-16 text-heading focus:outline-none text-14 leading-21"
          type="text"
          id={searchInputId}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              setIsDropdownOpen(false);
              if (searchText) history.push(`/search?searchText=${searchText}`);
            }
          }}
          autoComplete="off"
          placeholder="Search for anything"
          font-customisation="para-text"
        />
        <div className="p-14">
          <Button
            className="focus:outline-none w-16 h-16 flex items-center justify-center"
            onClick={() => {
              if (searchText) history.push(`/search?searchText=${searchText}`);
            }}
          >
            <p id={searchBtnId}>
              <Image src={SearchImg} className="my-44" alt="Search Icon" />
            </p>
          </Button>
        </div>
      </div>
      {isDropdownOpen ? (
        <div className="absolute rounded  searchBar w-full md:w-320 md:-mt-22 z-50 max-h-400 bg-white overflow-y-auto hideScroll shadow-searchDropdown">
          <OutsideClick onOutsideClick={() => setIsDropdownOpen(false)}>
            <div className="px-16 pt-16 pb-12">
              {!search[SEARCH_KEY.SUGGESTION]?.isFetched ? (
                <div>
                  {[...Array(1).keys()].map((key) => (
                    <div key={key} className="mb-16 last:mb-0 flex items-center">
                      <div className="h-44 w-44 rounded mr-8 has-shimmer"></div>
                      <div className="space-y-4 w-full">
                        <div className="h-20 w-full has-shimmer"></div>
                        <div className="h-20 w-full has-shimmer"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : search[SEARCH_KEY.SUGGESTION]?.isFetched && search[SEARCH_KEY.SUGGESTION]?.error ? (
                <Error message={ERROR_TEXT.SEARCH_RESULTS} />
              ) : search[SEARCH_KEY.SUGGESTION]?.search?.results?.length ? (
                <div>
                  {limitedSuggestions?.map((searchResult) => (
                    <Link
                      to={searchResult.storeLink}
                      className="block mb-16 last:mb-0"
                      key={searchResult.id}
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setSearchText('');
                      }}
                    >
                      <div className="flex items-center justify-start">
                        <div className="h-32 w-32 mr-20 rounded-sm overflow-hidden">
                          <Image src={searchResult.images[0]} alt={searchResult.title} />
                        </div>
                        <div className="text-16 text-left text-heading">
                          {(() => {
                            const title = searchResult.title;
                            const matchResult = title.match(searchText);
                            const index = matchResult?.index;
                            if (matchResult && index > -1) {
                              return (
                                <>
                                  <span className="text-searchBlur">{title.slice(0, index)}</span>
                                  <span className="text-heading">{title.slice(index, index + searchText.length)}</span>
                                  <span className="text-searchBlur">
                                    {title.slice(index + searchText.length, title.length)}
                                  </span>
                                </>
                              );
                            }
                            return <span className="text-searchBlur">{title} </span>;
                          })()}
                        </div>
                      </div>
                    </Link>
                  ))}
                  {remainingResults ? (
                    <Link
                      className="mt-6 text-14 underline text-theme cursor-pointer"
                      to={`/search?searchText=${searchText}`}
                    >
                      and {remainingResults} more.
                    </Link>
                  ) : null}
                </div>
              ) : (
                <div>No search Result</div>
              )}
            </div>
          </OutsideClick>
        </div>
      ) : null}
    </div>
  );
};

export default Search;
