import useForm, { FormReturnType } from '@common/hooks/useForm';
import { usePrevious } from '@common/hooks/usePrevious';
import { CategoryAttributesSliceType } from '@common/store/categoryAttributes/slice';
import { CategoryPriceLimitsSliceType } from '@common/store/priceLimits/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { generateFacetsString } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { validateGreaterThanEqualtoZero, validateNumber, validateRequired } from '@common/utils/validations';
import { Dispatch, useEffect } from 'react';

import { FacetTag, getFacetMappingAndTags } from './utils';

interface UsePriceRangeArgs {
  priceLimits: CategoryPriceLimitsSliceType;
  noOfRanges: number;
  currentMinPrice: number;
  currentMaxPrice: number;
}

interface UsePriceRangeReturnType {
  PRICE_RANGES: PriceRangeInterface[];
  MIN_MAX_DIFFERENCE?: number;
  STEP: number;
  MIN_PRICE?: number;
  MAX_PRICE?: number;
}

interface PriceRangeInterface {
  maxPrice: number;
  minPrice: number;
  isSelected: boolean;
}

export const useCategoryPrice = ({
  priceLimits,
  noOfRanges,
  currentMinPrice,
  currentMaxPrice,
}: UsePriceRangeArgs): UsePriceRangeReturnType => {
  const minPrice = priceLimits?.priceLimits?.minPrice;
  const maxPrice = priceLimits?.priceLimits?.maxPrice;

  const PRICE_RANGES = [];
  let STEP = 1;
  let MIN_MAX_DIFFERENCE;
  if (minPrice && maxPrice && minPrice != maxPrice) {
    MIN_MAX_DIFFERENCE = maxPrice - minPrice;
    const increment = (maxPrice - minPrice) / noOfRanges;
    for (let i = minPrice; +i.toFixed(0) < maxPrice; i += increment) {
      const rangeMinPrice = +i.toFixed(0);
      const rangeMaxPrice = +(i + increment).toFixed(0);
      const isSelected = rangeMinPrice == currentMinPrice && rangeMaxPrice == currentMaxPrice;

      PRICE_RANGES.push({
        minPrice: rangeMinPrice,
        maxPrice: rangeMaxPrice,
        isSelected,
      });
    }

    if (MIN_MAX_DIFFERENCE > 100 && MIN_MAX_DIFFERENCE < 1000) {
      STEP = 10;
    } else if (MIN_MAX_DIFFERENCE > 1000 && MIN_MAX_DIFFERENCE < 10000) {
      STEP = 50;
    } else if (MIN_MAX_DIFFERENCE > 10000) {
      STEP = 1000;
    }
  }
  return { PRICE_RANGES, MIN_MAX_DIFFERENCE, STEP, MIN_PRICE: minPrice, MAX_PRICE: maxPrice };
};

const validateCategoryAttributesForm = (values: Record<string, string>) => {
  const errors = {
    minPrice: [
      validateRequired(values.minPrice),
      validateNumber(values.minPrice),
      validateGreaterThanEqualtoZero(values.minPrice),
    ].filter((x) => x != null)[0],
    maxPrice: [
      validateRequired(values.maxPrice),
      validateNumber(values.maxPrice),
      validateGreaterThanEqualtoZero(values.maxPrice),
    ].filter((x) => x != null)[0],
  };
  return errors;
};

interface UseCategoryAttributesFormArgs {
  categoryProductsSlice: ProductsSliceType;
  categoryAttributes: CategoryAttributesSliceType;
  shouldFetchOnFacetChange: boolean;
  shouldFetchOnPriceChange: boolean;
  dispatch: Dispatch<unknown>;
}

export interface UseCategoryAttributesReturnType {
  form: FormReturnType;
  productFilters: string;
  shouldFetchOnFacetChange: boolean;
  shouldFetchOnPriceChange: boolean;
  computedFormFacetProperties: {
    FILTER_MAPPING;
    FACET_TAGS;
    NUMBER_OF_FACETS_APPLIED;
    onFacetTagClick;
  };
  computedFormPriceProperties: {
    PRICE_TAG;
    onPriceTagClick;
  };
  TOTAL_FORM_ATTRIBUTES_APPLIED;
}
export const useCategoryAttributesForm = ({
  categoryProductsSlice,
  categoryAttributes,
  shouldFetchOnFacetChange,
  shouldFetchOnPriceChange,
  dispatch,
}: UseCategoryAttributesFormArgs): UseCategoryAttributesReturnType => {
  const form = useForm({ validate: validateCategoryAttributesForm });
  const { values, setValues } = form;
  const { minPrice, maxPrice, ...facetFormValues } = values;

  const productFilters = generateFacetsString(facetFormValues);

  useSetProductAttributeValues({ categoryProductsSlice, categoryAttributes, setValues });

  const prevValues = usePrevious(values);
  const prevFacets = usePrevious(productFilters);

  useEffect(() => {
    if (!form.hasHandleChangeExecuted) {
      return;
    }

    let overrideParams = {} as Record<string, unknown>;
    const initialParams = overrideParams;
    if (shouldFetchOnFacetChange && prevFacets !== productFilters) {
      overrideParams = {
        ...overrideParams,
        product_filter: productFilters,
        page: 1,
      };
    }
    if (shouldFetchOnPriceChange && prevValues.minPrice !== values.minPrice && prevValues.maxPrice != values.maxPrice) {
      overrideParams = {
        ...overrideParams,
        final_price__gte: minPrice,
        final_price__lte: maxPrice,
        page: 1,
      };
    }

    if (initialParams === overrideParams) {
      return;
    }

    const currentQueryParams = categoryProductsSlice?.currentQueryParams || getProductQueryParamsInitialState();

    dispatch(resetProductsStore(PRODUCTS_KEY.CATEGORY));
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.CATEGORY,
        queryParams: {
          ...currentQueryParams,
          ...overrideParams,
        },
      }),
    );
  }, [productFilters, minPrice, maxPrice]);

  const { FILTER_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED } = getFacetMappingAndTags(productFilters);
  const onFacetTagClick = (facetTagToRemove: FacetTag) => {
    const newValues =
      Object.keys(form.values).reduce((acc, facetKey) => {
        if (facetTagToRemove.facetKey == facetKey) {
          if (typeof form.values[facetKey] === 'string') {
            return acc;
          } else if (typeof form.values[facetKey] === 'object') {
            const facetValues = { ...form.values[facetKey] };
            delete facetValues[facetTagToRemove.facetValue];
            return {
              ...acc,
              [facetKey]: facetValues,
            };
          }
        }
        return {
          ...acc,
          [facetKey]: form.values[facetKey],
        };
      }, {}) || {};
    form.setValues(newValues);
  };

  let PRICE_TAG;
  if (minPrice != null && maxPrice != null) {
    PRICE_TAG = { minPrice, maxPrice };
  }

  const onPriceTagClick = () => {
    setValues({ ...facetFormValues });
  };

  const computedFormFacetProperties = { FILTER_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED, onFacetTagClick };
  const computedFormPriceProperties = { PRICE_TAG, onPriceTagClick };
  const TOTAL_FORM_ATTRIBUTES_APPLIED =
    computedFormFacetProperties.NUMBER_OF_FACETS_APPLIED + (computedFormPriceProperties.PRICE_TAG ? 1 : 0);

  return {
    form,
    productFilters,
    shouldFetchOnFacetChange,
    shouldFetchOnPriceChange,
    computedFormFacetProperties,
    computedFormPriceProperties,
    TOTAL_FORM_ATTRIBUTES_APPLIED,
  };
};

interface UseSetProductAttributeValuesArgs {
  categoryProductsSlice: ProductsSliceType;
  categoryAttributes: CategoryAttributesSliceType;
  setValues: (value: Record<string, string>, ignoreHandleChange: boolean) => void;
}

export const useSetProductAttributeValues = ({
  categoryProductsSlice,
  categoryAttributes,
  setValues,
}: UseSetProductAttributeValuesArgs): void => {
  const productFilters = categoryProductsSlice?.currentQueryParams?.product_filter;
  const minPrice = categoryProductsSlice?.currentQueryParams?.final_price__gte;
  const maxPrice = categoryProductsSlice?.currentQueryParams?.final_price__lte;
  const ordering = categoryProductsSlice?.currentQueryParams?.ordering;
  const fetchCategoryKeyType = (id: number): string => {
    let type = '';
    const hit = categoryAttributes.categoryAttributes?.results.find((key) => key.id === id);
    if (hit) {
      type = hit?.filterType;
    }
    return type;
  };
  useEffect(() => {
    if (!categoryAttributes?.isFetched) {
      return;
    }
    const formObject = {
      minPrice,
      maxPrice,
      ordering,
    };
    productFilters?.split('|').forEach((entry) => {
      const [key, values] = entry.split(':');
      const numberKey = +key;
      const filterType = fetchCategoryKeyType(numberKey);

      if (filterType === 'radio') {
        formObject[numberKey] = values;
      } else if (filterType === 'checkbox') {
        formObject[numberKey] = values.split(';').reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
    });
    setValues((formObject as unknown) as Record<string, string>, true);
  }, [productFilters, minPrice, maxPrice, ordering, categoryAttributes?.isFetched]);
};
