import ProductAddToCartHOC from '@common/components/ProductAddToCartHOC';
import { ReactComponent as ShipIcon } from '@common/icons/ShipIcon.svg';
import { PRODUCT_TYPE } from '@common/models/Product';
import Product from '@common/models/Product';
import StoreInfo from '@common/models/StoreInfo';
import { addProductToCart } from '@common/store/cart/slice';
import { subtractProductFromCart } from '@common/store/cart/slice';
import { addToast } from '@common/store/toasts/slice';
import Gallery from '@components/Gallery';
import ProductAddToCart from '@components/ProductAddToCart';
import Share from '@components/Share';
import Editor from '@lib/Editor';
import React from 'react';

import StarRating from '../Rating/StarRating';

export interface ProductProps {
  type?: string;
  isZoomEnabled?: boolean;
  product: Product;
  cartProductQuantity: number;
  addToast: typeof addToast;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  storeType: string;
  isPaymentsDisabled: boolean;
  isStoreInfoFetching: boolean;
  wishListFeature: boolean;
  productVideoFeature: boolean;
  storeInfo: StoreInfo;
}

const ProductComponent: React.FunctionComponent<ProductProps> = ({
  isZoomEnabled = false,
  product,
  cartProductQuantity,
  addToast,
  addProductToCart,
  subtractProductFromCart,
  storeType,
  isPaymentsDisabled,
  isStoreInfoFetching,
  wishListFeature,
  productVideoFeature,
  storeInfo,
}) => {
  return (
    <ProductAddToCartHOC
      product={product}
      render={(hocArgs) => {
        const { finalProduct, onWishListClickHandler, wishlist } = hocArgs;
        return (
          <div className="w-full md:w-2/3">
            <div className="bg-white p-12 pb-16 rounded-md">
              <div className="flex flex-wrap md:flex-no-wrap">
                <div className="w-full md:w-300 mb-10 md:mb-24">
                  <Gallery
                    isZoomEnabled={isZoomEnabled}
                    images={finalProduct.images.length ? finalProduct.images : product.images}
                    wishListFeature={wishListFeature}
                    onWishListClickHandler={onWishListClickHandler}
                    wishlist={wishlist}
                    imageDescription={`${finalProduct.images.length ? finalProduct.title : product.title}${
                      finalProduct?.optionValues?.length ? '_' + finalProduct?.optionValues.join('_') : ''
                    }`}
                    productVideoUrl={finalProduct.productVideoUrl}
                    productVideoEnabled={productVideoFeature ? finalProduct.productVideoEnabled : false}
                  />
                  {product.type === PRODUCT_TYPE.PHYSICAL ? (
                    <div
                      className="hidden items-center text-13 text-shipIn-text"
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      <ShipIcon></ShipIcon>
                      <div className="ml-6">Standard shipping in {finalProduct.shipInDays} working days</div>
                    </div>
                  ) : null}
                </div>
                <div className="md:pl-24 w-full pr-8 md:mb-24">
                  <div
                    className="text-20 md:text-24 font-normal text-prodTitleTxt mb-16 break-words"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                    id="product-title"
                  >
                    {finalProduct.title}
                  </div>
                  <Share className="mb-20" product={finalProduct} />
                  {product.ratingVotes ? (
                    <div className="flex items-center">
                      <StarRating
                        rating={product.ratingScore}
                        total={5}
                        isInteractive={false}
                        className="transform scale-75 -ml-16"
                      />
                      <div className="-ml-12 text-14 text-theme">{product.ratingVotes} ratings</div>
                    </div>
                  ) : null}
                  <ProductAddToCart
                    taxIncludedInventory={storeInfo?.storeInfo?.tax_included_inventory}
                    product={product}
                    addToast={addToast}
                    cartProductQuantity={cartProductQuantity}
                    addProductToCart={addProductToCart}
                    subtractProductFromCart={subtractProductFromCart}
                    storeType={storeType}
                    hocArgs={hocArgs}
                    isPaymentsDisabled={isPaymentsDisabled}
                    isStoreInfoFetching={isStoreInfoFetching}
                    wishListFeature={wishListFeature}
                  />
                </div>
              </div>
              {finalProduct.shortDescription ? (
                <div className="w-full">
                  <div
                    className="text-label text-12 mb-16 font-bold"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    OVERVIEW
                  </div>
                  <Editor
                    isDefaultFont
                    name="productShortDescription"
                    value={finalProduct.shortDescription || product.shortDescription}
                    readonly={true}
                  />
                </div>
              ) : null}
            </div>
            {finalProduct.description ? (
              <div className="bg-white p-20 rounded-md description">
                <div
                  className="text-navText text-12 font-normal capitalize md:uppercase"
                  font-customisation="section-heading"
                  color-customisation="page-heading"
                >
                  <span className="border-nav border-b-2 ">Description</span>
                </div>
                <div className="py-14">
                  <Editor
                    isDefaultFont
                    name="productLongDescription"
                    value={finalProduct.description || product.description}
                    readonly={true}
                  />
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default ProductComponent;
