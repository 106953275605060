// import './index.css';

import Rupee from '@common/components/Rupee';
import { ShopAllContext } from '@common/context/ShopAllContext';
import {
  useProductAttributesForm,
  UseProductAttributesReturnType,
  useProductPrice,
} from '@common/context/ShopAllContext/hooks';
import closeDropDown from '@common/images/minus.svg';
import DropDown from '@common/images/plus.svg';
import FormCheckboxRadioGroup from '@lib/FormCheckboxRadioGroup';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

interface ProductAttributesFormProps {
  shopAllAttributesForm: UseProductAttributesReturnType;
}

const ProductAttributesForm: React.FC<ProductAttributesFormProps> = ({ shopAllAttributesForm }) => {
  const { priceLimits, shopAttributes, shopAllProductsSlice, categories } = useContext(ShopAllContext);
  const dispatch = useDispatch();
  const [isFiltersDropdownOpen, setIsFiltersDropdownOpen] = useState({});
  const [isPriceDropdownOpen, setIsPriceDropdownOpen] = useState(false);
  const { form } =
    shopAllAttributesForm ||
    useProductAttributesForm({
      shopAllProductsSlice,
      shopAttributes,
      dispatch,
      shouldFetchOnFacetChange: true,
      shouldFetchOnPriceChange: true,
      categories,
    });

  const { values, touched, submissions, handleChange, setValues, handleBlur, errors, asyncErrors } = form;
  const { PRICE_RANGES } = useProductPrice({
    priceLimits,
    noOfRanges: 5,
    currentMinPrice: values.minPrice,
    currentMaxPrice: values.maxPrice,
  });

  const filterCategory = categories?.categories?.results.filter((item) => item.id != 0);
  return (
    <form action="">
      {filterCategory && filterCategory?.length > 0 && (
        <div>
          <div className="leading-6 mt-20 pb-20">
            <div
              onClick={() => {
                setIsFiltersDropdownOpen({
                  ...isFiltersDropdownOpen,
                  ['category']: !isFiltersDropdownOpen['category'],
                });
              }}
              className="flex mb-2 items-center justify-between text-14 cursor-pointer"
            >
              <div
                className="justify-self-start text-textColor leading-16 text-16 font-semibold mb-2"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                Category
              </div>
              <div className="justify-self-end">
                <div className="bg-navbar h-44 w-44 rounded-10 flex justify-center items-center">
                  {isFiltersDropdownOpen['category'] ? (
                    <img src={closeDropDown} alt="up" />
                  ) : (
                    <img src={DropDown} alt="Down" />
                  )}
                </div>
              </div>
            </div>
            {isFiltersDropdownOpen['category'] && (
              <div className="md:max-h-204 md:overflow-y-scroll sideBar">
                <FormCheckboxRadioGroup
                  name={'category'}
                  type={'checkbox'}
                  value={values['category'] || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={filterCategory.map((value) => ({ id: value.id, value: value.name }))}
                  inputClass="flex"
                  optionsClass="relative cursor-pointer text-black_variant_2 text-18 leading-19 font-normal mt-20 space-x-0 optional-label-margin"
                  optionsActiveClass="relative text-black_variant_2 text-18 leading-19 font-normal mt-20"
                  spaceY="space-y-20 optional-margin-top"
                  spaceX="space-x-0"
                  error={(submissions || touched['category']) && (errors['category'] || asyncErrors['category'])}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {PRICE_RANGES?.length ? (
        <div className="pb-20 mt-20 ">
          <div
            className="flex items-center justify-between text-16 md:text-20 leading-24 font-semibold cursor-pointer"
            onClick={() => {
              setIsPriceDropdownOpen(!isPriceDropdownOpen);
            }}
          >
            <div
              className="justify-self-start text-textColor leading-16 text-16 font-semibold mb-2 flex"
              font-customisation="section-heading"
              color-customisation="page-heading"
            >
              Price
            </div>
            <div className="justify-self-end">
              <div className="bg-navbar h-44 w-44 rounded-10 flex justify-center items-center">
                {isPriceDropdownOpen ? <img src={closeDropDown} alt="up" /> : <img src={DropDown} alt="Down" />}
              </div>
            </div>
          </div>
          {isPriceDropdownOpen && (
            <div className="md:overflow-y-scroll cursor-pointer optional-margin-top">
              {PRICE_RANGES.map((priceRange, index) => {
                return (
                  <div key={index}>
                    <div
                      className={classnames(
                        'sideBar flex items-center relative mt-20 cursor-pointer text-black_variant_2 text-18 leading-19 font-normal optional-label-margin',
                      )}
                      onClick={() => {
                        setValues({
                          ...values,
                          ...(priceRange.isSelected
                            ? { minPrice: undefined, maxPrice: undefined }
                            : { minPrice: priceRange.minPrice, maxPrice: priceRange.maxPrice }),
                        });
                      }}
                    >
                      <input id={index.toString()} type="radio" value={index} checked={priceRange.isSelected} />
                      <label className="flex" font-customisation="para-text" color-customisation="page-body-text">
                        <Rupee value={priceRange.minPrice} defaultClass={false} /> <span className="mx-2">-</span>
                        <Rupee value={priceRange.maxPrice} defaultClass={false} />
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}

      {shopAttributes?.shopAttributes?.results?.map((shopAttribute) => (
        <div key={shopAttribute.id}>
          {shopAttribute.filterType === 'radio' || shopAttribute.filterType === 'checkbox' ? (
            <div className="leading-6 mt-20 pb-20">
              <div
                onClick={() => {
                  setIsFiltersDropdownOpen({
                    ...isFiltersDropdownOpen,
                    [shopAttribute.id]: !isFiltersDropdownOpen[shopAttribute.id],
                  });
                }}
                className="flex mb-2 items-center justify-between text-14 cursor-pointer"
              >
                <div
                  className="justify-self-start text-textColor leading-16 text-16 font-semibold mb-2"
                  font-customisation="section-heading"
                  color-customisation="page-heading"
                >
                  {shopAttribute.name}
                </div>
                <div className="justify-self-end">
                  <div className="bg-navbar h-44 w-44 rounded-10 flex justify-center items-center">
                    {isFiltersDropdownOpen[shopAttribute.id] ? (
                      <img src={closeDropDown} alt="up" />
                    ) : (
                      <img src={DropDown} alt="Down" />
                    )}
                  </div>
                </div>
              </div>
              {isFiltersDropdownOpen[shopAttribute.id] && (
                <div className="md:max-h-204 md:overflow-y-scroll sideBar">
                  <FormCheckboxRadioGroup
                    name={shopAttribute.idString}
                    type={shopAttribute.filterType}
                    value={values[shopAttribute.idString] || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={shopAttribute.values?.map((value) => ({ id: value.id, value: value.id }))}
                    inputClass="flex"
                    optionsClass="relative cursor-pointer text-black_variant_2 text-18 leading-19 font-normal mt-20 space-x-0 optional-label-margin"
                    optionsActiveClass="relative text-black_variant_2 text-18 leading-19 font-normal mt-20"
                    spaceY="space-y-20 optional-margin-top"
                    spaceX="space-x-0"
                    error={
                      (submissions || touched[shopAttribute.idString]) &&
                      (errors[shopAttribute.idString] || asyncErrors[shopAttribute.idString])
                    }
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      ))}
    </form>
  );
};

export default ProductAttributesForm;
