import { useConstructor } from '@common/hooks/useConstructor';
import { RootState } from '@common/store';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { CategorySliceType, fetchCategoryRequest } from '@common/store/category/slice';
import { CategoryAttributesSliceType, fetchCategoryAttributesRequest } from '@common/store/categoryAttributes/slice';
import { CategoryPriceLimitsSliceType } from '@common/store/priceLimits/slice';
import { fetchCategoryPriceLimitsRequest, fetchCategoryPriceLimitsSuccess } from '@common/store/priceLimits/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { isBrowser, smoothScroll } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { getProductsQueryParamsFromURL } from '@common/utils/params';
import React, { createContext, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import {
  computeFacetTags,
  ComputeFacetTagsReturnType,
  computePriceTag,
  ComputePriceTagReturnType,
  computeSortByOptions,
  ComputeSortByOptionsArgsReturnType,
} from './utils';

export const CategoryContext = createContext(({} as unknown) as CategoryContextType);

interface CategoryProviderProps {
  children: React.ReactNode;
  sortDefaultName?: string;
  sortDefaultUnselectedName?: string;
}

interface CategoryContextType {
  category: CategorySliceType;
  categories: CategoriesSliceType;
  categoryAttributes: CategoryAttributesSliceType;
  priceLimits: CategoryPriceLimitsSliceType;
  categoryProductsSlice: ProductsSliceType;
  computedSortOptions: ComputeSortByOptionsArgsReturnType;
  computedFacetProperties: ComputeFacetTagsReturnType;
  computedPriceProperties: ComputePriceTagReturnType;
  TOTAL_ATTRIBUTES_APPLIED: number;
  categoryId: number;
}

interface MatchParams {
  categoryId: string;
  categorySlug: string;
}

export const CategoryProvider: React.FC<CategoryProviderProps> = ({
  children,
  sortDefaultName,
  sortDefaultUnselectedName,
}) => {
  const category = useSelector((state: RootState) => state.category);
  const categories = useSelector((state: RootState) => state.categories);
  const categoryAttributes = useSelector((state: RootState) => state.categoryAttributes);
  const priceLimits = useSelector((state: RootState) => state.priceLimits);
  const categoryProductsSlice = useSelector((state: RootState) => state.products[PRODUCTS_KEY.CATEGORY]);

  const [priceLimitCategoryId, setPriceLimitCategoryId] = useState(null);

  const dispatch = useDispatch();
  const { categoryId: categoryIdString } = useParams<MatchParams>();
  const categoryId = +categoryIdString;

  const location = useLocation();
  const computedSortOptions = computeSortByOptions({
    categoryProductsSlice,
    dispatch,
    sortDefaultName,
    sortDefaultUnselectedName,
  });
  const computedFacetProperties = computeFacetTags({ categoryProductsSlice, dispatch });
  const computedPriceProperties = computePriceTag({ categoryProductsSlice, categoryAttributes, dispatch });

  const TOTAL_ATTRIBUTES_APPLIED =
    computedFacetProperties.NUMBER_OF_FACETS_APPLIED + (computedPriceProperties.PRICE_TAG ? 1 : 0);

  useConstructor(() => {
    if (!categories.isFetched) {
      dispatch(
        fetchCategoriesRequest({
          queryParams: getCategoryQueryParamsInitialState(),
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (categoryProductsSlice?.isFetched && priceLimitCategoryId != categoryId) {
      const productsCount = categoryProductsSlice.list?.count;
      const products = categoryProductsSlice.list?.results;
      if (productsCount >= 24 || categoryProductsSlice?.currentQueryParams?.facets) {
        dispatch(fetchCategoryPriceLimitsRequest({ categoryId }));
      } else if (productsCount >= 1) {
        const finalPrices = products.map((product) => product.finalPrice || 0);
        const minPrice = Math.min(...finalPrices);
        const maxPrice = Math.max(...finalPrices);
        dispatch(fetchCategoryPriceLimitsSuccess({ priceLimits: { minPrice, maxPrice } }));
      }
      setPriceLimitCategoryId(categoryId);
    }
  }, [categoryProductsSlice?.isFetched]);

  useConstructor(() => {
    if (categoryId !== category.categoryId) {
      dispatch(resetProductsStore(PRODUCTS_KEY.CATEGORY));
      const queryData = getProductsQueryParamsFromURL(location.search);
      dispatch(
        fetchProductsRequest({
          storeKey: PRODUCTS_KEY.CATEGORY,
          queryParams: {
            ...getProductQueryParamsInitialState(),
            ...queryData,
            ordering: '-created',
            category: queryData?.category ? queryData?.category : categoryId,
          },
        }),
      );
      dispatch(fetchCategoryRequest(categoryId));
    }
    if (isBrowser()) {
      const param = {
        limit: 500,
        page: 1,
        category: categoryId,
      };
      dispatch(
        fetchCategoryAttributesRequest({
          queryParams: param,
        }),
      );
    }
    smoothScroll(0, 0);
  }, [categoryId]);

  return (
    <CategoryContext.Provider
      value={{
        category,
        categoryAttributes,
        categoryProductsSlice,
        computedSortOptions,
        computedFacetProperties,
        computedPriceProperties,
        TOTAL_ATTRIBUTES_APPLIED,
        categoryId,
        categories,
        priceLimits,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
