import InstamojoLogo from '@common/icons/InstamojoLogo.svg';
import React from 'react';

export interface BrandingProps {
  className: string;
}

const Branding: React.FunctionComponent<BrandingProps> = () => {
  return (
    <div className="w-full md:mx-auto h-54 bg-white text-18 font-normal flex items-center">
      <div className="container px-42 mx-auto">
        <span className="flex justify-start text-14 md:text-16 items-center opacity-10 hover:opacity-100">
          <img src={InstamojoLogo} alt="Instamojo" className="ml-6 w-142 h-32" />
        </span>
      </div>
    </div>
  );
};

export default Branding;
