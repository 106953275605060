import Product from '@common/models/Product';
import { fetchProductDetailsRequest, fetchProductReviewsRequest } from '@common/store/productDetails/slice';
import { removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import React from 'react';

export interface ProductCardOverlayProps {
  product: Product;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  removeToast: typeof removeToast;
  toasts: ToastWithKeyType;
  CloseDetails: boolean;
  SetCloseDetails: () => void;
  computedProductLink: string;
}

const ProductCardOverlay: React.FC<ProductCardOverlayProps> = ({}) => {
  return <></>;
};

export default ProductCardOverlay;
