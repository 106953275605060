import CommonRoutes from '@common/Routes';
import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import Cart from '@containers/Cart';
import Category from '@containers/Category';
import Home from '@containers/Home';
import ProductDetail from '@containers/ProductDetail';
import Search from '@containers/Search';
import ShopAll from '@containers/ShopAll';
import ProductReview from '@Core/containers/ProductReview';
import Route from '@lib/Route';
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

interface RoutesProps {
  features: FeaturesSliceType;
}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/search/" component={Search} />
      <Route exact path="/category/:categoryId([0-9]+)/:categorySlug" component={Category} />
      <Route exact path="/category/:categoryId([0-9]+)/:categorySlug([\w-]+)/search" component={Category} />
      <Route path="/shop" component={ShopAll} />
      {/* <Route path="/" component={CustomPage} /> */}
      <Route exact isProtected path="/product/:productId([0-9]+)/review" component={ProductReview} />
      <Route exact path="/product/:productId([0-9]+)/:productSlug([\w-]+)/" component={ProductDetail} />
      <Route exact path="/product/:productId([0-9]+)" component={ProductDetail} />
      <Route exact path="/:productSlug([\w-]+)/p:productId([0-9]+)/" component={ProductDetail} />
      <CommonRoutes />
    </Switch>
  );
};

export default connect(({ features }: RootState) => ({ features }))(Routes);
