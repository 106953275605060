import NoIndex from '@common/components/SEO/NoIndex';
import React from 'react';
import { connect } from 'react-redux';

interface CartPageWrapperProps {
  children: React.ReactNode;
}

const CartPageWrapper: React.FC<CartPageWrapperProps> = ({ children }) => {
  return (
    <>
      <NoIndex />
      {children}
    </>
  );
};

export default connect(() => ({}), {})(CartPageWrapper);
