import Category from '@common/models/Category';
import React from 'react';

import { UseCategoryAttributesReturnType } from '../../../../common/context/CategoryContext/hooks';
import CategorySidebarItem from './CategorySidebarItem';
import ProductAttributesForm from './ProductAttributesForm';

interface CategorySidebarProps {
  category: Category;
  categoryAttributesForm?: UseCategoryAttributesReturnType;
  hideCategory?: boolean;
  hideForms?: boolean;
}

const CategorySidebar: React.FC<CategorySidebarProps> = ({
  category,
  hideCategory,
  categoryAttributesForm,
  hideForms,
}) => {
  return (
    <div className="text-black">
      {!hideCategory
        ? category.childCategories?.map((childCategory) => (
            <div key={childCategory.id}>
              <CategorySidebarItem category={childCategory} />
            </div>
          ))
        : null}
      {!hideForms ? <ProductAttributesForm categoryAttributesForm={categoryAttributesForm} /> : null}
    </div>
  );
};

export default CategorySidebar;
