import useBuyerLogin from '@common/hooks/useBuyerLogin';
import { fetchNextPageProductReviewsRequest } from '@common/store/productDetails/slice';
// import { isMobile } from '@common/utils';
import { getBuyerJWTToken } from '@common/utils/token';
import { LoaderIcon } from '@instamojo/icons';
import Button from '@lib/Button';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import Review from '../../models/Review';
// import { ReactComponent as StarIcon } from '@common/icons/Star.svg';
// import Ratingbar from './Ratingbar';
// import StarRating from './StarRating';
import ReviewComponent from './Review';

// import Carousel from '@brainhubeu/react-carousel';

export interface RatingProps {
  reviews: Array<Review>;
  productSlug?: string;
  nextReviewsUrl: string | null;
  reviewsCount: number;
  isNextReviewsLoadingFailed: boolean;
  isNextReviewsLoading: boolean;
  fetchNextPageProductReviewsRequest: typeof fetchNextPageProductReviewsRequest;
}

const Rating: React.FunctionComponent<RatingProps> = ({
  reviews = [],
  productSlug,
  fetchNextPageProductReviewsRequest,
  nextReviewsUrl,
  reviewsCount,
  isNextReviewsLoadingFailed,
  isNextReviewsLoading,
}) => {
  const { features, hideReviewsSection } = useBuyerLogin();
  if (hideReviewsSection) {
    return null;
  }
  return (
    <div className="w-full md:w-2/3 md:pr-8 mb-24">
      <div className="bg-white flex flex-wrap pb-16 rounded-md">
        <div className="flex w-full justify-between p-16">
          <div className="w-full">
            <div className="flex flex-col gap-14 md:items-start">
              <div
                className="text-navText text-12 font-normal capitalize md:uppercase"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                <span className="border-nav border-b-2 uppercase">Customer Reviews</span>
              </div>
              {features?.features?.buyerLogin && (
                <Link
                  className="max-w-150 w-1/2 md:w-1/3"
                  to={
                    getBuyerJWTToken()
                      ? `/product/${productSlug}/review`
                      : `/account/login?redirect_url=/product/${productSlug}/review`
                  }
                >
                  <Button
                    type="submit"
                    className="text-12 border border-nav text-nav uppercase font-semibold rounded h-36 w-full flex justify-center items-center"
                    font-customisation="para-text"
                    button-customisation="page-button"
                  >
                    Write a Review
                  </Button>
                </Link>
              )}
            </div>
            {reviewsCount > 0 &&
              reviews.map((review) => (
                <ReviewComponent
                  key={review.id}
                  name={review.customerName}
                  date={review.created}
                  review={review.review}
                  rating={review.rating}
                />
              ))}
            {isNextReviewsLoading && !isNextReviewsLoadingFailed && (
              <div className="flex justify-center md:pl-46 md:pt-20 pt-20 md:justify-start">
                <LoaderIcon size={35} />
              </div>
            )}
            {nextReviewsUrl && (
              <Button
                type="submit"
                className="text-12 border border-nav text-nav uppercase font-semibold rounded h-36 md:w-200 w-full mt-20 flex justify-center items-center"
                font-customisation="para-text"
                button-customisation="page-button"
                onClick={() => {
                  fetchNextPageProductReviewsRequest({
                    nextReviewsUrlPageNo: nextReviewsUrl,
                    productSlug: productSlug,
                  });
                }}
              >
                View More Reviews
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { fetchNextPageProductReviewsRequest })(Rating);
