import Category from '@common/models/Category';
import { ProductsSliceType } from '@common/store/products/slice';
import { ProductQueryParamsType } from '@common/store/products/slice';
// import CategoryDropdown from '@components/CategoryDropdown';
import MobileCategoryDropdown from '@components/CategoryDropdown/MobileCategoryDropdown';
import React from 'react';
// import { useSelector } from 'react-redux';

export interface FilterBarProps {
  products: ProductsSliceType;
  categories: Category[];
  className: string;
  fetchProductsRequest: (overrideParams: ProductQueryParamsType) => void;
  afterSortCallback?: () => void;
}

const FilterBar: React.FunctionComponent<FilterBarProps> = ({ categories, className = '' }) => {
  let filteredCategories = categories[0];
  const temp = filteredCategories?.childCategories?.filter((category) => category.name !== 'Shop All');
  filteredCategories = {
    ...filteredCategories,
    childCategories: temp,
  };
  const mobilenavLink = [filteredCategories];
  return (
    <div className={`md:flex z-40 ${className}`}>
      <div className="hidden md:flex justify-between items-center mt-2 md:mt-0">
        <div className="flex flex-no-wrap mx-auto overflow-x-auto overflow-y-hidden hideScroll justify-center">
          {mobilenavLink.map((category) => {
            if (category?.name == 'Shop All' && category?.childCategories?.length)
              return <MobileCategoryDropdown className="" key={category.id} category={category} />;
            else if (category?.name != 'Shop All')
              return <MobileCategoryDropdown className="" key={category.id} category={category} />;
          })}
        </div>
      </div>
      <div className="flex md:hidden">
        {mobilenavLink.map((category) => (
          <MobileCategoryDropdown className="" key={category.id} category={category} />
        ))}
      </div>
    </div>
  );
};

export default FilterBar;
