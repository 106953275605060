import ProductCardLoading from '@common/components/ProductCard/ProductCardLoading';
// import { ReactComponent as Down } from '@common/icons/MenuDown.svg';
import { RootState } from '@common/store';
import { addProductToCart, CartSliceType, subtractProductFromCart } from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { fetchHomeRequest } from '@common/store/home/slice';
import { fetchProductsRequest, ProductsSliceType } from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import {
  fetchSearchRequest,
  getSearchQueryParamsInitialState,
  resetSearchStore,
  SearchSliceType,
} from '@common/store/search/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast } from '@common/store/toasts/slice';
import { smoothScroll } from '@common/utils';
import { SEARCH_KEY } from '@common/utils/constants';
// import { PRODUCTS_KEY } from '@common/utils/constants';
// import CategorySidebar from '@components/CategorySidebar';
// import FilterBar from '@components/FilterBar';
// import NavLinkDropdown from '@components/NavLinkDropdown';
import PageLayout from '@components/PageLayout';
// import Pagination from '@components/Pagination';
import ProductCard from '@components/ProductCard';
import RecentlyViewed from '@components/RecentlyViewed';
import CartImg from '@images/noProducts.svg';
import { ReactComponent as BreadcrumbRightArrow } from '@images/sArrow.svg';
// import { ReactComponent as BreadcrumbRightArrow } from '@images/WhiteRightArrow.svg';
// import Arrow from '@images/Arrow.svg';
import Error from '@lib/Error';
import InfiniteScroll from '@lib/InfiniteScroll';
import Link from '@lib/Link';
// import Link from '@lib/Link';
import Loading from '@lib/Loading';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

interface SearchState {
  showFilter: boolean;
}
interface SearchProps {
  search: Record<string, SearchSliceType>;
  categories: CategoriesSliceType;
  products: Record<string, ProductsSliceType>;
  location: Location;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchSearchRequest: typeof fetchSearchRequest;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  resetSearchStore: typeof resetSearchStore;
  addToast: typeof addToast;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  storeInfo: StoreInfoSliceType;
  cart: CartSliceType;
  recentlyViewed: RecentlyViewedSliceType;
}

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    const searchText = this.getSearchText();
    this.props.resetSearchStore(SEARCH_KEY.SEARCH);
    this.props.fetchSearchRequest({
      storeKey: SEARCH_KEY.SEARCH,
      queryParams: getSearchQueryParamsInitialState(searchText),
    });
    this.props.fetchCategoriesRequest({
      queryParams: getCategoryQueryParamsInitialState(),
      ordering: 'order',
    });
    smoothScroll(0, 500);

    this.onSortClick = this.onSortClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const searchText = this.getSearchText();
    if (this.props.location.search !== prevProps?.location?.search) {
      this.props.resetSearchStore(SEARCH_KEY.SEARCH);
      this.props.fetchSearchRequest({
        storeKey: SEARCH_KEY.SEARCH,
        queryParams: getSearchQueryParamsInitialState(searchText),
      });
      this.props.fetchCategoriesRequest({
        queryParams: getCategoryQueryParamsInitialState(),
        ordering: 'order',
      });
      smoothScroll(0, 500);
    }
  }

  getSearchText() {
    const queryDict = new URLSearchParams(this.props.location.search);
    const searchText = queryDict.get('searchText');
    return searchText;
  }

  onSortClick = (ordering: string) => {
    console.log(ordering);
    return () => {
      this.props.resetSearchStore(SEARCH_KEY.SEARCH);
      this.props.fetchSearchRequest({
        storeKey: SEARCH_KEY.SEARCH,
        queryParams: {
          ...this.props.search[SEARCH_KEY.SEARCH]?.currentQueryParams,
          // ordering,
        },
      });
    };
  };

  sortByOptions = [
    { name: 'Price(Low to High)', key: 'final_price', onClick: this.onSortClick('final_price') },
    { name: 'Price(High to Low)', key: '-final_price', onClick: this.onSortClick('-final_price') },
    { name: 'A to Z', key: 'title', onClick: this.onSortClick('title') },
    { name: 'Z to A', key: '-title', onClick: this.onSortClick('-title') },
    { name: 'Latest', key: '-created', onClick: this.onSortClick('-created') },
    { name: 'Select', unSelectedName: 'Clear Selection', key: 'sort_order', onClick: this.onSortClick('sort_order') },
  ];

  render() {
    const searchProductsSlice = this.props.search[SEARCH_KEY.SEARCH];
    return (
      <PageLayout hideToast showBranding={false}>
        {searchProductsSlice?.isFetched && searchProductsSlice?.fetchedProducts?.length ? (
          <div className="w-full py-30">
            <div
              className="container md:mx-auto flex items-center text-textColor text-14 leading-20"
              font-customisation="para-text"
            >
              <div className="flex items-center font-normal">
                <Link
                  to="/"
                  className="mr-4 cursor-pointer text-textColor font-semibold underline"
                  color-customisation="page-body-text"
                >
                  Home
                </Link>
                <BreadcrumbRightArrow className="mr-4 transform -rotate-90 h-6 w-6" fill="#999999" />
                <span className="text-14 text-disabled font-semibold" color-customisation="page-body-text">
                  Showing
                  <span className="font-semibold"> &quot;{this.getSearchText()}&quot; </span>
                </span>
              </div>
            </div>
          </div>
        ) : null}
        <div className="container flex md:mx-auto min-h-screen px-13 md:px-112">
          <div className="relative w-full md:w-full flex-1 ">
            {searchProductsSlice?.error ? (
              <div className="text-center text-bold rounded m-100 p-25">{searchProductsSlice?.error}</div>
            ) : null}
            <div className="flex flex-col w-full">
              <div className="md:flex justify-between items-center">
                <div className="flex flex-col w-full">
                  <div className="container px-0 cursor-pointer ">
                    {!searchProductsSlice?.isFetched ? (
                      <Loading />
                    ) : searchProductsSlice?.isFetched && searchProductsSlice?.error ? (
                      <Error message={searchProductsSlice.error} />
                    ) : null}
                  </div>
                  {searchProductsSlice?.isFetched && searchProductsSlice?.fetchedProducts?.length ? (
                    <div
                      className="text-textColor leading-22 text-18 font-normal flex justify-center w-full"
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                    >
                      {searchProductsSlice?.fetchedProducts?.length} search results for{' '}
                      <span className="font-semibold"> &nbsp;&quot;{this.getSearchText()}&quot; </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {!this.props.search[SEARCH_KEY.SEARCH] ||
              (searchProductsSlice?.isFetching && !searchProductsSlice?.fetchedProducts?.length && (
                <div className="flex flex-col md:flex-row mt-50 justify-between items-center w-full">
                  <ProductCardLoading />
                  <ProductCardLoading />
                  <ProductCardLoading />
                  <ProductCardLoading />
                </div>
              ))}

            <InfiniteScroll
              dataLength={searchProductsSlice?.fetchedProducts?.length || 0} //This is important field to render the next data
              next={() => {
                this.props.fetchSearchRequest({
                  storeKey: SEARCH_KEY.SEARCH,
                  queryParams: {
                    ...searchProductsSlice?.currentQueryParams,
                    offset:
                      searchProductsSlice?.currentQueryParams.offset + searchProductsSlice?.currentQueryParams.limit,
                  },
                });
              }}
              hasMore={searchProductsSlice?.fetchedProducts?.length < searchProductsSlice?.search?.count}
              loader={<h4></h4>}
              scrollThreshold={0.8}
            >
              <div className="w-full mt-24 md:mt-30">
                <div className={classnames('flex flex-wrap h-full justify-evenly md:justify-start gap-10 md:gap-32')}>
                  {searchProductsSlice?.fetchedProducts?.map((product, index: number) => (
                    <div className={classnames('')} key={index}>
                      <ProductCard
                        addToast={this.props.addToast}
                        product={product}
                        addProductToCart={this.props.addProductToCart}
                        subtractProductFromCart={this.props.subtractProductFromCart}
                        cartProductQuantity={this.props.cart.quantityByProductId[product.id] || 0}
                        storeInfo={this.props.storeInfo}
                        // dimensionH={'h-145 md:h-280'}
                        // dimensionW={'w-145 md:w-280'}
                        // text={'text-16 md:text-21'}
                        // truncate={18}
                        // padding={''}
                        shopAll={'shopAll'}
                        // dimensionQ={'top-107 left-27'}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </InfiniteScroll>
            {searchProductsSlice?.isFetched && !searchProductsSlice?.search?.results?.length && (
              <>
                <div className="container md:mx-auto px-0 flex justify-center items-center flex-col">
                  <div className="flex flex-col md:mt-40 justify-center h-60v">
                    <div className="flex justify-center">
                      <img className="w-full min-w-full md:h-360 md:mt-20" src={CartImg} alt="Empty cart" />
                    </div>
                    <div className="flex flex-col gap-20 justify-center items-center">
                      <div
                        className="mt-20 text-center text-textColor text-21 md:text-28 leading-25 md:leading-28 font-bold"
                        font-customisation="section-heading"
                        color-customisation="page-heading"
                      >
                        Oops! No matching product found.
                      </div>
                      <button
                        className="text-textColor border-disabled border flex justify-center items-center h-40 font-medium w-fit px-20 rounded-5 text-16 leading-16 text-center"
                        font-customisation="para-text"
                        button-customisation="page-button"
                      >
                        <Link to="/">Shop Now</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {this.props.recentlyViewed.products?.length ? (
          <div className="w-full bg-bgLight -mt-200">
            <div className="container md:mx-auto py-50 md:py-88">
              <RecentlyViewed products={this.props.recentlyViewed.products} />
            </div>
          </div>
        ) : null}
      </PageLayout>
    );
  }
}

export default connect(
  ({ search, categories, category, products, cart, storeInfo, recentlyViewed }: RootState) => ({
    search,
    categories,
    category,
    products,
    cart,
    storeInfo,
    recentlyViewed,
  }),
  {
    fetchProductsRequest,
    fetchCategoriesRequest,
    fetchSearchRequest,
    fetchHomeRequest,
    resetSearchStore,
    addToast,
    addProductToCart,
    subtractProductFromCart,
  },
)(Search);
