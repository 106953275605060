import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent } from 'react';

export interface FormCheckboxRadioGroupProps {
  name: string;
  type: 'checkbox' | 'radio';
  multiple?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  hint?: React.ReactNode;
  error?: React.ReactNode;
  options: {
    id: number | string;
    value: string;
  }[];
  inputClass?: string;
  optionsClass?: string;
  optionsActiveClass?: string;
  spaceY?: string;
  spaceX?: string;
}

const FormCheckboxRadioGroup: React.FC<FormCheckboxRadioGroupProps> = ({
  name,
  error,
  type,
  onChange,
  onBlur,
  value,
  className = '',
  hint,
  options,
  inputClass,
  optionsClass,
  optionsActiveClass,
  spaceY = 'space-y-12',
  spaceX = 'space-x-12',
}) => {
  return (
    <div className={className}>
      <div className={spaceY}>
        {options.map((option) => (
          <div
            key={option.id}
            className={classnames(`flex items-center ${spaceX}`, {
              [optionsClass]: !!optionsClass,
              [optionsActiveClass]: type === 'checkbox' ? !!value[option.id] : value === option.id.toString(),
            })}
            font-customisation="para-text"
            color-customisation="page-body-text"
          >
            <input
              id={option.id.toString()}
              className={classnames(inputClass)}
              name={name}
              type={type}
              value={option.id}
              onChange={(e) => onChange(e)}
              onBlur={(e) => onBlur(e)}
              data-type="multiple"
              checked={type === 'checkbox' ? !!value[option.id] : value === option.id.toString()}
              // error={(submissions || touched.processor) && (errors.processor || asyncErrors.processor)}
            />
            <label
              className="cursor-pointer break-word-only w-full"
              htmlFor={option.id.toString()}
              font-customisation="para-text"
              color-customisation="page-body-text"
            >
              <span>{option.value}</span>
            </label>
          </div>
        ))}
      </div>

      <div className="px-18  text-khamaj-promo md:text-khamaj-black opacity-70 text-12 mt-2">
        {error ? <span className="text-input-error opacity-100">{error}</span> : null}
        {hint && !error ? <span>{hint}</span> : null}
      </div>
    </div>
  );
};

export default FormCheckboxRadioGroup;
