import Product from '@common/models/Product';
import Variant from '@common/models/Variant';
import { addProductToCart, subtractProductFromCart } from '@common/store/cart/slice';
import Button from '@lib/Button';
// import classnames from 'classnames';
import React, { useState } from 'react';

interface StepperProps {
  product: Product | Variant;
  subtractProductFromCart: typeof subtractProductFromCart;
  addProductToCart: typeof addProductToCart;
  quantity: number;
  cartProductQuantity: number;
  isSmallerVariant?: boolean;
  className?: string;
  inputClassName?: string;
  isBiggerVariant?: boolean;
  alterCoupon?: () => void;
  mixpanelProperties?: Record<string, unknown>;
  index: number;
}

const Stepper: React.FC<StepperProps> = ({
  index,
  className,
  product,
  subtractProductFromCart,
  addProductToCart,
  quantity,
  // isSmallerVariant,
  // isBiggerVariant,
  // inputClassName,
  cartProductQuantity,
  alterCoupon,
  mixpanelProperties,
}) => {
  const [disabled, setDisabled] = useState(null);
  return (
    <div>
      <div
        className={`inline-flex justify-between text-textColor flex-no-wrap items-center rounded ${className}`}
        color-customisation="page-body-text"
      >
        <Button
          className=" leading-none no-defaults focus:outline-none disabled:text-disabled disabled:cursor-not-allowed min-h-36 w-36 h-36 min-w-36 text-18 rounded-5"
          onClick={(e) => {
            setDisabled('minus');
            e.preventDefault();
            subtractProductFromCart({ product, mixpanelProperties });
            alterCoupon ? alterCoupon() : null;
            setTimeout(() => {
              setDisabled(null);
            }, 300);
          }}
          disabled={disabled === 'minus'}
          id={'cart-quantity-decrement-' + (index + 1)}
        >
          <span className="font-medium">–</span>
        </Button>
        <input
          readOnly
          type="text"
          className="w-50 text-center focus:outline-none text-16 font-normal text-textColor"
          value={quantity}
          onClick={(e) => {
            e.preventDefault();
          }}
          color-customisation="page-body-text"
        />
        <Button
          className="leading-none no-defaults focus:outline-none disabled:text-disabled disabled:cursor-not-allowed h-36 w-36 min-h-36 min-w-36 text-18 rounded-5 "
          onClick={(e) => {
            setDisabled('plus');
            e.preventDefault();
            addProductToCart({ product, disableSuccessToasts: true, mixpanelProperties });
            alterCoupon ? alterCoupon() : null;
            setTimeout(() => {
              setDisabled(null);
            }, 300);
          }}
          disabled={cartProductQuantity >= Math.min(product.stock, product.maxQuantity) || disabled === 'plus'}
          id={'cart-quantity-increment-' + (index + 1)}
        >
          <span className="font-medium">+</span>
        </Button>
      </div>
    </div>
  );
};

export default Stepper;
