import { ProductDetailsSliceType } from '@common/store/productDetails/slice';
import { getLambdaLink, getTrailingSlashUrl } from '@common/utils';
import React from 'react';
import { Helmet } from 'react-helmet';

interface ProductSEOTagsProps {
  productDetails: ProductDetailsSliceType;
}

const ProductSEOTags: React.FC<ProductSEOTagsProps> = ({ productDetails }) => {
  const product = productDetails.productDetails;
  const isProductFetched = productDetails.isFetched && !productDetails.error;
  const image = product?.images?.length && getLambdaLink({ link: product?.images[0], width: 400, height: 346 });

  return isProductFetched ? (
    <Helmet>
      <title>{product?.seoSettings?.metaTitle || product?.title}</title>
      {product?.seoSettings?.metaKeywords.length ? (
        <meta name="keywords" content={product?.seoSettings?.metaKeywords.join(',')} />
      ) : null}
      {product?.seoSettings?.metaDescription ? (
        <meta name="description" content={product?.seoSettings?.metaDescription} />
      ) : null}
      {product?.seoSettings?.metaDescription ? (
        <meta property="og:description" content={product?.seoSettings?.metaDescription} />
      ) : null}
      {image && <meta itemProp="image" property="og:image" content={image} />}
      {image && <meta property="og:image:width" content="400" />}
      {image && <meta property="og:image:height" content="346" />}
      <meta property="product:retailer_item_id" content={product.id?.toString()} />
      <meta property="product:price:currency" content="INR" />
      <meta property="product:price:amount" content={product?.finalPrice?.toString()} />
      <meta property="product:availability" content={product.inStock ? 'in stock' : 'out of stock'} />

      <link rel="canonical" href={getTrailingSlashUrl(product?.seoSettings?.metaCanonicalUrl || product?.fullURL)} />
    </Helmet>
  ) : null;
};

export default ProductSEOTags;
