import './index.css';

import { ReactComponent as Copy } from '@common/icons/Copy.svg';
import { ReactComponent as VisibilityOff } from '@common/icons/VisibilityOff.svg';
import { ReactComponent as VisibilityOn } from '@common/icons/VisibilityOn.svg';
import { store } from '@common/store';
import { addToast } from '@common/store/toasts/slice';
import { copyTextToClipboard } from '@common/utils';
import { ToastVariant } from '@lib/Toast';
import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, useState } from 'react';

import useDimensions from '../../common/hooks/useDimensions';

export interface FormInputProps {
  name: string;
  label: string;
  type: string;
  multiple?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  hint?: React.ReactNode;
  error?: React.ReactNode;
  enableCopyToClipboard?: boolean;
  enableVisbilityToggle?: boolean;
  inputClassName?: string;
  maxLength?: number;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  error,
  label,
  type,
  multiple,
  onChange,
  onBlur,
  value,
  className = '',
  inputClassName = '',
  hint,
  enableCopyToClipboard,
  enableVisbilityToggle,
  maxLength,
}) => {
  const isTypePassword = type === 'password';
  const [showPassword, setShowPassword] = useState(false);
  const modifiedType = isTypePassword && showPassword ? 'text' : type;
  const [ref, dimensions] = useDimensions();
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={className}>
      <div
        ref={ref}
        className={classnames(
          'form-input-wrapper text-15 relative border rounded border-input-border focus-within:border-theme focus-within:border-2',
          {
            'form-input-wrapper--error border-input-error': error,
            'h-48': type !== 'textarea',
            'h-200': type === 'textarea',
          },
        )}
      >
        <input
          className={classnames(
            'w-full appearance-none focus:outline-none bg-transparent h-full rounded',
            inputClassName,
            {
              'px-16': !enableCopyToClipboard,
              'pl-16 pr-110': enableCopyToClipboard,
            },
          )}
          type={modifiedType}
          id={name}
          autoComplete="new-password"
          name={name}
          multiple={multiple}
          placeholder=" "
          value={value}
          maxLength={maxLength}
          onFocus={() => setIsFocused(true)}
          onChange={(e) => onChange(e)}
          onBlur={(e) => {
            setIsFocused(false);
            onBlur(e);
          }}
          font-customisation="para-text"
        />
        <label
          className="block top-0 px-16 mt-14 absolute pointer-events-none text-input-label opacity-75 overflow-hidden"
          htmlFor={name}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            // eslint-disable-next-line
            // @ts-ignore
            maxWidth: `${dimensions?.width - (isFocused || value ? 28 : 0)}px`,
          }}
          font-customisation="para-text"
        >
          {label}
        </label>
        {enableCopyToClipboard ? (
          <div
            className="absolute select-none top-0 right-0 h-full bg-white flex items-center px-16 shadow-copyToClipboard rounded-r cursor-pointer"
            onClick={() => {
              copyTextToClipboard(value);
              store.dispatch(
                addToast({
                  content: `Copied to Clipboard`,
                  variant: ToastVariant.success,
                  dismissAfterMillis: 2000,
                }),
              );
            }}
          >
            <Copy className="mr-8" />
            <div className="text-14 text-theme select-none">Copy</div>
          </div>
        ) : null}
        {isTypePassword && enableVisbilityToggle ? (
          <div
            className={classnames(
              'text-visibility-off absolute select-none top-0 right-0 h-full bg-white flex items-center px-12 cursor-pointer',
            )}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? <VisibilityOn className="fill-current" /> : <VisibilityOff className="fill-current" />}
          </div>
        ) : null}
      </div>
      <div className="px-2 text-input-hint opacity-70 text-12 mt-4">
        {error ? <span className="text-input-error opacity-100">{error}</span> : null}
        {hint && !error ? <span>{hint}</span> : null}
      </div>
    </div>
  );
};

export default FormInput;
