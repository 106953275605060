import { ToastVariant } from '@common/../lib-components/Toast';
import { store } from '@common/store';
import { CategoryAttributesSliceType } from '@common/store/categoryAttributes/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  PRODUCTS_SORT_ORDERING_TYPE,
  ProductsSliceType,
} from '@common/store/products/slice';
import { addToast } from '@common/store/toasts/slice';
import { generateFacetsString } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { Dispatch } from 'react';

interface ComputeSortByOptionsArgs {
  categoryProductsSlice: ProductsSliceType;
  dispatch: Dispatch<unknown>;
  sortDefaultName?: string;
  sortDefaultUnselectedName?: string;
}

export interface ComputeSortByOptionsArgsReturnType {
  ALL_SORT_OPTIONS;
  UNSELECTED_SORT_OPTIONS;
  SELECTED_SORT_OPTION;
  DEFAULT_SORT_OPTION;
}

export const computeSortByOptions = ({
  categoryProductsSlice,
  dispatch,
  sortDefaultName,
  sortDefaultUnselectedName,
}: ComputeSortByOptionsArgs): ComputeSortByOptionsArgsReturnType => {
  const onSortClick = (ordering: PRODUCTS_SORT_ORDERING_TYPE) => {
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.CATEGORY,
        queryParams: { ...categoryProductsSlice?.currentQueryParams, ordering, page: 1 },
      }),
    );
  };
  const ALL_SORT_OPTIONS = [
    { name: 'Price (Low to High)', key: 'final_price', onClick: () => onSortClick('final_price') },
    { name: 'Price (High to Low)', key: '-final_price', onClick: () => onSortClick('-final_price') },
    { name: 'A to Z', key: 'title', onClick: () => onSortClick('title') },
    { name: 'Z to A', key: '-title', onClick: () => onSortClick('-title') },
    { name: 'Latest', key: '-created', onClick: () => onSortClick('-created') },
    {
      name: sortDefaultName ?? 'Select',
      unSelectedName: sortDefaultUnselectedName ?? 'Clear Selection',
      key: 'sort_order',
      onClick: () => onSortClick('sort_order'),
    },
  ];

  const UNSELECTED_SORT_OPTIONS = ALL_SORT_OPTIONS.filter(
    (x) => x.key != categoryProductsSlice?.currentQueryParams?.ordering,
  );
  const SELECTED_SORT_OPTION = ALL_SORT_OPTIONS.find(
    (x) => x.key === categoryProductsSlice?.currentQueryParams?.ordering,
  );
  const DEFAULT_SORT_OPTION = ALL_SORT_OPTIONS.find((x) => x.key === 'sort_order');
  return { ALL_SORT_OPTIONS, UNSELECTED_SORT_OPTIONS, SELECTED_SORT_OPTION, DEFAULT_SORT_OPTION };
};

interface ComputeFacetTagsArgs {
  categoryProductsSlice: ProductsSliceType;
  dispatch: Dispatch<unknown>;
}

export interface ComputeFacetTagsReturnType {
  FACET_TAGS;
  FILTER_MAPPING;
  onFacetTagClick;
  NUMBER_OF_FACETS_APPLIED;
}
export interface FacetTag {
  facetKey: string;
  facetValue: string;
}

export const getFacetMappingAndTags = (facets: string): { FACET_TAGS; FILTER_MAPPING; NUMBER_OF_FACETS_APPLIED } => {
  const FILTER_MAPPING =
    facets
      ?.split('|')
      ?.filter((facet) => facet.includes(':'))
      ?.reduce((acc, facet) => {
        const [key, value] = facet.split(':');
        if (!(key && value)) {
          return acc;
        }
        const valueArray = value.split(';');
        return {
          ...acc,
          [key]: valueArray,
        };
      }, {}) || {};
  const FACET_TAGS = Object.keys(FILTER_MAPPING)
    ?.map((facetKey) => {
      return FILTER_MAPPING[facetKey]?.map((facetValue) => ({
        facetKey,
        facetValue,
      }));
    })
    ?.reduce((a, b) => {
      return a.concat(b);
    }, []);
  const NUMBER_OF_FACETS_APPLIED = FACET_TAGS.length;

  return { FILTER_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED };
};

export const computeFacetTags = ({
  categoryProductsSlice,
  dispatch,
}: ComputeFacetTagsArgs): ComputeFacetTagsReturnType => {
  const productFilter = categoryProductsSlice?.currentQueryParams?.product_filter || '';

  const { FILTER_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED } = getFacetMappingAndTags(productFilter);
  const onFacetTagClick = (facetTagToRemove: FacetTag) => {
    const newFacetString =
      Object.keys(FILTER_MAPPING)
        .reduce((acc, facetKey) => {
          let facetValues = [...FILTER_MAPPING[facetKey]];
          if (facetTagToRemove.facetKey === facetKey) {
            facetValues = facetValues.filter((x) => x !== facetTagToRemove.facetValue);
          }
          if (facetValues.length) {
            return [...acc, `${facetKey}:${facetValues.join(';')}`];
          } else {
            return acc;
          }
        }, [])
        .join('|') || undefined;

    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.CATEGORY,
        queryParams: { ...categoryProductsSlice?.currentQueryParams, page: 1, product_filter: newFacetString },
      }),
    );
  };

  return { FACET_TAGS, FILTER_MAPPING, onFacetTagClick, NUMBER_OF_FACETS_APPLIED };
};

interface ComputePriceTagArgs {
  categoryProductsSlice: ProductsSliceType;
  categoryAttributes: CategoryAttributesSliceType;
  dispatch: Dispatch<unknown>;
}

export interface ComputeSortByTagReturnType {
  SORTBY_TAG;
  onSortByTagClick;
}

export interface ComputePriceTagReturnType {
  PRICE_TAG;
  onPriceTagClick;
}

export const computePriceTag = ({
  categoryProductsSlice,
  dispatch,
}: ComputePriceTagArgs): ComputePriceTagReturnType => {
  const { final_price__gte, final_price__lte } = categoryProductsSlice?.currentQueryParams || {};

  let PRICE_TAG;

  if (final_price__gte != null && final_price__lte != null) {
    PRICE_TAG = { minPrice: final_price__gte, maxPrice: final_price__lte };
  }

  const onPriceTagClick = () => {
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.CATEGORY,
        queryParams: {
          ...categoryProductsSlice?.currentQueryParams,
          page: 1,
          final_price__gte: undefined,
          final_price__lte: undefined,
        },
      }),
    );
  };

  return { PRICE_TAG, onPriceTagClick };
};

export const computeSortByTag = ({
  categoryProductsSlice,
  dispatch,
  sortOptions,
}: ComputePriceTagArgs): ComputeSortByTagReturnType => {
  const sortBy = categoryProductsSlice?.currentQueryParams?.ordering || '';
  const SORTBY_TAG = sortOptions.filter((list) => list.key === sortBy && list.name !== 'Select')[0];
  const onSortByTagClick = () => {
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.CATEGORY,
        queryParams: {
          ...categoryProductsSlice?.currentQueryParams,
          page: 1,
          ordering: 'sort_order',
        },
      }),
    );
  };

  return { SORTBY_TAG, onSortByTagClick };
};

interface CategoryAttributesSubmitHandlerArgs {
  values: Record<string, unknown>;
  disableFacets?: boolean;
  disablePrice?: boolean;
}

export const categoryAttributesSubmitHandler = async ({
  values,
  disableFacets,
  disablePrice,
}: CategoryAttributesSubmitHandlerArgs): Promise<void> => {
  const { minPrice: formMinPrice, maxPrice: formMaxPrice, ordering, ...facetFormValues } = values;
  const order = ordering;
  const maxPrice = +formMaxPrice || undefined;
  const minPrice = +formMinPrice || undefined;
  const productFilters = generateFacetsString(facetFormValues);

  if (maxPrice < minPrice) {
    store.dispatch(
      addToast({
        content: 'Max price must be greater than min price.',
        variant: ToastVariant.error,
        dismissAfterMillis: 5000,
      }),
    );
    return;
  }

  const currentQueryParams =
    store.getState().products[PRODUCTS_KEY.CATEGORY]?.currentQueryParams || getProductQueryParamsInitialState();

  store.dispatch(
    fetchProductsRequest({
      storeKey: PRODUCTS_KEY.CATEGORY,
      queryParams: {
        ...currentQueryParams,
        ...(!disablePrice ? { final_price__lte: maxPrice, final_price__gte: minPrice } : {}),
        ...(!disableFacets ? { product_filter: productFilters } : {}),
        ordering: order || '',
        page: 1,
      },
    }),
  );
};
