import Product from '@common/models/Product';
import { RecentlyViewedTime } from '@common/store/recentlyViewed/slice';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';

interface RecentlyViewedProps {
  products: Array<Product> | Record<number, Product & RecentlyViewedTime>;
  title?: string;
  ignoreProducts?: Set<number>;
}

const RecentlyViewed: React.FunctionComponent<RecentlyViewedProps> = ({
  products,
  ignoreProducts = new Set(),
  title = 'Recently Viewed',
}) => {
  const filteredProducts = (Array.isArray(products) ? [...products] : Object.values(products))
    .reverse()
    .filter((product) => !ignoreProducts.has(product.id));

  if (!filteredProducts.length) {
    return null;
  }
  return (
    <>
      <div className="text-label text-14 mb-16" font-customisation="section-heading" color-customisation="page-heading">
        {title}
      </div>
      <div className="flex flex-no-wrap overflow-x-auto mb-72">
        {filteredProducts.map((product) => (
          <Link
            key={product.id}
            to={product.storeLink}
            className="bg-white shadow-productDetailCard mb-2 max-w-120 flex-1 max-w- mx-8 first:ml-0"
          >
            <div className={'w-full flex justify-center h-170 mb-6'}>
              <Image src={product.images[0]} alt={product.title} />
            </div>
            <div className="px-6 pb-6">
              <div
                className="font-semibold text-10 text-black mb-2 break-words"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                {product.title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default RecentlyViewed;
