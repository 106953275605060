import React from 'react';

const ProductCardLoading: React.FunctionComponent = () => {
  return (
    <div className="bg-white w-248 mb-40 hover:shadow">
      <div className=" has-shimmer w-full flex justify-center h-230 shadow hover:shadow-none"></div>
      <div className="p-10 bg-white">
        <div className="has-shimmer font-semibold text-10 text-black mb-2">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div className="flex justify-between items-center">
          <div className="">
            <div className="has-shimmer  font-normal text-10 w-150 mb-4">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className="has-shimmer text-theme font-normal text-10 w-150">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardLoading;
