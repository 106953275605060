import Category from '@common/models/Category';
import DropDown from '@images/downVector.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import classnames from 'classnames';
import React, { useState } from 'react';
interface CategorySidebarItemProps {
  category: Category;
}

const CategorySidebarItem: React.FC<CategorySidebarItemProps> = ({ category }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="w-full flex justify-between items-center mt-30">
        <Link to={category.storeLink}>
          <div className="text-14 md:text-16 leading-22 md:leading-25 font-semibold hover:font-semibold text-textColor">
            {category.name}
            {/* {category.childCategories?.length > 0 ? <span>({category.childCategories?.length})</span> : null} */}
          </div>
        </Link>
        <div
          className={classnames('justify-self-end', {
            'text-label': !isOpen,
            invisible: !category.childCategories?.length,
            'cursor-pointer': category.childCategories?.length,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <Image src={DropDown} alt="up" className="transform -rotate-180 min-w-10 min-h-10 h-10 w-10" />
          ) : (
            <Image src={DropDown} alt="Down" className="min-w-10 min-h-10 h-10 w-10" />
          )}
        </div>
      </div>
      {isOpen &&
        category.childCategories?.map((category) => (
          <div className="my-20 ml-10" key={category.id}>
            <Link to={category.storeLink}>
              <div className="w-full text-14 md:text-16 leading-22 md:leading-25 font-normal hover:font-semibold text-textColor">
                {category.name}
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};

export default CategorySidebarItem;
