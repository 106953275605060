import { ReactComponent as CartIcon } from '@common/icons/Cart.svg';
import classnames from 'classnames';
import React from 'react';

export interface ButtonProps {
  type?: 'addToCart' | 'buyNow' | 'addToCartAlt' | 'orders' | 'submit' | 'viewProduct' | 'enquireNow' | 'expired';
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Button: React.FunctionComponent<ButtonProps> = ({ type, className = '', children, ...props }) => {
  const buttonProps = {
    type: undefined,
  };
  if (type === 'buyNow') {
    buttonProps.type = 'submit';
  }
  return (
    <button
      {...buttonProps}
      className={classnames(
        {
          'text-14 text-theme font-medium rounded-sm bg-white h-40 p-12 flex justify-center items-center border border-addToCart-border  hover:border-theme':
            type === 'addToCart' || type === 'viewProduct',
          'rounded bg-theme md:max-w-220 h-40 p-12 flex justify-center items-center text-white disabled:cursor-not-allowed disabled:bg-disabled':
            type === 'buyNow' || type === 'enquireNow' || type === 'expired',
          'rounded bg-white md:max-w-220 h-40 p-12 flex justify-center items-center border border-theme disabled:cursor-not-allowed disabled:border-disabled disabled:text-disabled':
            type === 'addToCartAlt',
          'rounded-sm h-36 md:w-124 flex justify-center items-center font-medium text-13 text-theme': type === 'orders',
          'rounded-md': className && !className.includes('no-defaults') && !type,
        },
        className,
      )}
      {...props}
      id={
        type === 'addToCart' || type === 'addToCartAlt' ? 'add-to-cart-btn' : type === 'buyNow' ? 'buy-now-btn' : type
      }
    >
      {(type === 'addToCart' || type === 'addToCartAlt') && (
        <>
          <CartIcon className="mr-8 fill-current" />
          <span>Add to Cart</span>
        </>
      )}
      {type === 'viewProduct' && (
        <>
          <span>View Product</span>
        </>
      )}
      {type === 'buyNow' && (
        <>
          <span>Buy Now</span>
        </>
      )}
      {type === 'expired' && (
        <>
          <span>Unavailable</span>
        </>
      )}
      {type === 'enquireNow' && (
        <>
          <span>Enquire Now</span>
        </>
      )}
      {children}
    </button>
  );
};

export default Button;
