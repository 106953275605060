// import { ReactComponent as WhatsappShare } from '@common/icons/WhatsappShare.svg';
import Product from '@common/models/Product';
// import { isMobile } from '@common/utils';
import { copyTextToClipboard } from '@common/utils';
import { context } from '@common/utils/constants';
import Copy from '@images/copy.svg';
// import { ReactComponent as InstagramShare } from '@common/icons/InstagramShare.svg';
// import { ReactComponent as ShareIcon } from '@common/icons/ShareIcon.svg';
import GoogleFooter from '@images/email.svg';
import FacebookFooter from '@images/fb.svg';
import TwitterFooter from '@images/twitter.svg';
// import { CopyV2Icon, EmailIcon, FacebookIcon, TwitterIcon, WhatsAppIcon } from '@instamojo/icons';
import React, { useState } from 'react';

export interface ShareProps {
  className?: string;
  product: Product;
}

const Share: React.FunctionComponent<ShareProps> = ({ className = '', product }) => {
  const facebookURL = `https://www.facebook.com/dialog/feed?app_id=${context.FACEBOOK_APP_ID}&link=${product.fullURL}&picture=${product.images[0]}&name=${product.title}&caption=${product.shortDescription}&description=${product.description}&redirect_uri=http://www.facebook.com`;

  const tweetContent = `Check out ${product.title} at`;
  const twitterURL = `https://twitter.com/intent/tweet?text=${tweetContent}&url=${product.fullURL}`;

  // const whatsappContent = `Check out ${product.title} at ${product.fullURL}`;
  // const whatsappURL = isMobile()
  //   ? `https://api.whatsapp.com/send?text=${whatsappContent}`
  //   : `https://web.whatsapp.com/send?text=${whatsappContent}`;

  const mailSubject = `Check out ${product.title}`;
  const mailBody = `Check out ${product.title} at ${product.fullURL}`;
  const mailURL = `mailto:?subject=${mailSubject}&body=${mailBody}`;

  const [isCopyToClipboardShown, setIsCopyToClipboardShown] = useState(false);

  return (
    <div>
      <div className={`flex justify-start gap-15 items-center w:full relative ${className}`}>
        <div className="flex items-center">
          <span className="text-share-text text-14" font-customisation="para-text" color-customisation="page-body-text">
            Share:
          </span>
        </div>
        <div className="flex items-center gap-8">
          <a
            href={facebookURL}
            className="border border-share-text border-opacity-30 h-34 w-34 flex justify-center items-center"
          >
            <img src={FacebookFooter} className="fill-current h-14" />
          </a>
          <a
            href={twitterURL}
            className="border border-share-text border-opacity-30 h-34 w-34 flex justify-center items-center"
          >
            <img src={TwitterFooter} className="h-12" />
          </a>
          <a
            href={mailURL}
            className="border border-share-text border-opacity-30 h-34 w-34 flex justify-center items-center"
          >
            <img src={GoogleFooter} className="h-12" />
          </a>
          <span
            onClick={() => {
              copyTextToClipboard(product.shortLink || product.fullURL);
              setIsCopyToClipboardShown(true);
              setTimeout(() => {
                setIsCopyToClipboardShown(false);
              }, 2000);
            }}
          >
            <span
              title="Copy product short link"
              className="border border-share-text border-opacity-30 h-34 w-34 flex justify-center items-center"
            >
              <img src={Copy} className="h-14" />
            </span>
          </span>
        </div>
        {isCopyToClipboardShown && (
          <div
            style={{ top: 60 }}
            className="bg-black absolute right-0 text-white px-12 py-4 rounded-md copy-clipboard-msg"
          >
            Copied product short link
          </div>
        )}
      </div>
    </div>
  );
};

export default Share;
