import useForm, { FormReturnType } from '@common/hooks/useForm';
import { usePrevious } from '@common/hooks/usePrevious';
import { CategoriesSliceType } from '@common/store/categories/slice';
import { CategoryPriceLimitsSliceType } from '@common/store/priceLimits/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { ShopAttributesSliceType } from '@common/store/shopAttributes/slice';
import { generateFacetsString } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { validateGreaterThanEqualtoZero, validateNumber, validateRequired } from '@common/utils/validations';
import { Dispatch, useEffect } from 'react';

import {
  FacetTag,
  // getCategoryMappingAndTags,
  getFacetMappingAndTags,
} from './utils';

interface UsePriceRangeArgs {
  priceLimits: CategoryPriceLimitsSliceType;
  noOfRanges: number;
  currentMinPrice: number;
  currentMaxPrice: number;
}

interface UsePriceRangeReturnType {
  PRICE_RANGES: PriceRangeInterface[];
  MIN_MAX_DIFFERENCE?: number;
  STEP: number;
  MIN_PRICE?: number;
  MAX_PRICE?: number;
}

interface PriceRangeInterface {
  maxPrice: number;
  minPrice: number;
  isSelected: boolean;
}

export const useProductPrice = ({
  priceLimits,
  noOfRanges,
  currentMinPrice,
  currentMaxPrice,
}: UsePriceRangeArgs): UsePriceRangeReturnType => {
  const minPrice = priceLimits?.priceLimits?.minPrice;
  const maxPrice = priceLimits?.priceLimits?.maxPrice;

  const PRICE_RANGES = [];
  let STEP = 1;
  let MIN_MAX_DIFFERENCE;

  if (minPrice && maxPrice && minPrice != maxPrice) {
    MIN_MAX_DIFFERENCE = maxPrice - minPrice;
    const increment = (maxPrice - minPrice) / noOfRanges;
    for (let i = minPrice; +i.toFixed(0) < maxPrice; i += increment) {
      const rangeMinPrice = +i.toFixed(0);
      const rangeMaxPrice = +(i + increment).toFixed(0);
      const isSelected = rangeMinPrice == currentMinPrice && rangeMaxPrice == currentMaxPrice;

      PRICE_RANGES.push({
        minPrice: rangeMinPrice,
        maxPrice: rangeMaxPrice,
        isSelected,
      });
    }

    if (MIN_MAX_DIFFERENCE > 100 && MIN_MAX_DIFFERENCE < 1000) {
      STEP = 10;
    } else if (MIN_MAX_DIFFERENCE > 1000 && MIN_MAX_DIFFERENCE < 10000) {
      STEP = 50;
    } else if (MIN_MAX_DIFFERENCE > 10000) {
      STEP = 1000;
    }
  }

  return { PRICE_RANGES, MIN_MAX_DIFFERENCE, STEP, MIN_PRICE: minPrice, MAX_PRICE: maxPrice };
};

const validateShopAttributesForm = (values: Record<string, string>) => {
  const errors = {
    minPrice: [
      validateRequired(values.minPrice),
      validateNumber(values.minPrice),
      validateGreaterThanEqualtoZero(values.minPrice),
    ].filter((x) => x != null)[0],
    maxPrice: [
      validateRequired(values.maxPrice),
      validateNumber(values.maxPrice),
      validateGreaterThanEqualtoZero(values.maxPrice),
    ].filter((x) => x != null)[0],
  };
  return errors;
};

interface UseProductAttributesFormArgs {
  shopAllProductsSlice: ProductsSliceType;
  shopAttributes: ShopAttributesSliceType;
  shouldFetchOnFacetChange: boolean;
  shouldFetchOnPriceChange: boolean;
  dispatch: Dispatch<unknown>;
  categories: CategoriesSliceType;
}

export interface UseProductAttributesReturnType {
  form: FormReturnType;
  productFilters: string;
  shouldFetchOnFacetChange: boolean;
  shouldFetchOnPriceChange: boolean;
  computedFormFacetProperties: {
    FACET_MAPPING;
    FACET_TAGS;
    NUMBER_OF_FACETS_APPLIED;
    onFacetTagClick;
  };
  computedFormPriceProperties: {
    PRICE_TAG;
    onPriceTagClick;
  };
  TOTAL_FORM_ATTRIBUTES_APPLIED;
}
export const useProductAttributesForm = ({
  shopAllProductsSlice,
  shopAttributes,
  shouldFetchOnFacetChange,
  shouldFetchOnPriceChange,
  dispatch,
  categories: allCategories,
}: UseProductAttributesFormArgs): UseProductAttributesReturnType => {
  const form = useForm({ validate: validateShopAttributesForm });
  const { values, setValues } = form;
  const { minPrice, maxPrice, ...facetFormValues } = values;

  const filtertedSelectedCategories =
    facetFormValues.category && Object.keys(facetFormValues?.category).length
      ? Object.keys(facetFormValues?.category).filter((item) => facetFormValues?.category[item])
      : [];

  const categories = filtertedSelectedCategories.length > 0 ? filtertedSelectedCategories.join(',') : undefined;
  const productFilters = generateFacetsString(facetFormValues);

  useSetProductAttributeValues({ shopAllProductsSlice, shopAttributes, setValues, allCategories });

  const prevValues = usePrevious(values);
  const prevFacets = usePrevious(productFilters);
  const prevCategories = usePrevious(categories);

  useEffect(() => {
    if (!form.hasHandleChangeExecuted) {
      return;
    }

    let overrideParams = {} as Record<string, unknown>;
    const initialParams = overrideParams;
    if (shouldFetchOnFacetChange && prevFacets !== productFilters) {
      overrideParams = {
        ...overrideParams,
        product_filter: productFilters,
        page: 1,
      };
    }
    if (shouldFetchOnPriceChange && prevValues.minPrice !== values.minPrice && prevValues.maxPrice != values.maxPrice) {
      overrideParams = {
        ...overrideParams,
        final_price__gte: minPrice,
        final_price__lte: maxPrice,
        page: 1,
      };
    }
    if (shouldFetchOnFacetChange && prevCategories !== categories) {
      overrideParams = {
        ...overrideParams,
        category: categories,
        page: 1,
      };
    }

    if (initialParams === overrideParams) {
      return;
    }

    const currentQueryParams = shopAllProductsSlice?.currentQueryParams || getProductQueryParamsInitialState();
    dispatch(resetProductsStore(PRODUCTS_KEY?.SHOP));
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY?.SHOP,
        queryParams: {
          ...currentQueryParams,
          ...overrideParams,
        },
      }),
    );
  }, [productFilters, minPrice, maxPrice, categories]);

  const { FACET_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED } = getFacetMappingAndTags(productFilters);

  // const { CATEGORY_MAPPING, CATEGORY_TAGS, NUMBER_OF_CATEGORY_APPLIED } = getCategoryMappingAndTags(
  //   categories,
  //   allCategories,
  // );
  const onFacetTagClick = (facetTagToRemove: FacetTag) => {
    const newValues =
      Object.keys(form.values).reduce((acc, facetKey) => {
        if (facetTagToRemove.facetKey == facetKey) {
          if (typeof form.values[facetKey] === 'string') {
            return acc;
          } else if (typeof form.values[facetKey] === 'object') {
            const facetValues = { ...form.values[facetKey] };
            delete facetValues[facetTagToRemove.facetValue];
            return {
              ...acc,
              [facetKey]: facetValues,
            };
          }
        }
        return {
          ...acc,
          [facetKey]: form.values[facetKey],
        };
      }, {}) || {};
    form.setValues(newValues);
  };

  let PRICE_TAG;
  if (minPrice != null && maxPrice != null) {
    PRICE_TAG = { minPrice, maxPrice };
  }

  const onPriceTagClick = () => {
    setValues({ ...facetFormValues });
  };

  const computedFormFacetProperties = { FACET_MAPPING, FACET_TAGS, NUMBER_OF_FACETS_APPLIED, onFacetTagClick };
  const computedFormPriceProperties = { PRICE_TAG, onPriceTagClick };
  const TOTAL_FORM_ATTRIBUTES_APPLIED =
    computedFormFacetProperties.NUMBER_OF_FACETS_APPLIED + (computedFormPriceProperties.PRICE_TAG ? 1 : 0);
  return {
    form,
    productFilters,
    shouldFetchOnFacetChange,
    shouldFetchOnPriceChange,
    computedFormFacetProperties,
    computedFormPriceProperties,
    TOTAL_FORM_ATTRIBUTES_APPLIED,
  };
};

interface UseSetProductAttributeValuesArgs {
  shopAllProductsSlice: ProductsSliceType;
  shopAttributes: ShopAttributesSliceType;
  setValues: (value: Record<string, string>, ignoreHandleChange: boolean) => void;
  allCategories: CategoriesSliceType;
}

export const useSetProductAttributeValues = ({
  shopAllProductsSlice,
  shopAttributes,
  setValues,
}: UseSetProductAttributeValuesArgs): void => {
  const productFilter = shopAllProductsSlice?.currentQueryParams?.product_filter;
  const minPrice = shopAllProductsSlice?.currentQueryParams?.final_price__gte;
  const maxPrice = shopAllProductsSlice?.currentQueryParams?.final_price__lte;
  const category = shopAllProductsSlice?.currentQueryParams?.category;
  const ordering = shopAllProductsSlice?.currentQueryParams?.ordering;
  const fetchShopKeyType = (id: number): string => {
    let type = '';
    const hit = shopAttributes.shopAttributes?.results?.find((key) => key.id === id);
    if (hit) {
      type = hit?.filterType;
    }
    return type;
  };
  useEffect(() => {
    if (!shopAttributes?.isFetched) {
      return;
    }
    const formObject = {
      minPrice,
      maxPrice,
      ordering,
    };
    productFilter?.split('|').forEach((entry) => {
      const [key, values] = entry.split(':');
      const numberKey = +key;
      const filterType = fetchShopKeyType(numberKey);

      if (filterType === 'radio') {
        formObject[numberKey] = values;
      } else if (filterType === 'checkbox') {
        formObject[numberKey] = values.split(';').reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
    });
    if (category) {
      const splitCategories = category.split(',');
      formObject['category'] = splitCategories.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
    }
    setValues((formObject as unknown) as Record<string, string>, true);
  }, [productFilter, category, minPrice, maxPrice, ordering, shopAttributes?.isFetched]);
};
