import ProductCardLoading from '@common/components/ProductCard/ProductCardLoading';
import Rupee from '@common/components/Rupee';
import { ShopAllContext, ShopAllProvider } from '@common/context/ShopAllContext';
import Arrow from '@common/images/downVector.svg';
import Close from '@common/images/facetClose.svg';
import Filter from '@common/images/Filter.svg';
import CartImg from '@common/images/noProducts.svg';
import Sort from '@common/images/Sort.svg';
import { RootState } from '@common/store';
import { addProductToCart, subtractProductFromCart } from '@common/store/cart/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { addToast } from '@common/store/toasts/slice';
import { PRODUCTS_KEY } from '@common/utils/constants';
import PageLayout from '@components/PageLayout';
import ProductCard from '@components/ProductCard';
import { ReactComponent as BreadcrumbRightArrow } from '@images/sArrow.svg';
import Image from '@lib/Image';
import InfiniteScroll from '@lib/InfiniteScroll';
import InjectScript from '@lib/InjectScript';
import Link from '@lib/Link';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AttributesModal from './AttributesModal';
import SortByModal from './SortByModal';

const ShopAll = () => {
  const {
    shopAllProductsSlice,
    shopAttributes,
    priceLimits,
    computedSortOptions: { SELECTED_SORT_OPTION, UNSELECTED_SORT_OPTIONS },
    TOTAL_ATTRIBUTES_APPLIED,
    computedFacetProperties: { FACET_TAGS, onFacetTagClick },
    computedPriceProperties: { PRICE_TAG, onPriceTagClick },
    computedCategoryProperties: { CATEGORY_TAGS, onFacetTagClick: onCategoryTagClick },
  } = useContext(ShopAllContext);
  const cart = useSelector(({ cart }: RootState) => cart);
  const storeInfo = useSelector(({ storeInfo }: RootState) => storeInfo);
  const dispatch = useDispatch();
  const [showAttributesModal, setShowAttributesModal] = useState(false);
  const [showwebAttributesModal, setShowwebAttributesModal] = useState(false);
  const [showSortOptionsModal, setShowSortOptionsModal] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const productIds = {};

  const noProducts = () => {
    if (
      shopAttributes?.shopAttributes?.results?.length ||
      Object.keys(priceLimits?.priceLimits).length ||
      shopAllProductsSlice?.list?.count
    ) {
      return true;
    }
    return false;
  };

  return (
    <PageLayout hideToast showBranding={false}>
      <InjectScript others="shop" />
      {noProducts() ? (
        <div className="w-full py-30">
          <div
            className="container md:mx-auto flex items-center text-textColor text-14 leading-20"
            font-customisation="para-text"
          >
            <div className="flex items-center font-normal">
              <Link to="/" className="mr-4 cursor-pointer text-disabled" color-customisation="page-body-text">
                Home
              </Link>
              <BreadcrumbRightArrow className="mr-4 transform -rotate-90 h-6 w-6" fill="#999999" />
              <span className="text-14 text-textColor font-regular" color-customisation="page-body-text">
                Shop All
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="container flex md:mx-auto min-h-screen px-0 sm:px-20 xl:px-112">
        <div
          className={classnames('', {
            'fixed inset-0 mt-100 md:mt-0 right-0 overflow-y-auto bg-textColor bg-opacity-20 z-999': showwebAttributesModal,
          })}
        >
          <OutsideClickHandler onOutsideClick={() => setShowwebAttributesModal(false)}>
            <div
              className={classnames(
                'w-0 fixed top-0 bottom-0 mt-100 md:mt-0 left-0 overflow-y-auto bg-white z-999 shadow-modal',
                {
                  'w-full md:w-435 p-30': showwebAttributesModal,
                },
              )}
            >
              {showwebAttributesModal ? <AttributesModal closeModal={() => setShowwebAttributesModal(false)} /> : null}
            </div>
          </OutsideClickHandler>
        </div>

        {showAttributesModal ? <AttributesModal closeModal={() => setShowAttributesModal(false)} /> : null}
        {showSortOptionsModal ? <SortByModal closeModal={() => setShowSortOptionsModal(false)} /> : null}

        <div className="relative w-full">
          <div className="flex flex-col w-full">
            <div
              className={classnames('justify-between items-center md:mt-0 w-full', {
                'hidden leading-17 ': !noProducts(),
                'hidden md:flex ': noProducts(),
              })}
            >
              <div className="h-40 cursor-pointer flex items-center ">
                <div
                  onClick={() => {
                    setShowwebAttributesModal(true);
                  }}
                  className="w-90 md:w-88 h-30 md:h-40 border-btnBorder border rounded-3 flex justify-center items-center"
                  button-customisation="page-button"
                >
                  <span
                    className="text-14 md:text-16 font-semibold text-textColor leading-24"
                    font-customisation="para-text"
                    button-customisation="page-button"
                  >
                    Filters
                  </span>
                </div>
              </div>
              <div className="hidden md:flex">
                {!shopAllProductsSlice?.isFetching && shopAllProductsSlice?.list?.count ? (
                  <div
                    className="text-textColor leading-22 text-18 font-normal"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    {shopAllProductsSlice?.list?.count} {shopAllProductsSlice?.list?.count > 1 ? 'results' : 'result'}{' '}
                    for <span className="font-bold">&quot;Shop All&quot;</span>
                  </div>
                ) : null}
              </div>
              <div
                className={classnames('', {
                  'hidden leading-10': !noProducts(),
                  'justify-between items-center md:mt-0 hidden md:flex': noProducts(),
                })}
              >
                <div
                  className="cursor-pointer focus:outline-none "
                  tabIndex={0}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setIsSortDropdownOpen(false);
                  }}
                >
                  <div className="flex items-center">
                    <span
                      className="text-18 leading-19 font-normal text-textColor mr-10"
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      Sort
                    </span>

                    <div
                      className="text-16 text-textColor flex justify-between px-10 w-200 h-40 items-center rounded-4 focus:outline-none focus:h-200 font-normal bg-white border border-slideBorder"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSortDropdownOpen(!isSortDropdownOpen);
                      }}
                    >
                      <span>
                        <span className="" color-customisation="page-body-text">
                          {SELECTED_SORT_OPTION?.name === 'Sort' ? 'Select' : SELECTED_SORT_OPTION?.name}
                        </span>
                      </span>
                      <div className="cursor-pointer mr-4">
                        <Image
                          className={classnames('', {
                            'transform rotate-180': isSortDropdownOpen,
                          })}
                          src={Arrow}
                          noDefaultDimensions
                          alt="Down"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    {isSortDropdownOpen && (
                      <div className="flex absolute flex-col w-200 border border-slideBorder bg-white z-40 rounded-5 right-0">
                        {UNSELECTED_SORT_OPTIONS.map((sort) => (
                          <div
                            className="flex text-dark rounded hover:bg-bgLight text-16 leading-19 font-normal pl-10 min-h-40 items-center focus:bg-opacity-15 focus:bg-textColor"
                            key={sort.key}
                            onClick={() => {
                              sort.onClick();
                              setIsSortDropdownOpen(false);
                            }}
                            color-customisation="page-body-text"
                          >
                            {sort.unSelectedName ?? sort.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="flex md:hidden md:mt-16 mb-22 justify-center">
                {!shopAllProductsSlice?.isFetching && shopAllProductsSlice?.list?.count ? (
                  <div
                    className="text-black leading-18 text-18 font-normal"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    {shopAllProductsSlice?.list?.count} {shopAllProductsSlice?.list?.count > 1 ? 'results' : 'result'}{' '}
                    for <span className="font-bold">&quot;Shop All&quot;</span>
                  </div>
                ) : null}
              </div>
              <div
                className="grid text-theme text-center grid-cols-2 md:hidden py-12 cursor-pointer border-cartBorder border-t border-b w-full"
                font-customisation="para-text"
              >
                <div
                  className="px-8 border-r border-cartBorder font-semibold text-16 flex flex-col items-center justify-center"
                  onClick={() => setShowSortOptionsModal(true)}
                >
                  <div className="flex justify-center items-center">
                    <span>
                      <img src={Sort} alt="Sort" className="m-4" />
                    </span>
                    <span
                      className="ml-2 text-18 leading-19 font-semibold text-textColor"
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                    >
                      Sort
                    </span>
                  </div>
                  <div>
                    <div
                      className="text-16 ml-6 mt-6 leading-16 text-disabled font-normal"
                      color-customisation="page-body-text"
                    >
                      {SELECTED_SORT_OPTION?.name === 'Sort' ? 'Select' : SELECTED_SORT_OPTION?.name}
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <div
                  className="items-center flex flex-col px-8 justify-center md:hidden cursor-pointer"
                  onClick={() => {
                    setShowAttributesModal(true);
                  }}
                >
                  <div className="flex justify-center items-center">
                    <span>
                      <img src={Filter} alt="filter" className="m-4" />
                    </span>
                    <span
                      className="ml-2 text-18 leading-19 font-semibold text-textColor"
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                    >
                      Filter
                    </span>
                  </div>
                  <div
                    className="text-16 ml-6 mt-6 leading-16 text-disabled font-normal"
                    color-customisation="page-body-text"
                  >
                    Apply filter
                  </div>
                </div>
              </div>
            </>
            <div className={classnames('mt-20 mx-20 md:mx-0')}>
              <div
                className={classnames('flex flex-col', {
                  'mb-20 md:mb-30': TOTAL_ATTRIBUTES_APPLIED,
                  'mb-20': !TOTAL_ATTRIBUTES_APPLIED,
                })}
              >
                <div className="flex flex-wrap gap-16 md:gap-10 justify-center">
                  {FACET_TAGS.map((facetTag, index) => (
                    <div
                      key={index}
                      className="flex rounded-4 border border-slideBorder px-10 flex-no-wrap text-dark w-fit font-normal h-30 min-h-30 bg-white justify-between cursor-pointer text-16 leading-22 items-center"
                      onClick={() => {
                        onFacetTagClick(facetTag);
                      }}
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      <div className="justify-self-start pr-10" font-customisation="para-text">
                        {facetTag.facetValue}
                      </div>
                      <div className="justify-self-end mx-6 ">
                        <img src={Close} alt="close" className="" />
                      </div>
                    </div>
                  ))}

                  {CATEGORY_TAGS.map((facetTag, index) => (
                    <div
                      key={index}
                      className="flex rounded-4 border border-slideBorder px-10 flex-no-wrap text-dark w-fit font-normal h-30 min-h-30 bg-white justify-between cursor-pointer text-16 leading-22 items-center"
                      onClick={() => {
                        onCategoryTagClick(facetTag);
                      }}
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      <div className="justify-self-start pr-10">{facetTag.facetValue}</div>
                      <div className="justify-self-end mx-6 ">
                        <img src={Close} alt="close" className="" />
                      </div>
                    </div>
                  ))}

                  {PRICE_TAG ? (
                    <div
                      className="flex rounded-4 border border-slideBorder px-10 flex-no-wrap text-dark w-fit font-normal h-30 min-h-30 bg-white justify-between cursor-pointer text-16 leading-22 items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        onPriceTagClick();
                      }}
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      <div className="justify-self-start pr-10 flex">
                        <Rupee value={PRICE_TAG.minPrice} defaultClass={false} /> &nbsp;<span>-</span> &nbsp;
                        <Rupee value={PRICE_TAG.maxPrice} defaultClass={false} />
                      </div>
                      <div className="justify-self-end mx-6 ">
                        <img src={Close} alt="close" className="" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {shopAllProductsSlice?.error ? (
            <div className="text-center text-bold border rounded m-100 p-25">{shopAllProductsSlice?.error}</div>
          ) : null}
          <div className="container px-0">
            {shopAllProductsSlice?.isFetching && !shopAllProductsSlice?.fetchedProducts.length ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                <ProductCardLoading />
                <ProductCardLoading />
                <ProductCardLoading />
              </div>
            ) : (
              <div className="px-22 md:px-0 mb-50">
                {shopAllProductsSlice?.fetchedProducts.length ? (
                  <InfiniteScroll
                    dataLength={shopAllProductsSlice?.fetchedProducts?.length || 0} //This is important field to render the next data
                    next={() => {
                      dispatch(
                        fetchProductsRequest({
                          storeKey: PRODUCTS_KEY.SHOP,
                          queryParams: {
                            ...shopAllProductsSlice?.currentQueryParams,
                            page: (shopAllProductsSlice?.currentQueryParams?.page ?? 0) + 1,
                          },
                        }),
                      );
                    }}
                    hasMore={shopAllProductsSlice?.fetchedProducts.length < shopAllProductsSlice?.list?.count}
                    loader={<h4></h4>}
                    scrollThreshold={0.8}
                  >
                    <div className={classnames('flex flex-wrap h-full justify-between md:justify-start md:gap-32')}>
                      {shopAllProductsSlice?.fetchedProducts?.map((product, index: number) => {
                        const isProductIdAvailable = productIds[product.id];
                        productIds[product.id] = true;
                        return !isProductIdAvailable ? (
                          <div className={classnames('')} key={index}>
                            <ProductCard
                              addToast={(...args) => dispatch(addToast(...args))}
                              product={product}
                              addProductToCart={(...args) => dispatch(addProductToCart(...args))}
                              subtractProductFromCart={(...args) => dispatch(subtractProductFromCart(...args))}
                              cartProductQuantity={cart.quantityByProductId[product.id] || 0}
                              storeInfo={storeInfo}
                              shopAll={'shopAll'}
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  </InfiniteScroll>
                ) : shopAllProductsSlice?.isFetched && !shopAllProductsSlice?.fetchedProducts.length ? (
                  <div className="flex flex-col justify-center items-center h-60v px-20 md:px-0 w-full">
                    <div className="flex">
                      <img className="h-254 w-254 min-w-254" src={CartImg} alt="Empty cart" />
                    </div>
                    <div className="mt-20 flex flex-col justify-center items-center">
                      <div
                        className="text-textColor text-21 md:text-28 leading-25 md:leading-28 font-extrabold"
                        font-customisation="section-heading"
                        color-customisation="page-heading"
                      >
                        Coming Soon!
                      </div>
                      <div
                        className="mt-20 md:mt-12 text-disabled text-17 md:text-16 font-normal leading-24 md:leading-22 font-regular text-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        We are yet to add products to this category.
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

// eslint-disable-next-line
export default () => {
  return (
    <ShopAllProvider sortDefaultName="Sort" sortDefaultUnselectedName="Clear All">
      <ShopAll />
    </ShopAllProvider>
  );
};
