import CustomFieldHOC from '@common/components/CustomFieldHOC';
import { ProductAddToCartHOCRenderArgs } from '@common/components/ProductAddToCartHOC';
import ProductOptionsHOC from '@common/components/ProductOptionsHOC';
import Rupee from '@common/components/Rupee';
import Product, { PRODUCT_TYPE } from '@common/models/Product';
import { STORE_TYPE } from '@common/models/StoreInfo';
import { addProductToCart } from '@common/store/cart/slice';
import { subtractProductFromCart } from '@common/store/cart/slice';
import { addToast } from '@common/store/toasts/slice';
import { isMobile } from '@common/utils';
import SelectVariants from '@components/SelectVariants';
import Stepper from '@components/Stepper';
import FormCheckboxRadioGroup from '@lib/FormCheckboxRadioGroup';
import FormInput from '@lib/FormInput';
import WishlistIcon from '@lib/WishlistIcon';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import Savings from './Savings';

interface ProductAddToCart {
  product: Product;
  cartProductQuantity: number;
  addToast: typeof addToast;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  storeType: string;
  isPaymentsDisabled: boolean;
  isStoreInfoFetching: boolean;
  hocArgs: ProductAddToCartHOCRenderArgs;
  wishListFeature: boolean;
}

const ProductAddToCart: React.FC<ProductAddToCart> = ({
  product,
  addProductToCart,
  cartProductQuantity,
  subtractProductFromCart,
  storeType,
  hocArgs,
  wishListFeature,
}) => {
  const {
    finalProduct,
    showTicketsLeft,
    eventStartDate,
    eventStartTime,
    eventEndDate,
    eventEndTime,
    editedPrice,
    setEditedPrice,
    editedPriceError,
    addToCart,
    variant,
    minQuantityGreaterThanOne,
    isBuyNowButtonDisabled,
    // isAddToCartButtonDisabled,
    // showAddToCartButton,
    showStepper,
    finalPrice,
    discountCode,
    savings,
    onWishListClickHandler,
    wishlist,
  } = hocArgs;

  const { selectedOptions, setSelectedOptions, quantity, setQuantity } = variant;
  const { isProductInWishList } = wishlist;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, seterror] = useState(false);
  const cartCtaRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLDivElement>(null);
  return (
    <div>
      <div>
        <div
          className="text-prodTitleTxt text-22 mb-4 font-bold"
          font-customisation="para-text"
          color-customisation="page-body-text"
          id="final-price"
        >
          <Rupee value={finalPrice} />
        </div>

        {savings ? (
          <div className="flex mb-24 items-center" font-customisation="para-text">
            <div className="text-savings hidden text-14 mr-8">
              Saving <Rupee value={savings.amount} />
            </div>
            {savings ? (
              <div
                className="text-strikeTxt line-through font-normal text-14 mr-12"
                id="original-price"
                font-customisation="para-text"
              >
                <Rupee value={savings.price} />
              </div>
            ) : null}
            <Savings percentage={savings.percentage} />
          </div>
        ) : null}
      </div>
      {product.type === PRODUCT_TYPE.PHYSICAL ? (
        <div
          className="my-10 flex items-center text-16 text-cart-heading"
          font-customisation="para-text"
          color-customisation="page-body-text"
        >
          <div className="">Ships within {finalProduct.shipInDays} days</div>
        </div>
      ) : null}
      <div className="flex gap-10 text-share-text">
        <div>SKU : </div>
        {product.stock}
      </div>
      {showTicketsLeft ? (
        <div
          className="text-12 font-medium text-input-error mt-8 mb-16"
          font-customisation="para-text"
          color-customisation="page-body-text"
        >
          Only {product.stock} tickets left
        </div>
      ) : null}
      {finalProduct.type === PRODUCT_TYPE.EVENT ? (
        <div className="my-8 text-14" font-customisation="para-text" color-customisation="page-body-text">
          <div className="flex mb-8">
            <div className="w-1/3">Start Date:</div>
            <div className="w-2/3 break-words">
              {eventStartDate} {eventStartTime}
            </div>
          </div>
          <div className="flex mb-8">
            <div className="w-1/3">End Date:</div>
            <div className="w-2/3 break-words">
              {eventEndDate} {eventEndTime}
            </div>
          </div>
          <div className="flex mb-8">
            <div className="w-1/3">Venue:</div>
            <div className="w-2/3 break-words">{finalProduct.event?.venue}</div>
          </div>
        </div>
      ) : null}

      {product.hasVariants ? (
        <div className="mb-20">
          <SelectVariants
            product={product}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </div>
      ) : null}

      {finalProduct.payWhatYouWant ? (
        <FormInput
          name={'price'}
          className="my-20"
          label={`Pay what you want (Min ₹${finalPrice})`}
          type="text"
          value={editedPrice}
          onChange={(e) => setEditedPrice(e.target.value)}
          onBlur={() => console.log('NoOp')}
          error={editedPriceError}
        />
      ) : null}
      <ProductOptionsHOC
        product={product}
        addToCartHocArgs={hocArgs}
        renderFormField={({ form, formInputName, charges, productOption, multiple, typeOfInput }) => {
          const {
            values,
            errors,
            touched,
            submissions,
            // submitting,
            handleChange,
            handleBlur,
            asyncErrors,
            // setDynamicErrors,
          } = form;
          return (
            <FormInput
              name={formInputName}
              className="my-20"
              label={`${productOption.name} ${charges}`}
              type={typeOfInput}
              multiple={multiple}
              value={values[formInputName] || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={(submissions || touched[formInputName]) && (errors[formInputName] || asyncErrors[formInputName])}
            />
          );
        }}
        renderFormCheckboxRadioField={({ form, formInputName, optionType, optionValues, charges, productOption }) => {
          const {
            values,
            errors,
            touched,
            submissions,
            // submitting,
            handleChange,
            handleBlur,
            asyncErrors,
            // setDynamicErrors,
          } = form;
          return (
            <fieldset>
              <div className="mb-12" font-customisation="section-heading" color-customisation="page-heading">
                {productOption.name} {charges}
              </div>
              <FormCheckboxRadioGroup
                className="mb-12"
                name={formInputName}
                type={optionType as 'radio' | 'checkbox'}
                value={values[formInputName] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                options={optionValues.map((optionValue) => ({ id: optionValue.id, value: optionValue.text }))}
                error={(submissions || touched[formInputName]) && (errors[formInputName] || asyncErrors[formInputName])}
              />
            </fieldset>
          );
        }}
      />
      <CustomFieldHOC
        product={product}
        addToCartHocArgs={hocArgs}
        renderFormField={({ customFieldForm, formInputName, charges, productOption, multiple, typeOfInput }) => {
          const { values, errors, touched, submissions, handleChange, handleBlur, asyncErrors } = customFieldForm;
          if (values[formInputName]) seterror(false);
          else if (!values[formInputName]) seterror(true);
          return (
            <FormInput
              name={formInputName}
              className="my-32"
              label={`${productOption?.name} ${charges}`}
              type={typeOfInput === 'multifile' ? 'file' : typeOfInput}
              multiple={multiple}
              value={values[formInputName] || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={200}
              error={(submissions || touched[formInputName]) && (errors[formInputName] || asyncErrors[formInputName])}
            />
          );
        }}
        renderFormCheckboxRadioField={({
          customFieldForm,
          formInputName,
          optionType,
          optionValues,
          charges,
          productOption,
        }) => {
          const { values, errors, touched, submissions, handleChange, handleBlur, asyncErrors } = customFieldForm;
          if (values[formInputName]) seterror(false);
          else if (!values[formInputName]) seterror(true);
          return (
            <fieldset>
              <div className="mb-12" font-customisation="section-heading" color-customisation="page-heading">
                {productOption.name} {charges}
              </div>
              <FormCheckboxRadioGroup
                className="mb-12"
                name={formInputName}
                type={optionType as 'radio' | 'checkbox'}
                value={values[formInputName] || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                options={optionValues.map((optionValue) => ({ id: optionValue.id, value: optionValue.text }))}
                error={(submissions || touched[formInputName]) && (errors[formInputName] || asyncErrors[formInputName])}
              />
            </fieldset>
          );
        }}
      />
      {minQuantityGreaterThanOne ? (
        <div
          className={classnames('text-12 font-medium text-cart-heading mt-24')}
          font-customisation="para-text"
          color-customisation="page-body-text"
        >
          Min Order Qty: {finalProduct.minQuantity}
        </div>
      ) : null}
      <div ref={ctaRef} className="static left-0 bottom-0 w-full py-10">
        {finalProduct.type && finalProduct.type === PRODUCT_TYPE.DIGITAL ? (
          <button
            className="rounded-md mt-20 uppercase bg-white w-full h-42 flex justify-center items-center border border-addToCart-border text-addToCart-border text-16"
            disabled={isBuyNowButtonDisabled}
            onClick={() => addToCart({ isBuyNowFlow: true, discountLinkPromoCode: discountCode })}
            button-customisation="buyNow-button"
            font-customisation="para-text"
          >
            {storeType === STORE_TYPE.ENQUIRY ? 'Enquire Now' : !product.isExpired ? 'Buy Now' : 'Expired'}
          </button>
        ) : (
          <button
            ref={cartCtaRef}
            className={classnames(
              'uppercase rounded-md w-full h-42 flex bg-white justify-center items-center border border-nav text-nav  text-16',
              {
                'mt-24': !minQuantityGreaterThanOne,
                'mt-8': minQuantityGreaterThanOne,
              },
            )}
            onClick={() => {
              // addToCart({ isBuyNowFlow: false });
              addProductToCart({
                product,
                disableSuccessToasts: false,
              });
            }}
            button-customisation="addToCart-button"
            font-customisation="para-text"
          >
            Add to Cart
          </button>
        )}
        {showStepper ? (
          <Stepper
            className={classnames({ 'mt-8': minQuantityGreaterThanOne }, 'hidden')}
            isBiggerVariant
            product={product}
            subtractProductFromCart={subtractProductFromCart}
            addProductToCart={addProductToCart}
            quantity={cartProductQuantity}
            cartProductQuantity={cartProductQuantity}
            mixpanelProperties={{ source: 'theme-detail' }}
          />
        ) : null}
      </div>

      <div className="hidden">
        {!isMobile() && (
          <WishlistIcon
            className="h-40 w-40 focus:outline-none bg-disabled text-14 md:text-18 font-bold tracking-widest rounded pointer-events-auto max-w-300 flex justify-center items-center disabled:opacity-20 ml-14 mt-24"
            type={isProductInWishList ? 'addWishList' : 'removeWishList'}
            wishListFeature={wishListFeature}
            onClick={() => {
              onWishListClickHandler();
            }}
            font-customisation="para-text"
          />
        )}
      </div>
    </div>
  );
};

export default ProductAddToCart;
