import BundleDeleteModal from '@common/components/BundleDeleteModal';
import CartCheckoutHOC from '@common/components/CartCheckoutHOC';
import ProductBundleCart from '@common/components/ProductBundleCart';
import { useBundleDelete } from '@common/hooks/useBundleDelete';
import StoreInfo from '@common/models/StoreInfo';
import { RootState, store } from '@common/store';
import {
  addBundleProductToCartSuccess,
  addProductToCart,
  addPromoCodeToCart,
  addPromoCodeToCartSuccess,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import { CategoriesSliceType, fetchCategoriesRequest } from '@common/store/categories/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { PromotionsSliceType } from '@common/store/promotions/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
// import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { trackEvent } from '@common/utils/analytics';
import { getMerchantJWTToken } from '@common/utils/token';
// import PageLayout from '@components/PageLayout';
// import Arrow from '@images/RightArrow.svg';
// import RecentlyViewed from '@components/RecentlyViewed';
import Rupee from '@components/Rupee';
import CartImg from '@images/CartImg.svg';
// import Toasts from '@components/Toasts';
import Close from '@images/Close.svg';
// import CloseRed from '@images/CloseRed.svg';
// import CloseWhite from '@images/CloseWhite.svg';
// import CartImg from '@images/emptyCart.svg';
// import { ReactComponent as BreadcrumbRightArrow } from '@images/sArrow.svg';
import Rupees from '@instamojo/rupee';
// import Button from '@lib/Button';
import Link from '@lib/Link';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchProductBundleRequest, ProductDetailsSliceType } from '../../../../common/store/productDetails/slice';
import CartItem from './CartItem';

export interface CartProps {
  className?: string;
  cart: CartSliceType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  addPromoCodeToCart: typeof addPromoCodeToCart;
  removeToast: typeof removeToast;
  toasts: ToastWithKeyType[];
  storeType: string;
  isPaymentsDisabled: boolean;
  isStoreInfoFetching: boolean;
  promotions: PromotionsSliceType;
  addPromoCodeToCartSuccess: typeof addPromoCodeToCartSuccess;
  recentlyViewed: RecentlyViewedSliceType;
  categories: CategoriesSliceType;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  productDetails: ProductDetailsSliceType;
  fetchProductBundleRequest: typeof fetchProductBundleRequest;
  addBundleProductToCartSuccess: typeof addBundleProductToCartSuccess;
  productSlug: string;
  hideShowCartModal?: () => void;
  storeInfo: StoreInfo;
}

const Cart: React.FunctionComponent<CartProps> = ({
  cart,
  addProductToCart,
  subtractProductFromCart,
  removeProductFromCart,
  addPromoCodeToCart,
  promotions,
  removeToast,
  toasts,
  addPromoCodeToCartSuccess,
  // recentlyViewed,
  productDetails,
  fetchProductBundleRequest,
  addBundleProductToCartSuccess,
  productSlug,
  hideShowCartModal,
  storeInfo,
}) => {
  return (
    <CartCheckoutHOC
      render={({
        products,
        promoCode,
        totalProducts,
        totalAmount,
        isCheckoutDisabled,
        checkoutHandler,
        cartHasOptionProduct,
        hasSomeDiscount,
        totalAmountAfterDiscount,
        discountBreakup,
        cartHasVariant,
        hidePromoCodeSection,
        addUserWishList,
        removeUserWishList,
        wishlist,
      }) => {
        const checkoutBlock = (showCartIds) => {
          return (
            <div>
              <div className="px-14">
                {hasSomeDiscount ? (
                  <>
                    <div className="flex items-center mb-15 justify-between">
                      <div
                        className=" text-right text-textColor leading-20 text-16 font-bold mr-10"
                        color-customisation="page-body-text"
                      >
                        Sub Total :
                      </div>
                      <div
                        className=" text-16 font-normal text-textColor text-left"
                        color-customisation="page-body-text"
                      >
                        <Rupee value={totalAmount} defaultClass={false} />
                      </div>
                    </div>
                    {discountBreakup?.promo ? (
                      <div className="flex items-center mb-15 justify-between">
                        <div
                          className=" text-right text-textColor leading-20 text-16 font-bold mr-10"
                          color-customisation="page-body-text"
                        >
                          Coupon Discount :
                        </div>
                        <div
                          className=" text-16 font-normal text-textColor text-left"
                          color-customisation="page-body-text"
                        >
                          - <Rupee value={discountBreakup?.promo} defaultClass={false} />
                        </div>
                      </div>
                    ) : null}
                    {discountBreakup?.wholesale ? (
                      <div className="flex items-center mb-15 justify-between">
                        <div
                          className=" text-right text-textColor leading-20 text-16 font-bold mr-10"
                          color-customisation="page-body-text"
                        >
                          Wholesale Discount :
                        </div>
                        <div
                          className=" text-16 font-normal text-textColor text-left"
                          color-customisation="page-body-text"
                        >
                          - <Rupee value={discountBreakup?.wholesale} defaultClass={false} />
                        </div>
                      </div>
                    ) : null}
                    <div className="w-full border-cartBorder mb-14 border-t"></div>
                    <div className="flex items-center mb-14 justify-between">
                      <div
                        className=" text-16 font-semibold text-textColor text-left"
                        color-customisation="page-body-text"
                      >
                        Total :
                      </div>
                      <div className=" text-right text-total leading-20 text-16 font-bold ">
                        <Rupee value={totalAmountAfterDiscount} defaultClass={false} />
                      </div>
                    </div>
                    <div className="w-full border-cartBorder mb-14 border-t h-4 border-b"></div>
                  </>
                ) : (
                  <div className="flex item-center mb-15 justify-between">
                    <p
                      className=" text-right mr-10 text-storeName text-16 font-bold"
                      color-customisation="page-body-text"
                    >
                      Total :
                    </p>
                    <p className="text-16 font-normal text-storeName text-left" color-customisation="page-body-text">
                      <Rupee value={totalAmount} defaultClass={false} />
                    </p>
                  </div>
                )}
              </div>
              <div className="px-14">
                <button
                  className="ripple font-normal no-defaults h-40 rounded-3 border border-nav text-nav bg-white p-10 text-16 leading-16 w-full justify-center items-center disabled:bg-disabled disabled:cursor-not-allowed"
                  disabled={isCheckoutDisabled}
                  onClick={checkoutHandler}
                  button-customisation="buyNow-button"
                  id={showCartIds ? 'cart-checkout' : ''}
                >
                  Checkout
                  {/* {storeType === STORE_TYPE.ENQUIRY ? 'Proceed to Enquiry' : 'Proceed to Checkout'} */}
                </button>
              </div>
            </div>
          );
        };
        // const [CouponPopUp, setCouponPopUp] = useState(false);
        const [couponCode, setcouponCode] = useState(null);
        const [AppliedCoupon, setAppliedCoupon] = useState(false);
        const [showAllCoupons, setShowAllCoupons] = useState(false);
        const [isBundleHide, setIsBundleHide] = useState(false);
        const {
          showRemoveItemAlert,
          bundleProduct,
          handleCloseRemoveAlert,
          setShowRemoveItemAlert,
          removeProductFromBundle,
        } = useBundleDelete();
        const [removeReferralCoupon, setRemoveReferralCoupon] = useState(false);
        useEffect(() => {
          const state: RootState = store.getState();
          trackEvent('Viewed Cart', {
            order_id: '',
            cart_amount: promoCode?.discountedTotalOrder || totalAmount,
            num_products: products?.length,
            num_items: totalProducts,
            parent_ref_store_flag: 1,
            ga_client_id: '',
            self_flag: !!getMerchantJWTToken(),
            seller_username: '',
            seller_account_id: state.storeInfo.storeInfo?.accountId,
            is_mojocommerce_cart: true,
          });
          setcouponCode(promoCode ? promoCode?.code : null);
          setAppliedCoupon(promoCode?.code ? true : false);
          if (Object.keys(products).length > 0 && !removeReferralCoupon) {
            const promotion = promotions?.promotions?.results.find(
              (promotion) => promotion.isAutoDiscount && promotion.oncePerUser,
            );
            if (promotion) {
              addPromoCodeToCart(promotion.promoCode);
              setcouponCode(promotion.promoCode);
              setAppliedCoupon(true);
            }
          }
        }, [promoCode?.code, promotions?.promotions?.results]);

        useEffect(() => {
          const payload = {
            productSlugs: productSlug,
            location: 'cart',
          };
          if (productSlug.length) {
            fetchProductBundleRequest(payload);
          }
        }, [productSlug]);

        useEffect(() => {
          const getSession = window.sessionStorage.getItem('isBundleHide');
          setIsBundleHide(getSession == 'true');
        }, []);

        if (products.length === 0) toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
        return (
          <div className="relative">
            {showAllCoupons && (
              <div
                className="w-full z-9999 fixed overflow-y-scroll px-20 py-50 md:px-0 md:py-0 left-0 top-0 h-full bg-black bg-opacity-40 flex md:justify-end justify-center"
                onClick={() => setShowAllCoupons(false)}
              >
                <div className="md:w-350 w-320 rounded-3xl md:rounded-none bg-white h-fit md:h-full md:right-0">
                  <div className="relative w-full flex justify-end h-0">
                    <div className="md:hidden -mt-30 -mr-20 rounded-full cursor-pointer w-50 h-50 flex justify-center items-center bg-lightGrey">
                      <img src={Close} alt="Close" className="h-14 w-14 " />
                    </div>
                  </div>
                  <div className="m-16 hidden md:block absolute right-0">
                    <img src={Close} alt="" className="cursor-pointer" />
                  </div>
                  <div
                    className="text-textColor text-center my-20 md:my-40 text-20 font-semibold"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    Available Coupons ({promotions?.promotions?.results.length})
                  </div>

                  {promotions?.promotions?.results.map((item) => {
                    return (
                      <div key={item.id} className="px-30 mb-30 md:mb-0 md:px-36">
                        {item.isActive && item.isValid && (
                          <div className="flex justify-center pb-20 mb-20 border-b border-black border-opacity-20">
                            <div
                              className="min-w-248 md:min-w-full w-248 md:w-full cursor-pointer flex justify-between items-start"
                              onClick={() => {
                                toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
                                addPromoCodeToCart(item.promoCode);
                                setcouponCode(item.promoCode);
                                setAppliedCoupon(true);
                              }}
                            >
                              <div className="text-left flex mr-10">
                                {parseInt(item.discountPercentage) ? (
                                  <span
                                    className="text-12 font-semibold text-left leading-20 text-disabled"
                                    font-customisation="para-text"
                                    color-customisation="page-body-text"
                                  >
                                    {`${item.discountPercentage}% off up to `}
                                    <Rupees value={item.maxDiscountAmount} />
                                    {` on a minimum purchase of `}
                                    <Rupees value={item.minEligibleAmount} />
                                  </span>
                                ) : (
                                  <span
                                    className="text-12 font-semibold text-left leading-20 text-disabled"
                                    font-customisation="para-text"
                                    color-customisation="page-body-text"
                                  >
                                    {`Flat discount of up to `}
                                    <Rupees value={item.flatDiscount} />
                                    {` on a minimum purchase of `}
                                    <Rupees value={item.minEligibleAmount} />
                                  </span>
                                )}
                              </div>
                              <div className="flex min-h-48 min-w-104 w-104 rounded-lg h-48 bg-white  justify-center items-center border-2 border-dashed border-theme">
                                <span className="text-18 text-textColor font-normal uppercase">{item.promoCode}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!totalProducts ? (
              <div className=" md:mt-0 ">
                <div className="flex container min-w-full  md:min-w-1080 px-0 mx-auto">
                  <div className="w-full flex flex-wrap justify-between">
                    <div
                      className="w-full relative border-b border-variant-bg flex p-16 justify-between"
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                      id="cart-heading"
                    >
                      <span className="text-storeName font-medium text-18">Shopping Cart ({totalProducts})</span>
                      <img src={Close} alt="close" className="cursor-pointer" onClick={hideShowCartModal} />
                    </div>
                    <div className="w-full border border-variant-bg m-14 p-14 flex justify-center items-center flex-col">
                      <p
                        className=" text-storeName text-16 leading-22 mb-10 md:text-18 font-normal md:leading-24"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        There are no items in your cart.
                      </p>
                      <Link
                        to="/"
                        className="flex border gap-6 items-center border-nav bg-white justify-center w-186 h-46 rounded-4"
                      >
                        <img className="h-26 min-h-26 w-18" src={CartImg} alt="Cart" id="header-cart" />
                        <span className="p-2 text-nav text-center text-16 font-normal">Continue shopping</span>
                      </Link>
                    </div>
                    <div className="w-full border-t border-variant-bg p-14 flex justify-center items-center flex-col"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" md:mt-0 ">
                <div className="flex container px-0 mx-auto">
                  <div className="w-full flex flex-wrap justify-between">
                    <div className="w-full">
                      <div
                        className="w-full relative border-b border-variant-bg flex p-16 justify-between"
                        font-customisation="section-heading"
                        color-customisation="page-heading"
                        id="cart-heading"
                      >
                        <span className="text-storeName font-medium text-18">Shopping Cart ({totalProducts})</span>
                        <img src={Close} alt="close" className="cursor-pointer" onClick={hideShowCartModal} />
                      </div>
                      <div className="p-16 pb-0 border-b hidden md:flex border-cartTable-border">
                        <table className="w-full border border-cartTable-border mb-16">
                          <thead className="border-b border-cartTable-border">
                            <tr className="bg-cartTable-header h-44">
                              <th className="pl-16 text-16 text-cartTable-font font-normal">Product Details</th>
                              <th className="pl-16 text-16 text-cartTable-font font-normal">Quantity</th>
                              <th className="pl-16 text-16 text-cartTable-font font-normal">Price / Item</th>
                              <th className="pl-16 text-16 text-cartTable-font font-normal">Total Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products?.map((product, index) => (
                              <CartItem
                                taxIncludedInventory={storeInfo?.storeInfo?.tax_included_inventory}
                                index={index}
                                key={product.hash}
                                product={product}
                                addProductToCart={addProductToCart}
                                subtractProductFromCart={subtractProductFromCart}
                                removeProductFromCart={removeProductFromCart}
                                cartHasOptionProduct={cartHasOptionProduct}
                                cartHasVariant={cartHasVariant}
                                removeProductFromBundle={removeProductFromBundle}
                                cartProductQuantity={cart?.quantityByProductId[product.id] || 0}
                                alterCoupon={() => {
                                  toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
                                  if (couponCode) addPromoCodeToCart(couponCode);
                                  setTimeout(() => {
                                    const data = JSON.parse(localStorage.getItem('NewCart'));
                                    if (data.promoCode === null) {
                                      setcouponCode('');
                                      setAppliedCoupon(false);
                                    }
                                  }, 250);
                                }}
                                addUserWishList={(product) => addUserWishList(product)}
                                removeUserWishList={(product) => removeUserWishList(product)}
                                wishlist={wishlist}
                              />
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col md:hidden">
                        {products?.map((product, index) => (
                          <CartItem
                            taxIncludedInventory={storeInfo?.storeInfo?.tax_included_inventory}
                            index={index}
                            key={product.hash}
                            product={product}
                            addProductToCart={addProductToCart}
                            subtractProductFromCart={subtractProductFromCart}
                            removeProductFromCart={removeProductFromCart}
                            cartHasOptionProduct={cartHasOptionProduct}
                            cartHasVariant={cartHasVariant}
                            removeProductFromBundle={removeProductFromBundle}
                            cartProductQuantity={cart?.quantityByProductId[product.id] || 0}
                            alterCoupon={() => {
                              toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
                              if (couponCode) addPromoCodeToCart(couponCode);
                              setTimeout(() => {
                                const data = JSON.parse(localStorage.getItem('NewCart'));
                                if (data.promoCode === null) {
                                  setcouponCode('');
                                  setAppliedCoupon(false);
                                }
                              }, 250);
                            }}
                            addUserWishList={(product) => addUserWishList(product)}
                            removeUserWishList={(product) => removeUserWishList(product)}
                            wishlist={wishlist}
                          />
                        ))}
                      </div>
                    </div>
                    <div
                      className="flex flex-col md:flex-row w-full p-16 justify-between"
                      color-customisation="page-bg"
                    >
                      <div className="">
                        {!hidePromoCodeSection ? (
                          <div className="">
                            <div className="hidden">
                              {promotions?.promotions?.results.slice(0, 1).map((item) => {
                                return (
                                  <div key={item.id} className="">
                                    {item.isActive && item.isValid && (
                                      <div className="flex justify-center mb-14">
                                        <div
                                          className="min-w-335 md:min-w-full w-335 md:w-full cursor-pointer flex justify-between items-start"
                                          onClick={() => {
                                            toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
                                            addPromoCodeToCart(item.promoCode);
                                            setcouponCode(item.promoCode);
                                            setAppliedCoupon(true);
                                          }}
                                        >
                                          <div className="text-left flex">
                                            {parseInt(item.discountPercentage) ? (
                                              <span
                                                className="text-12 font-semibold text-left leading-20 text-disabled"
                                                font-customisation="para-text"
                                                color-customisation="page-body-text"
                                              >
                                                {`${item.discountPercentage}% off up to `}
                                                <Rupees value={item.maxDiscountAmount} />
                                                {` on a minimum purchase of `}
                                                <Rupees value={item.minEligibleAmount} />
                                              </span>
                                            ) : (
                                              <span
                                                className="text-12 font-semibold text-left leading-20 text-disabled"
                                                font-customisation="para-text"
                                                color-customisation="page-body-text"
                                              >
                                                {`Flat discount of up to `}
                                                <Rupees value={item.flatDiscount} />
                                                {` on a minimum purchase of `}
                                                <Rupees value={item.minEligibleAmount} />
                                              </span>
                                            )}
                                          </div>
                                          <div className="flex min-h-48 min-w-104 w-104 rounded-lg h-48 bg-white  justify-center items-center border-2 border-dashed border-theme">
                                            <span className="text-18 text-textColor font-normal uppercase">
                                              {item.promoCode}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                              {promotions?.promotions?.results.length > 1 ? (
                                <div
                                  className="w-full text-left underline text-theme text-14 cursor-pointer font-semibold"
                                  onClick={() => setShowAllCoupons(true)}
                                  font-customisation="para-text"
                                >
                                  View All Coupons
                                </div>
                              ) : null}

                              <div className="text-14 font-normal hidden text-center text-disabled mb-10">
                                Already Have a coupon? Redeem Here.
                              </div>
                            </div>

                            <div className="flex w-full md:w-fit border mb-10 border-cartTable-border">
                              <input
                                className={classnames(
                                  'h-34 pl-20 pr-10 w-full md:w-fit min-w-200 border-r border-cartTable-border placeholder-disabled font-normal text-14 focus:outline-none',
                                  {
                                    'text-textColor': !promoCode && !AppliedCoupon,
                                  },
                                )}
                                type="text"
                                value={couponCode}
                                onChange={(e) => setcouponCode(e.target.value)}
                                placeholder="Apply Coupon Code"
                                readOnly={(promoCode && AppliedCoupon) || (!promoCode && AppliedCoupon)}
                                font-customisation="para-text"
                                id="coupon-code-input"
                              />

                              {(promoCode && AppliedCoupon) || (!promoCode && AppliedCoupon) ? (
                                <button
                                  className=" text-14 no-defaults h-34 px-20 min-w-50 md:w-fit flex justify-center items-center text-nav "
                                  disabled={couponCode?.length == 0}
                                  onClick={() => {
                                    addPromoCodeToCartSuccess(null);
                                    setcouponCode('');
                                    setAppliedCoupon(false);
                                    setRemoveReferralCoupon(true);
                                  }}
                                  font-customisation="para-text"
                                  button-customisation="page-button"
                                  id="remove-coupon"
                                >
                                  Remove
                                </button>
                              ) : (
                                <button
                                  className="text-14 no-defaults h-34 px-20 min-w-50 md:w-fit flex justify-center items-center text-nav "
                                  disabled={couponCode?.length == 0}
                                  onClick={() => {
                                    toasts?.length ? toasts?.map((t) => removeToast(t.key)) : null;
                                    addPromoCodeToCart(couponCode);
                                    setTimeout(() => {
                                      setAppliedCoupon(true);
                                    }, 500);
                                  }}
                                  font-customisation="para-text"
                                  button-customisation="page-button"
                                  id="apply-coupon"
                                >
                                  Apply
                                </button>
                              )}
                            </div>
                            {cart.oneTimePromoError && couponCode && AppliedCoupon ? (
                              <div className="px-2 text-14 font-semibold leading-22 text-grayText -mt-8 mb-8 text-center">
                                <span className="text-grayText">Apply this coupon during checkout</span>
                              </div>
                            ) : null}
                            {(promoCode && AppliedCoupon) || (promoCode && !AppliedCoupon) ? (
                              <div className="flex justify-center w-full" id="coupon-applied">
                                <span
                                  className="w-full text-center -mt-4 mb-8 text-18 font-normal leading-22 text-successText"
                                  font-customisation="para-text"
                                >
                                  Coupon Applied Successfully!
                                </span>
                              </div>
                            ) : toasts?.length ? (
                              <div className="hidden w-full justify-center ">
                                <span
                                  className="flex text-16 text-center -mt-4 mb-8  font-normal leading-22 text-errorText"
                                  font-customisation="para-text"
                                >
                                  {toasts[toasts.length - 1].variant === 'bg-toast-error'
                                    ? toasts[toasts.length - 1].content
                                    : null}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div className="block py-14" font-customisation="para-text">
                        {checkoutBlock(true)}
                      </div>
                    </div>
                    <div className="w-full md:w-904">
                      {productDetails.bundle && !isBundleHide && (
                        <div className="w-full">
                          <ProductBundleCart
                            productsBundle={productDetails.bundle}
                            addToBundleProductInCart={addBundleProductToCartSuccess}
                            setIsBundleHide={setIsBundleHide}
                            priceClassName={'text-textColor'}
                            borderClassName={'border-productBundle-lightGray'}
                            discountPercentageColor={'bg-errorToast'}
                          />
                        </div>
                      )}
                      <BundleDeleteModal
                        showRemoveItemAlert={showRemoveItemAlert}
                        bundleProduct={bundleProduct}
                        handleCloseRemoveAlert={handleCloseRemoveAlert}
                        setShowRemoveItemAlert={setShowRemoveItemAlert}
                        removeProductFromCart={removeProductFromCart}
                        textClassName={'text-textColor'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* </PageLayout> */}
          </div>
        );
      }}
    />
  );
};

export default connect(
  ({ productDetails, storeInfo }: RootState) => ({
    productDetails,
    storeInfo,
  }),
  {
    addPromoCodeToCart,
    addPromoCodeToCartSuccess,
    fetchProductBundleRequest,
    addBundleProductToCartSuccess,
  },
)(Cart);
