import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export interface OutisdeClickProps {
  children: React.ReactNode;
  onOutsideClick: () => void;
}
const OutsideClick: React.FunctionComponent<OutisdeClickProps> = ({ onOutsideClick, children }) => {
  return <OutsideClickHandler onOutsideClick={onOutsideClick}>{children}</OutsideClickHandler>;
};

export default OutsideClick;
