import { ReactComponent as ModalCloseIcon } from '@common/icons/ModalClose.svg';
import { ReactComponent as VariantCircleRemove } from '@common/icons/VariantCircleRemove.svg';
import { RootState } from '@common/store';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import Toasts from '@components/Toasts';
import Image from '@lib/Image';
import Close from '@Spring/images/Close.svg';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

export interface ModalProps {
  heading: string;
  showCloseIcon?: boolean;
  children: React.ReactNode;
  backdropColor?: string;
  closeModal: () => void;
  onClose: () => void;
  maxWidth?: string;
  noPadding?: boolean;
  className?: string;
  toasts: ToastWithKeyType[];
  addToast?: typeof addToast;
  removeToast: typeof removeToast;
  altCloseIcon?: boolean;
  isPaymentModal?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  heading,
  showCloseIcon = false,
  children,
  closeModal,
  toasts,
  addToast,
  removeToast,
  onClose,
  backdropColor = 'bg-modal-backdrop',
  // maxWidth = 'max-w-720',
  noPadding = false,
  className = '',
  altCloseIcon,
  isPaymentModal,
}) => {
  const modalClassNames = className.includes('rounded') ? className : `rounded-20 ${className}`;
  return (
    <div className="fixed z-999 inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-30 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className={`absolute inset-0 ${backdropColor} bg-modal-grey bg-opacity-40 cursor-pointer`}
            onClick={() => closeModal()}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          className={`inline-block max-w-1024 align-bottom bg-white md:mt-100 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${modalClassNames}`}
        >
          <div className="relative w-full flex justify-end h-0 ">
            <div className="zoomIn absolute md:hidden z-9999 rounded-full right-0 cursor-pointer p-18 -mr-20 -mt-30 w-50 h-50 bg-quantity-bg flex justify-center items-center">
              <Image
                src={Close}
                alt="Close"
                className="h-14 w-14 "
                onClick={() => {
                  onClose();
                }}
                noDefaultDimensions
                noDefaultFit
              />
            </div>
          </div>
          <div className={`${noPadding ? '' : 'bg-white p-14 md:p-24 text-label overflow-y-scroll rounded-20'}`}>
            {(showCloseIcon || heading) && (
              <div className="flex items-center justify-between">
                {heading && <div className="font-semibold text-20">{heading}</div>}
                {showCloseIcon ? (
                  <>
                    {altCloseIcon ? (
                      <VariantCircleRemove
                        className={classnames('ml-auto cursor-pointer', { 'mt-32 mr-40': noPadding })}
                        onClick={() => closeModal()}
                      />
                    ) : (
                      <ModalCloseIcon
                        className={classnames('ml-auto cursor-pointer', { 'mt-16 mr-16': noPadding })}
                        onClick={() => closeModal()}
                      />
                    )}
                  </>
                ) : null}
              </div>
            )}

            {heading && <hr className="my-16" />}
            {children}
          </div>
        </div>
        {/* to handle close button payment iframe in checkout */}
        {isPaymentModal ? (
          <button
            onClick={() => closeModal()}
            className="hidden sm:inline-block relative self-start align-top focus:outline-none bg-white p-2 mt-40 md:mt-32"
          >
            <VariantCircleRemove className="w-24 h-24" />
          </button>
        ) : null}
      </div>
      {toasts.length ? (
        <div className="w-auto max-w-410 md:hidden" id="QuickViewToasts">
          <Toasts toasts={toasts} addToast={addToast} removeToast={removeToast} quickView={true} />
        </div>
      ) : null}
    </div>
  );
};

export default connect(
  ({ toasts }: RootState) => ({
    toasts,
  }),
  {
    removeToast,
  },
)(Modal);
