import AtoZ from '@images/atoz.svg';
import Clear from '@images/clear.svg';
import HighToLow from '@images/HighToLow.svg';
import Latest from '@images/Latest.svg';
import LowToHigh from '@images/lowToHigh.svg';
import close from '@images/modalClose.svg';
import Sort from '@images/Sort.svg';
import ZtoA from '@images/ZtoA.svg';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import React, { useContext, useState } from 'react';

import { CategoryContext } from './CategoryContext';

interface SortModalProps {
  closeModal: () => void;
}

const SortByModal: React.FC<SortModalProps> = ({ closeModal }) => {
  const {
    computedSortOptions: { UNSELECTED_SORT_OPTIONS, SELECTED_SORT_OPTION },
  } = useContext(CategoryContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedOption, setSelectedOption] = useState(SELECTED_SORT_OPTION);

  return (
    <div className="fixed inset-0 z-999 overflow-y-auto bg-black bg-opacity-70 h-full w-full flex items-end justify-center">
      <div className="bg-white w-full h-fit min-w-full">
        <OutsideClickHandler onOutsideClick={() => closeModal()}>
          <div className="relative hidden w-full justify-end h-0">
            <div
              className="-mr-20 -mt-30 rounded-full w-50 h-50 bg-quantity-bg flex justify-center items-center"
              onClick={() => closeModal()}
            >
              <img src={close} alt="close" />
            </div>
          </div>
          <div className="">
            <div
              className="text-textColor px-20 pt-20 pb-10 text-15 font-medium leading-19 flex w-full justify-start"
              font-customisation="section-heading"
              color-customisation="page-heading"
            >
              Sort by
            </div>
            <div className="">
              {UNSELECTED_SORT_OPTIONS?.map((item) => {
                return (
                  <div key={item.key} className="">
                    <div
                      className="text-15 leading-19 border-b border-sortBorder px-20 py-12 text-textColor font-normal first:pt-0"
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                      onClick={() => {
                        // setSelectedOption(item);
                        item?.onClick?.();
                        closeModal();
                      }}
                    >
                      <SortOptions item={item} />
                    </div>
                  </div>
                );
              })}
              <div
                className="text-15 leading-19 text-textColor px-20 py-12 font-normal"
                font-customisation="para-text"
                color-customisation="page-body-text"
                onClick={() => closeModal()}
              >
                Cancel
              </div>

              <div className="hidden grid-rows-2 w-full">
                <button
                  className="font-semibold text-16 leading-16 text-white h-44 bg-themeBg mb-15 rounded-5"
                  onClick={() => {
                    selectedOption?.onClick?.();
                    closeModal();
                  }}
                  font-customisation="para-text"
                  button-customisation="buyNow-button"
                >
                  Apply
                </button>
                <button
                  onClick={() => closeModal()}
                  className="border border-disabled font-semibold text-16 leading-16 text-textColor h-44 rounded-5"
                  font-customisation="para-text"
                  button-customisation="page-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

interface SortOptionsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}

export const SortOptions: React.FC<SortOptionsProps> = ({ item }) => {
  switch (item?.key) {
    case 'final_price':
      return (
        <span className="flex items-center gap-8">
          <img src={LowToHigh} alt="Sort" className="w-12 h-12" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    case '-final_price':
      return (
        <span className="flex items-center gap-8">
          <img src={HighToLow} alt="Sort" className="w-12 object-cover h-16" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    case 'title':
      return (
        <span className="flex items-center gap-8">
          <img src={AtoZ} alt="Sort" className="w-12 h-12" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    case '-title':
      return (
        <span className="flex items-center gap-8">
          <img src={ZtoA} alt="Sort" className="w-12 object-cover h-16" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    case 'sort_order':
      return (
        <span className="flex items-center gap-8">
          <img src={Clear} alt="Sort" className="w-10 h-10" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    case '-created':
      return (
        <span className="flex items-center gap-8">
          <img src={Latest} alt="Sort" className="w-12 h-12" />
          {item.unSelectedName ?? item.name}
        </span>
      );
    default:
      return (
        <span className="flex items-center gap-8">
          <img src={Sort} alt="Sort" className="w-12 h-12" />
          {item.unSelectedName ?? item.name}
        </span>
      );
  }
};

export default SortByModal;
