import Category from '@common/models/Category';
import classnames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface ICustomLinkProps {
  children: React.ReactNode;
  isExternal?: boolean;
  link: string;
  ref?: React.MutableRefObject<HTMLAnchorElement>;
  category?: Category;
  className?: string;
  isDropdownOpen?: number;
  setIsDropdownOpen?: (number) => void;
  onClick?: () => void;
}

const CustomLink = (props: ICustomLinkProps): React.ReactElement => {
  const location = useLocation();
  const {
    category,
    className = '',
    link,
    children,
    ref,
    isExternal,
    isDropdownOpen,
    setIsDropdownOpen,
    onClick,
  } = props;
  const { pathname } = location;
  const handleClick = (e) => {
    if (category?.id === 0 && setIsDropdownOpen) {
      e.preventDefault();
      e.stopPropagation();
      if (category?.childCategories?.length) {
        setIsDropdownOpen(isDropdownOpen != -1 ? -1 : category?.id);
      }
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <>
      {isExternal ? (
        <a {...props} href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <Link
          {...props}
          to={link}
          ref={ref}
          onClick={handleClick}
          className={classnames(
            {
              '': category?.name === 'Shop All',
              'font-bold':
                ((pathname === category?.storeLink ||
                  pathname === link ||
                  (link?.includes(pathname) && pathname?.startsWith('/account'))) &&
                  category?.name !== 'Shop All') ||
                (pathname?.startsWith('/shop') && link?.startsWith('/shop')),
              'hover:font-bold font-bold':
                (pathname?.startsWith('/category') || pathname?.startsWith('/shop')) && category?.name === 'Shop All',
            },
            className,
          )}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default CustomLink;
