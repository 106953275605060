import React from 'react';

interface LensPosProps {
  top: number;
  left: number;
}

export interface ZoomLensProps {
  className?: string;
  mouseEnter: boolean;
  lensPos: LensPosProps;
}

const ZoomLens: React.FunctionComponent<ZoomLensProps> = ({ mouseEnter, lensPos }) => {
  return (
    <div
      className="absolute w-100 h-100 bg-white pointer-events-none"
      style={{
        left: lensPos.left,
        top: lensPos.top,
        opacity: 0.4,
        display: mouseEnter ? 'block' : 'none',
      }}
    ></div>
  );
};

export default ZoomLens;
