import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import { useSelector } from 'react-redux';

interface UseBuyerLogin {
  features: FeaturesSliceType;
  hideReviewsSection: boolean;
}

const useBuyerLogin = (): UseBuyerLogin => {
  const features = useSelector((state: RootState) => state.features);
  const productDetails = useSelector((state: RootState) => state.productDetails);

  const hideReviewsSection = !features.features?.buyerLogin && productDetails.reviews?.length === 0;
  return { features, hideReviewsSection };
};

export default useBuyerLogin;
