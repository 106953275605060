/* eslint-disable @typescript-eslint/no-unused-vars */
// import LatestProducts from '@common/components/LatestProducts';
// import ProductCard from '@common/components/ProductCard';
import // Banner,
// Products as ProductsSection,
// SectionEnum,
'@common/models/sections';

import ProductCardLoading from '@common/components/ProductCard/ProductCardLoading';
import Reward from '@common/components/Reward';
// import WideProductCard from '@common/components/WideProductCard';
import { ReactComponent as AddSymbol } from '@common/icons/AddSymbol.svg';
import { RootState } from '@common/store';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { fetchHomeRequest, HomeSliceType } from '@common/store/home/slice';
import { fetchLegacySliderRequest } from '@common/store/legacySlider/slice';
import { SliderSliceType } from '@common/store/legacySlider/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import {
  fetchReferralCodeDetailsRequest,
  ReferralSliceType,
  resetReferralCodeDetails,
} from '@common/store/referral/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { TestimonialsSliceType } from '@common/store/testimonials/slice';
import { addToast } from '@common/store/toasts/slice';
import { getAddProductUrl, PRODUCTS_KEY } from '@common/utils/constants';
import { getMerchantJWTToken } from '@common/utils/token';
// import BannerCarousal from '@components/BannerCarousal';
import PageLayout from '@components/PageLayout';
// import Pagination from '@components/Pagination';
import ProductCard from '@components/ProductCard';
// import ProductList from '@components/ProductList';
// import Cup from '@images/Cup.svg';
// import TextBg from '@images/TextBg.svg';
// import Support from '@images/Support.svg';
// import Warrenty from '@images/Warrenty.svg';
import Error from '@lib/Error';
import InfiniteScroll from '@lib/InfiniteScroll';
import InjectScript from '@lib/InjectScript';
// import Link from '@lib/Link';
import Loading from '@lib/Loading';
// import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// import { smoothScroll } from '../../../../common/utils';
import { getQueryParamsFromURL } from '../../../../common/utils/params';
// import Subscribe from './Subscribe';

interface MatchParams {
  categoryId: string;
  categorySlug: string;
}

interface HomeProps extends RouteComponentProps<MatchParams> {
  products: Record<string, ProductsSliceType>;
  categories: CategoriesSliceType;
  cart: CartSliceType;
  recentlyViewed: RecentlyViewedSliceType;
  home: HomeSliceType;
  slider: SliderSliceType;
  storeInfo: StoreInfoSliceType;
  fetchLegacySliderRequest: typeof fetchLegacySliderRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  fetchHomeRequest: typeof fetchHomeRequest;
  resetProductsStore: typeof resetProductsStore;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  addToast: typeof addToast;
  testimonials: TestimonialsSliceType;
  FilterTheme?: string;
  BannerTheme?: string;
  fetchReferralCodeDetailsRequest: typeof fetchReferralCodeDetailsRequest;
  resetReferralCodeDetails: typeof resetReferralCodeDetails;
  referral: ReferralSliceType;
}

class Home extends PureComponent<HomeProps> {
  private allProductsRef: React.RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
    if (!this.props.slider.isFetched) {
      this.props.fetchLegacySliderRequest();
    }
  }
  componentDidUpdate(prevProps) {
    const categoryId = this.props.match.params.categoryId;
    if (categoryId !== prevProps.match.params.categoryId) {
      this.props.resetProductsStore(PRODUCTS_KEY.ALL);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          ordering: '-created',
          category: +categoryId || undefined,
        },
      });
    }
  }
  componentDidMount() {
    const queryParam = getQueryParamsFromURL();
    if (queryParam?.referrer_code) {
      this.props.fetchReferralCodeDetailsRequest(queryParam?.referrer_code);
    } else {
      this.props.resetReferralCodeDetails();
    }
    if (!this.props.products[PRODUCTS_KEY.ALL]?.list?.results.length) {
      this.props.resetProductsStore(PRODUCTS_KEY.ALL);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          ordering: '-created',
          category: +this.props.match.params.categoryId || undefined,
        },
      });
      this.props.fetchCategoriesRequest({
        queryParams: {
          ...getCategoryQueryParamsInitialState(),
          ordering: 'created',
        },
      });
      // this.props.fetchHomeRequest();
    }
    this.allProductsRef = React.createRef();
  }
  render() {
    const mainProductsSlice = this.props.products[PRODUCTS_KEY.ALL];
    return (
      <PageLayout
        filterBarType="home"
        showBanner={true}
        enabledBanner={this.props?.storeInfo?.storeInfo?.sliderEnabled}
        showBranding={false}
      >
        <InjectScript others="home" />
        {!this.props.home.isFetched && <Loading />}
        {this.props.home.isFetched && this.props.home.error && <Error message={this.props.home.error} />}
        {this.props.home.isFetched && !this.props.home.error && (
          <>{this.props.referral.isReferralCodeFetched && <Reward referral={this.props.referral} />}</>
        )}
        <div className="mt-20 md:mt-40">
          {mainProductsSlice?.error ? (
            <div className="text-center text-bold border rounded m-100 p-25">{mainProductsSlice?.error}</div>
          ) : null}
          <div className="mx-auto container px-16 md:px-42">
            {!this.props.products[PRODUCTS_KEY.ALL] ||
              (mainProductsSlice?.isFetching && (
                <div className="flex mx-auto ml-100 mb-80">
                  <ProductCardLoading />
                  <ProductCardLoading />
                  <ProductCardLoading />
                </div>
              ))}
            <InfiniteScroll
              dataLength={mainProductsSlice?.fetchedProducts?.length || 0} //This is important field to render the next data
              next={() => {
                this.props.fetchProductsRequest({
                  storeKey: PRODUCTS_KEY.ALL,
                  queryParams: {
                    ...mainProductsSlice?.currentQueryParams,
                    page: (mainProductsSlice?.currentQueryParams?.page ?? 0) + 1,
                  },
                });
              }}
              hasMore={mainProductsSlice?.fetchedProducts?.length < mainProductsSlice?.list?.count}
              loader={<h4></h4>}
              scrollThreshold={0.8}
            >
              <div className="flex mb-15 flex-wrap items-stretch justify-center md:justify-start md:gap-30">
                {mainProductsSlice?.fetchedProducts?.map((product) => (
                  <div className="block mb-10 min-w-full md:min-w-312" key={product.id}>
                    <ProductCard
                      addToast={this.props.addToast}
                      product={product}
                      addProductToCart={this.props.addProductToCart}
                      subtractProductFromCart={this.props.subtractProductFromCart}
                      cartProductQuantity={this.props.cart.quantityByProductId[product.id] || 0}
                      storeInfo={this.props.storeInfo}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
            {getMerchantJWTToken() &&
            mainProductsSlice?.isFetched &&
            !mainProductsSlice?.error &&
            (mainProductsSlice?.list?.results?.length || 0) <= 5 ? (
              <div className="px-40 mb-50 w-full sm:w-1/2 md:w-1/3">
                <a href={getAddProductUrl()} target="_blank" rel="noreferrer noopener">
                  <div className="flex flex-col items-center rounded-sm bg-white h-full w-full relative">
                    <AddSymbol className="mt-62 mb-74" />
                    <div className="text-black_variant_1 text-18 mb-50">Add a product</div>
                  </div>
                </a>
              </div>
            ) : null}
          </div>
          {!getMerchantJWTToken() &&
          mainProductsSlice?.isFetched &&
          !mainProductsSlice?.error &&
          !mainProductsSlice?.list?.results?.length ? (
            <div className="w-full text-center text-18 font-medium text-disabled my-72">
              <div>This store does not have any product listed yet.</div>
              <div>Please check back after few days.</div>
            </div>
          ) : null}
        </div>
        {/* <Pagination
            className="mb-48 hidden md:block"
            {...mainProductsSlice?.pagination}
            onClick={(pageNumber) => {
              this.props.resetProductsStore(PRODUCTS_KEY.ALL);
              this.props.fetchProductsRequest({
                storeKey: PRODUCTS_KEY.ALL,
                queryParams: {
                  ...mainProductsSlice?.currentQueryParams,
                  page: pageNumber,
                },
              });
              smoothScroll(this.allProductsRef.current?.offsetTop - 150, 0);
            }}
          /> */}
        {/* {this.props.storeInfo?.storeInfo?.isNewsletterSubscriptionEnabled && <Subscribe></Subscribe>} */}
      </PageLayout>
    );
  }
}

export default connect(
  ({
    products,
    categories,
    cart,
    recentlyViewed,
    home,
    storeInfo,
    testimonials,
    referral,
    legacySlider,
  }: RootState) => ({
    storeInfo,
    products,
    categories,
    cart,
    recentlyViewed,
    home,
    slider: legacySlider,
    testimonials,
    referral,
  }),
  {
    fetchLegacySliderRequest,
    fetchProductsRequest,
    fetchCategoriesRequest,
    fetchHomeRequest,
    resetProductsStore,
    addProductToCart,
    subtractProductFromCart,
    removeProductFromCart,
    addToast,
    fetchReferralCodeDetailsRequest,
    resetReferralCodeDetails,
  },
)(Home);
