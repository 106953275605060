// import { ReactComponent as EditIcon } from '@common/icons/Edit.svg';
// import { ReactComponent as ReplyIcon } from '@common/icons/Reply.svg';
// import { ReactComponent as ThumbsUpIcon } from '@common/icons/ThumbsUp.svg';
// import { isMobile } from '@common/utils';
import classnames from 'classnames';
import React from 'react';

import StarRating from './StarRating';
// import StarRating from './StarRating';

export interface ReviewProps {
  className?: string;
  rating?: number;
  total?: number;
  name: string;
  date: string;
  review: string;
}

const Review: React.FunctionComponent<ReviewProps> = ({ name, date, review, rating, className = '' }) => {
  return (
    <>
      <div
        className={`flex flex-col w-full border-review-text border-b pt-24 ${className}`}
        font-customisation="para-text"
      >
        <div className="w-150 mr-20">
          <div className="text-16 text-review-text mb-2" color-customisation="page-body-text">
            {name}
          </div>
          <div className={classnames('text-16 text-black mb-10')} color-customisation="page-body-text">
            {review}
          </div>
        </div>
        <div className="flex text-left relative">
          <div className="hidden">
            {rating != null ? (
              <StarRating
                rating={rating}
                total={5}
                isInteractive={false}
                className="transform scale-60 absolute left-0 -mt-4 -ml-32"
              />
            ) : null}
          </div>
          <div className="text-16 text-black mb-4" color-customisation="page-body-text">
            {new Date(date).toLocaleDateString()?.replaceAll('/', '-')}
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
