import { isBrowser } from '@common/utils';
import React, { useEffect, useRef } from 'react';

export const useConstructor = (callBack: React.EffectCallback, dependencies: React.DependencyList): void => {
  const hasBeenCalled = useRef(false);
  useEffect(callBack, dependencies);
  if (hasBeenCalled.current) return;
  !isBrowser() && callBack();
  hasBeenCalled.current = true;
};
