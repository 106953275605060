import { FormReturnType } from '@common/hooks/useForm';
import CustomField, { OptionValue } from '@common/models/CustomField';
import Product, { PRODUCT_CUSTOM_FIELD_TYPE } from '@common/models/Product';
import React from 'react';

import { ProductAddToCartHOCRenderArgs } from '../ProductAddToCartHOC';
import { rupees } from '../Rupee';

interface CustomFieldHOCProps {
  addToCartHocArgs: ProductAddToCartHOCRenderArgs;
  product: Product;
  renderFormField: (args: RenderFormFieldArgs) => React.ReactNode;
  renderFormCheckboxRadioField: (args: RenderFormCheckboxRadioFieldArgs) => React.ReactNode;
  isCharges: boolean;
}

interface RenderFormFieldArgs {
  customFieldForm: FormReturnType;
  formInputName: string;
  optionType: string;
  optionValues: OptionValue[];
  charges: string;
  productOption: CustomField;
  multiple: boolean;
  typeOfInput: string;
}

interface RenderFormCheckboxRadioFieldArgs {
  customFieldForm: FormReturnType;
  formInputName: string;
  optionType: string;
  optionValues: OptionValue[];
  charges: string;
  productOption: CustomField;
}

const type = {
  1: 'text',
  2: 'radio',
  3: 'checkbox',
  4: 'date',
  5: 'datetime-local',
  6: 'file',
  7: 'multifile',
};
const CustomFieldHOC: React.FC<CustomFieldHOCProps> = ({
  addToCartHocArgs,
  product,
  renderFormField,
  renderFormCheckboxRadioField,
  isCharges = false,
}) => {
  const { customFieldFormRef, customFieldForm } = addToCartHocArgs;
  const { handleSubmit } = customFieldForm;
  return (
    <>
      <form
        ref={customFieldFormRef}
        onSubmit={handleSubmit(async (values: Record<string, unknown>) => {
          console.log({ values });
        })}
      >
        {product.customFields.map((productOption) => {
          const formInputName = productOption.formInputName;
          const optionType = productOption.type;
          const optionValues = productOption.values;

          const charges = productOption.charges
            ? isCharges
              ? `${rupees(productOption.charges, true)}`
              : `(Charges: ${rupees(productOption.charges, true)})`
            : '';
          switch (optionType) {
            case PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE:
            case PRODUCT_CUSTOM_FIELD_TYPE.TEXT:
            case PRODUCT_CUSTOM_FIELD_TYPE.DATE:
            case PRODUCT_CUSTOM_FIELD_TYPE.DATETIME:
            case PRODUCT_CUSTOM_FIELD_TYPE.FILE: {
              let multiple = false;
              let typeOfInput = optionType;
              if (optionType === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE) {
                typeOfInput = 7;
                multiple = true;
              } else if (optionType === PRODUCT_CUSTOM_FIELD_TYPE.DATETIME) {
                typeOfInput = 5;
              }
              return renderFormField({
                customFieldForm,
                formInputName,
                optionType,
                optionValues,
                charges,
                productOption,
                multiple,
                typeOfInput: type[typeOfInput],
              });
            }
            case PRODUCT_CUSTOM_FIELD_TYPE.RADIO:
            case PRODUCT_CUSTOM_FIELD_TYPE.CHECKBOX: {
              if (!optionValues.length) {
                return null;
              }
              return renderFormCheckboxRadioField({
                customFieldForm,
                formInputName,
                optionType: type[optionType],
                optionValues,
                charges,
                productOption,
              });
            }
            default: {
              return null;
            }
          }
        })}
      </form>
    </>
  );
};

export default CustomFieldHOC;
