import Image from '@common/../lib-components/Image';
import Link from '@common/../lib-components/Link';
import DefaultProductImage from '@common/images/DefaultProductImage.png';
import Product from '@common/models/Product';
import Rupee from '@instamojo/rupee';
import classnames from 'classnames';
import React from 'react';

interface ProductBundleCardProps {
  product: Product;
  priceClassName?: string;
  discountPercentageColor?: string;
  calcPercentage?: number;
  borderClassName?: string;
  cartAlign?: string;
  isBaseProduct: boolean;
}

const ProductBundleCard: React.FC<ProductBundleCardProps> = ({
  product,
  priceClassName,
  discountPercentageColor,
  calcPercentage,
  borderClassName,
  isBaseProduct,
  cartAlign,
}) => {
  const linkClasses = isBaseProduct
    ? classnames(
        'flex flex-col rounded-sm w-250 p-16 relative border border-opacity-75 mt-16 mx-auto',
        borderClassName ? borderClassName : 'border-border',
      )
    : 'flex flex-col rounded-sm w-220 relative';

  return (
    <Link to={product.storeLink} className={linkClasses}>
      <div className="h-220 bg-white rounded-lg overflow-hidden">
        <Image
          src={product.images[0] ? product.images[0] : DefaultProductImage}
          alt={product.title}
          className={'rounded-t-sm'}
        />
      </div>
      <div className="p-10 pb-0 flex flex-col flex-1">
        <div className="flex-grow">
          <div className="text-20 text-dark font-semibold mt-8 mb-10 break-words add-ellipsis">{product.title}</div>
        </div>
        <div>
          <div className={classnames('flex flex-wrap items-end', { [cartAlign]: cartAlign })}>
            <div className="flex flex-row-reverse items-center">
              {product.bundleDetails?.discountedPrice && (
                <>
                  <div className="line-through font-normal text-20 text-productBundle-gray">
                    <span className="rupee-container " data-testid="rupee-value-container">
                      <Rupee value={product.price} removeSpaceAfterRupee />
                    </span>
                  </div>
                  <div className="flex items-center">
                    <div
                      className={classnames('font-medium text-20 mr-14', {
                        [priceClassName]: priceClassName,
                        'text-theme': !priceClassName,
                      })}
                    >
                      <span className="rupee-container " data-testid="rupee-value-container">
                        <Rupee value={product.bundleDetails?.discountedPrice} removeSpaceAfterRupee />
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div
                className={classnames(
                  'right-0 top-0 rounded-full px-10 py-5 text-white text-14 font-bold absolute',
                  isBaseProduct ? 'mt-26 mr-26' : 'mt-10 mr-10',
                  {
                    [discountPercentageColor]: discountPercentageColor,
                    'bg-savings': !discountPercentageColor,
                  },
                )}
              >
                {calcPercentage}% OFF
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductBundleCard;
