import { useState } from 'react';

interface ReturnType {
  showRemoveItemAlert: boolean;
  bundleProduct: Record<string, unknown>;
  handleCloseRemoveAlert: () => void;
  removeProductFromBundle: ({
    product,
    isRemoveProduct,
  }: {
    product: Record<string, unknown>;
    isRemoveProduct: boolean;
  }) => void;
  setShowRemoveItemAlert: (flag: boolean) => void;
}

export const useBundleDelete = (): ReturnType => {
  const [showRemoveItemAlert, setShowRemoveItemAlert] = useState(false);
  const [bundleProduct, setBundleProduct] = useState({});
  const handleCloseRemoveAlert = () => {
    setShowRemoveItemAlert(false);
  };
  const removeProductFromBundle = ({ product, isRemoveProduct }) => {
    setShowRemoveItemAlert(isRemoveProduct);
    setBundleProduct(product);
  };
  return {
    showRemoveItemAlert,
    bundleProduct,
    handleCloseRemoveAlert,
    removeProductFromBundle,
    setShowRemoveItemAlert,
  };
};
