import { ShopAllContext } from '@common/context/ShopAllContext';
import close from '@common/images/modalClose.svg';
import React, { useContext, useState } from 'react';

interface SortModalProps {
  closeModal: () => void;
}

const SortByModal: React.FC<SortModalProps> = ({ closeModal }) => {
  const {
    computedSortOptions: { UNSELECTED_SORT_OPTIONS, SELECTED_SORT_OPTION },
  } = useContext(ShopAllContext);
  const [selectedOption, setSelectedOption] = useState(SELECTED_SORT_OPTION);

  return (
    <div className="fixed inset-0 z-999 overflow-y-auto bg-black bg-opacity-70 h-full w-full flex items-start justify-center px-28">
      <div className="bg-white w-11/12 rounded-lg h-fit mt-64  min-w-320">
        <div className="relative w-full flex justify-end h-0">
          <div
            className="-mr-20 -mt-30 rounded-full w-50 h-50 bg-quantity-bg flex justify-center items-center"
            onClick={() => closeModal()}
          >
            <img src={close} alt="close" />
          </div>
        </div>
        <div className="p-20">
          <div
            className="text-textColor text-18 font-semibold leading-19 flex w-full justify-center mb-30"
            font-customisation="section-heading"
            color-customisation="page-heading"
          >
            Sort by
          </div>
          <div>
            {UNSELECTED_SORT_OPTIONS?.map((item) => (
              <div key={item.key} className="mb-24 ml-4">
                <input
                  type="radio"
                  id={item.key}
                  checked={item.name === selectedOption.name}
                  value={item.key}
                  onClick={() => setSelectedOption(item)}
                />
                <label htmlFor={item.key}>
                  <span
                    className="text-18 leading-19 text-black_variant_2 font-regular"
                    font-customisation="para-text"
                    color-customisation="page-body-text"
                  >
                    {item.unSelectedName ?? item.name}
                  </span>
                </label>
              </div>
            ))}

            <div className="grid grid-rows-2 w-full">
              <button
                className="font-semibold text-16 leading-16 rounded-5 text-white h-46 bg-theme mb-16"
                onClick={() => {
                  selectedOption?.onClick?.();
                  closeModal();
                }}
                font-customisation="para-text"
                button-customisation="buyNow-button"
              >
                Apply
              </button>
              <button
                onClick={() => closeModal()}
                className="border border-quantity-border font-semibold text-16 leading-16 rounded-5 text-dark h-46"
                font-customisation="para-text"
                button-customisation="page-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortByModal;
