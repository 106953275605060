import Carousel from '@brainhubeu/react-carousel';
import { getFilteredSliderImages } from '@common/models/LegacySlider';
import { SliderSliceType } from '@common/store/slider/slice';
import BannerRightArrow from '@images/bannerSlider.svg';
// import Image from '@lib/Image';
import classnames from 'classnames';
import React from 'react';

export interface BannerCarousalProps {
  slider: SliderSliceType;
}

const BannerCarousal: React.FC<BannerCarousalProps> = ({ slider }) => {
  const filteredImages = getFilteredSliderImages({ slider, shouldImageBePresent: true });
  if (!filteredImages?.length) {
    return null;
  }
  return (
    <div className="BannerCarousal w-full relative ">
      <Carousel
        arrows={false}
        dots={filteredImages?.length > 1 ? true : false}
        slidesPerPage={1}
        offset={0}
        lazyLoad={true}
        addArrowClickHandler={true}
        autoPlay={5000}
        arrowLeft={
          filteredImages?.length > 1 ? (
            <div className="rounded-full flex cursor-pointer absolute top-0 left-0  justify-center h-full items-center z-10">
              <div className="bg-white h-38 w-38 flex justify-center items-center rounded-r-full">
                <img src={BannerRightArrow} alt="Left" className={classnames('transform rotate-180 h-16', {})} />
              </div>
            </div>
          ) : null
        }
        arrowRight={
          filteredImages?.length > 1 ? (
            <div className="rounded-full flex cursor-pointer absolute top-0 right-0 justify-center h-full items-center z-10">
              <div className="bg-white h-38 w-38 flex justify-center items-center rounded-l-full">
                <img src={BannerRightArrow} alt="Right" className={classnames('h-16', {})} />
              </div>
            </div>
          ) : null
        }
        slides={filteredImages.map((banner, index) => {
          return (
            <div className="bg-cover bg-no-repeat w-full overflow-hidden relative" key={banner.slider}>
              <div className="flex">
                <div
                  className={classnames('md:w-full h-140 md:h-270 w-screen', {})}
                  style={{
                    background: `url('${filteredImages[index]?.image}')`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                >
                  <div className="hidden md:flex justify-center h-full items-center">
                    {banner.heading || banner.subheading ? (
                      <a
                        className={classnames(
                          'z-40 border p-16 max-h-200 text-center md:max-w-500 min-w-80 bg-black bg-opacity-45 flex gap-10 justify-center items-center flex-col',
                        )}
                        href={banner.link}
                      >
                        {banner?.heading ? (
                          <p
                            className="text-white text-30 font-medium w-full line-clamp-2"
                            font-customisation="section-heading"
                            color-customisation="banner-heading"
                          >
                            {banner.heading}
                          </p>
                        ) : null}
                        {banner.subheading ? (
                          <p
                            className="text-white text-14 font-normal line-clamp-2"
                            font-customisation="para-text"
                            color-customisation="banner-subHeading"
                          >
                            {banner.subheading}
                          </p>
                        ) : null}
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      ></Carousel>
    </div>
  );
};

export default BannerCarousal;
