import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

interface ProductVideoProps {
  productVideoUrl: string;
  height: string;
  width: string;
  className: string;
  autoPlay: boolean;
}

const ProductVideo: React.FC<ProductVideoProps> = ({
  productVideoUrl,
  height,
  width,
  className = '',
  autoPlay = false,
}) => {
  const opts: YouTubeProps['opts'] = {
    height,
    width,
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
    },
  };
  const videoId = productVideoUrl.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
  );
  return <YouTube videoId={videoId[1]} opts={opts} className={className} />;
};

export default ProductVideo;
