import { FormReturnType } from '@common/hooks/useForm';
import Option, { OptionValue } from '@common/models/Option';
import Product, { PRODUCT_OPTIONS_TYPE } from '@common/models/Product';
import React from 'react';

import { ProductAddToCartHOCRenderArgs } from '../ProductAddToCartHOC';
import { rupees } from '../Rupee';

interface ProductOptionsHOCProps {
  addToCartHocArgs: ProductAddToCartHOCRenderArgs;
  product: Product;
  renderFormField: (args: RenderFormFieldArgs) => React.ReactNode;
  renderFormCheckboxRadioField: (args: RenderFormCheckboxRadioFieldArgs) => React.ReactNode;
}

interface RenderFormFieldArgs {
  form: FormReturnType;
  formInputName: string;
  optionType: string;
  optionValues: OptionValue[];
  charges: string;
  productOption: Option;
  multiple: boolean;
  typeOfInput: string;
}

interface RenderFormCheckboxRadioFieldArgs {
  form: FormReturnType;
  formInputName: string;
  optionType: string;
  optionValues: OptionValue[];
  charges: string;
  productOption: Option;
}

const ProductOptionsHOC: React.FC<ProductOptionsHOCProps> = ({
  addToCartHocArgs,
  product,
  renderFormField,
  renderFormCheckboxRadioField,
}) => {
  const { optionsFormRef, form } = addToCartHocArgs;
  const { handleSubmit } = form;
  return (
    <>
      <form
        ref={optionsFormRef}
        onSubmit={handleSubmit(async (values: Record<string, unknown>) => {
          console.log({ values });
        })}
      >
        {product?.options?.map((productOption) => {
          const formInputName = productOption.formInputName;
          const optionType = productOption.type;
          const optionValues = productOption.values;
          const charges = productOption.charges ? `(Charges: ${rupees(productOption.charges, true)})` : '';

          switch (optionType) {
            // case PRODUCT_OPTIONS_TYPE.MULTI_FILE:
            case PRODUCT_OPTIONS_TYPE.TEXT:
            case PRODUCT_OPTIONS_TYPE.DATE:
            case PRODUCT_OPTIONS_TYPE.DATETIME:
            case PRODUCT_OPTIONS_TYPE.TIME:
            case PRODUCT_OPTIONS_TYPE.FILE: {
              let multiple = false;
              let typeOfInput = optionType;
              if (optionType === PRODUCT_OPTIONS_TYPE.MULTI_FILE) {
                typeOfInput = 'file';
                multiple = true;
              } else if (optionType === PRODUCT_OPTIONS_TYPE.DATETIME) {
                typeOfInput = 'datetime-local';
              }
              return renderFormField({
                form,
                formInputName,
                optionType,
                optionValues,
                charges,
                productOption,
                multiple,
                typeOfInput,
              });
            }
            case PRODUCT_OPTIONS_TYPE.RADIO:
            case PRODUCT_OPTIONS_TYPE.CHECKBOX: {
              if (!optionValues.length) {
                return null;
              }
              return renderFormCheckboxRadioField({
                form,
                formInputName,
                optionType,
                optionValues,
                charges,
                productOption,
              });
            }
            default: {
              return null;
            }
          }
        })}
      </form>
    </>
  );
};

export default ProductOptionsHOC;
