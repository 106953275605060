// import { useConstructor } from '@common/hooks/useConstructor';
// import { RouteMap } from '@common/RouteMap';
import { RootState } from '@common/store';
import { getPageMetaTags } from '@common/utils';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class CategorySeo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { location, storeInfo, categoryName } = this.props;
    const pageMetaTags = getPageMetaTags({ location, storeName: storeInfo?.storeInfo?.storename, categoryName });
    return (
      <Helmet>
        {pageMetaTags?.map((result, index) => (
          <meta key={index} name={result.name} content={result.content} />
        ))}
      </Helmet>
    );
  }
}

export default withRouter(
  connect(({ storeInfo }: RootState) => ({
    storeInfo,
  }))(CategorySeo),
);
