import BasicLayout from '@common/components/Admin/Layout/BasicLayout';
import DefaultBranding from '@common/components/Branding';
import SEO from '@common/components/SEO';
// import SliderComponent from '@common/components/Slider';
// import { Banner, SectionEnum } from '@common/models/sections';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { HomeSliceType } from '@common/store/home/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import {
  fetchProductsRequest,
  // getProductQueryParamsInitialState,
  ProductsSliceType,
  // resetProductsStore,
} from '@common/store/products/slice';
import { fetchSearchRequest, SearchSliceType } from '@common/store/search/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { UserProfileSliceType } from '@common/store/userProfile/slice';
import BannerCarousal from '@components/BannerCarousal';
// import { isBrowser } from '@common/utils';
// import { PRODUCTS_KEY } from '@common/utils/constants';
// import FilterBar from '@components/FilterBar';
import Branding from '@components/Branding';
// import { isBrowser } from '@common/utils';
// import { PRODUCTS_KEY } from '@common/utils/constants';
// import Branding from '@components/Branding';
// import FilterBar from '@components/FilterBar';
import Footer from '@components/Footer';
import Toasts from '@components/Toasts';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';
import Navbar from '../Navbar';

export interface PageLayoutProps {
  children: React.ReactNode;
  topChildren?: React.ReactNode;
  cart: CartSliceType;
  products: Record<number, ProductsSliceType>;
  toasts: ToastWithKeyType[];
  storeInfo: StoreInfoSliceType;
  home: HomeSliceType;
  slider: SliderSliceType;
  search: Record<string, SearchSliceType>;
  addProductToCart: typeof addProductToCart;
  removeProductFromCart: typeof removeProductFromCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  addToast: typeof addToast;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  categories: CategoriesSliceType;
  removeToast: typeof removeToast;
  showBanner?: boolean;
  enabledBanner?: boolean;
  fetchSearchRequest: typeof fetchSearchRequest;
  customPages: CustomPagesSliceType;
  userProfile: UserProfileSliceType;
  features: FeaturesSliceType;
  filterBarType?: string;
  hideToast?: boolean;
  filterBar: FilterBarSliceType;
  policiesTos: PoliciesSliceTosType;
  showNavbar: boolean;
  showBranding: boolean;
}

const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  customPages,
  cart,
  toasts,
  children,
  fetchCategoriesRequest,
  categories,
  addProductToCart,
  removeProductFromCart,
  subtractProductFromCart,
  addToast,
  removeToast,
  storeInfo,
  // products,
  slider,
  // home,
  showBanner = false,
  enabledBanner = false,
  showBranding = false,
  showNavbar = true,
  filterBarType = 'others',
  fetchSearchRequest,
  search,
  userProfile,
  features,
  hideToast = false,
  filterBar,
  policiesTos,
}) => {
  if (!categories.isFetched && !categories.isFetching) {
    fetchCategoriesRequest({
      queryParams: {
        ...getCategoryQueryParamsInitialState(),
        ordering: 'created',
      },
    });
  }
  return (
    <BasicLayout
      navbarHeight={showBranding ? 54 : 0}
      navbarMobileHeight={0}
      className="bg-page pageLayout"
      Navbar={
        showBranding ? (
          <div className="hidden md:flex">
            <Branding />
          </div>
        ) : null
      }
    >
      {showBanner ? (
        <div className="">
          {enabledBanner ? (
            <div key={'Banner'} className="banner_decoration banner-bg-color">
              {slider.isFetched ? <BannerCarousal slider={slider} /> : null}
            </div>
          ) : (
            <div className="h-140 bg-noBanner"></div>
          )}
        </div>
      ) : null}
      {showNavbar ? (
        <Navbar
          userProfile={userProfile}
          fetchSearchRequest={fetchSearchRequest}
          storeInfo={storeInfo.storeInfo}
          cart={cart}
          search={search}
          categories={categories}
          filterBarType={filterBarType}
          features={features}
          addProductToCart={addProductToCart}
          subtractProductFromCart={subtractProductFromCart}
          removeProductFromCart={removeProductFromCart}
          storeType={storeInfo.storeInfo?.storeType}
        />
      ) : null}
      <SEO />
      {/* {isBrowser() && window.location.pathname.includes('account/login') ? null : categories.isFetched ? (
        <div className="top-84 hidden md:block w-full sticky z-40 bg-white">
          <FilterBar
            className=""
            categories={categories?.categories?.results || []}
            products={products[PRODUCTS_KEY.ALL]}
            fetchProductsRequest={(overrideParams) =>
              fetchProductsRequest({
                storeKey: PRODUCTS_KEY.ALL,
                queryParams: {
                  ...getProductQueryParamsInitialState(),
                  ...overrideParams,
                },
              })
            }
          />
        </div>
      ) : null} */}
      {!hideToast ? (
        <div className="w-auto hidden max-w-410 mx-auto">
          <Toasts toasts={toasts} addToast={addToast} removeToast={removeToast} />
        </div>
      ) : null}
      {children}
      <Footer
        storeInfo={storeInfo.storeInfo}
        customPages={customPages}
        features={features}
        filterBar={filterBar}
        policies={policiesTos}
      />
      <DefaultBranding className="mt-10" />
      {/* {!features?.features?.whiteLabel ? <Branding storeInfo={storeInfo.storeInfo} className="mt-40" /> : null} */}
    </BasicLayout>
  );
};

export default connect(
  ({
    cart,
    products,
    slider,
    toasts,
    categories,
    storeInfo,
    home,
    search,
    customPages,
    userProfile,
    features,
    filterBar,
    policiesTos,
  }: RootState) => ({
    search,
    home,
    cart,
    slider,
    products,
    categories,
    toasts,
    storeInfo,
    customPages,
    userProfile,
    features,
    filterBar,
    policiesTos,
  }),
  {
    addProductToCart,
    removeProductFromCart,
    fetchCategoriesRequest,
    fetchProductsRequest,
    subtractProductFromCart,
    addToast,
    removeToast,
    fetchSearchRequest,
  },
)(PageLayout);
