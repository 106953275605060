import { ReactComponent as ModalCloseIcon } from '@common/icons/ModalClose.svg';
import { ReactComponent as VariantCircleRemove } from '@common/icons/VariantCircleRemove.svg';
import classnames from 'classnames';
import React, { useEffect } from 'react';

export interface CartModalProps {
  heading: string;
  showCloseIcon?: boolean;
  children: React.ReactNode;
  backdropColor?: string;
  closeModal: () => void;
  maxWidth?: string;
  noPadding?: boolean;
  className?: string;
  altCloseIcon?: boolean;
  isPaymentModal?: boolean;
  zIndex?: string;
}

const CartModal: React.FC<CartModalProps> = ({
  heading,
  showCloseIcon = false,
  children,
  closeModal,
  backdropColor = 'bg-modalBg',
  // maxWidth = 'max-w-720',
  noPadding = false,
  className = '',
  altCloseIcon,
  isPaymentModal,
  zIndex = '',
}) => {
  useEffect(() => {
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.height = null;
      document.body.style.overflow = null;
    };
  }, []);
  const modalClassNames = className.includes('rounded') ? className : `rounded-lg ${className}`;
  return (
    <div
      className={classnames('fixed inset-0 overflow-y-auto', {
        'z-10': !zIndex,
        [zIndex]: zIndex,
      })}
    >
      <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div
            className={`absolute inset-0 ${backdropColor} bg-opacity-30 cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          className={`inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${modalClassNames}`}
        >
          <div className={`${noPadding ? '' : 'bg-white text-label'}`}>
            {(showCloseIcon || heading) && (
              <div className="flex items-center justify-between">
                {heading && <div className="font-semibold text-20">{heading}</div>}
                {showCloseIcon ? (
                  <>
                    {altCloseIcon ? (
                      <VariantCircleRemove
                        className={classnames('ml-auto cursor-pointer', { 'mt-32 mr-40': noPadding })}
                        onClick={() => closeModal()}
                      />
                    ) : (
                      <ModalCloseIcon
                        className={classnames('ml-auto cursor-pointer', { 'mt-16 mr-16': noPadding })}
                        onClick={() => closeModal()}
                      />
                    )}
                  </>
                ) : null}
              </div>
            )}
            {children}
          </div>
        </div>
        {isPaymentModal ? (
          <button
            onClick={() => closeModal()}
            className="hidden sm:inline-block relative self-start align-top focus:outline-none bg-white p-2 mt-40 md:mt-32"
          >
            <VariantCircleRemove className="w-24 h-24" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CartModal;
