import Product, { PRODUCT_TYPE } from '@common/models/Product';
import Variant from '@common/models/Variant';
import { CartSliceType } from '@common/store/cart/slice';
import { computeProductHash } from '@common/utils';
import Button from '@lib/Button';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

interface SelectVariantsProps {
  product: Product;
  selectedOptions: Array<number>;
  setSelectedOptions: (selectedOptions: Array<number>) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
}

export interface UseVariantReturnType {
  selectedOptions: number[];
  setSelectedOptions: (selectedOptions: number[]) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  selectedVariant: Variant;
  selectedVariantCartQuantity?: number;
}

export interface UseVariantArgs {
  product: Product;
  cart: CartSliceType;
  variantSetToMinQuantity?: boolean;
  noInitialVariant?: boolean;
  noInitialQuantity?: boolean;
}

export const useVariant = ({
  product,
  cart,
  variantSetToMinQuantity,
  noInitialVariant,
  noInitialQuantity,
}: UseVariantArgs): UseVariantReturnType => {
  const [selectedOptions, setSelectedOptions] = useState(
    product?.optionNames?.map(() => (noInitialVariant ? -1 : 0) as number),
  );

  const [quantity, setQuantity] = useState(noInitialQuantity ? -1 : 1);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search);

      setSelectedOptions(
        product?.optionNames?.map((option, i) => {
          const variantValue = urlSearchParams?.get(option);
          return variantValue ? product?.allOptionValues[i].indexOf(variantValue) : noInitialVariant ? -1 : 0;
        }),
      );
    }
  }, [product?.optionNames, product?.allOptionValues, noInitialVariant]);

  const selectedVariant = product?.variants?.find((variant) => {
    const matches = variant.optionValues
      .map(
        (value, index) =>
          value === product?.allOptionValues[index]?.[selectedOptions?.length > 0 ? selectedOptions[index] : index],
      )
      .filter((value) => value);
    return matches.length === variant.optionValues.length;
  });

  const selectedVariantCartQuantity = selectedVariant?.id
    ? cart?.products[computeProductHash(selectedVariant)]?.cartQuantity ?? 0
    : null;

  useEffect(() => {
    if (noInitialQuantity) {
      setQuantity(-1);
    } else if (selectedVariantCartQuantity != null) {
      if (variantSetToMinQuantity) {
        setQuantity(selectedVariantCartQuantity >= selectedVariant.minQuantity ? 1 : selectedVariant.minQuantity);
      } else {
        setQuantity(1);
      }
    }
  }, [selectedVariant, selectedVariantCartQuantity]);

  return {
    selectedOptions,
    setSelectedOptions,
    quantity,
    setQuantity,
    selectedVariant,
    selectedVariantCartQuantity,
  };
};

const SelectVariants: React.FC<SelectVariantsProps> = ({
  product,
  selectedOptions,
  setSelectedOptions,
  quantity,
  setQuantity,
}) => {
  return (
    <>
      {product.optionNames?.map((optionName, index) => (
        <div key={optionName} className="mb-8" font-customisation="para-text">
          <div className="flex items-center mb-8">
            <div
              className="text-variant-label text-14 mr-4"
              color-customisation="page-body-text"
              id={'variant-type-' + (index + 1)}
            >
              {optionName}:
            </div>
            <div
              className="text-16 font-bold"
              color-customisation="page-body-text"
              id={
                'variant-' +
                product?.allOptionValues[index]?.[selectedOptions[index]]?.toLowerCase().split(' ').join('-')
              }
            >
              {product?.allOptionValues[index]?.[selectedOptions[index]]}
            </div>
          </div>
          <div className="flex flex-wrap">
            {product?.allOptionValues[index]?.map((optionValue, optionIndex) => (
              <div
                key={optionValue}
                className={classnames(
                  'cursor-pointer rounded	md:rounded-none text-14 mb-12 px-12 py-8 py-8 md:px-16 md:py-10 mr-8',
                  {
                    'text-white bg-variant-selectedBg': optionIndex === selectedOptions[index],
                    'text-variant-label bg-variant-bg': optionIndex !== selectedOptions[index],
                  },
                )}
                onClick={() => {
                  const newSelectedOptions = [...selectedOptions];
                  newSelectedOptions[index] = optionIndex;
                  setSelectedOptions(newSelectedOptions);
                }}
                color-customisation="page-body-text"
                id={'type' + (index + 1) + '-v' + (optionIndex + 1)}
              >
                {optionValue}
              </div>
            ))}
          </div>
        </div>
      ))}
      {product.type !== PRODUCT_TYPE.DIGITAL ? (
        <div font-customisation="para-text">
          <div className="flex items-center mb-8">
            <div className="text-variant-label text-14 mr-4" color-customisation="page-body-text">
              Quantity:
            </div>
            <div className="text-16 font-bold" color-customisation="page-body-text">
              {quantity}
            </div>
          </div>
          <div className="flex text-15 text-quantityButton-text">
            <Button
              className="leading-7 md:leading-8 relative text-24 font-medium focus:outline-none h-32 w-32 md:h-36 md:w-50 rounded-l-sm rounded-r-none border-quantityButton-border border disabled:cursor-not-allowed disabled:text-border"
              onClick={() => setQuantity(Math.max(quantity - 1, 1))}
              disabled={quantity === 1}
            >
              <div className="absolute top-0 bottom-0 left-0 right-0 -mt-2" id="quantity-decrement">
                –
              </div>
            </Button>
            <Button
              className="text-20 font-semibold focus:outline-none h-32 w-32 md:h-36 md:w-50 rounded-r-sm rounded-l-none border-quantityButton-border border-r border-t border-b"
              onClick={() => setQuantity(quantity + 1)}
            >
              <div id="quantity-increment">+</div>
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SelectVariants;
