// import { ReactComponent as SearchIcon } from '@common/icons/Search.svg';
import CustomLink from '@common/components/CustomLink';
import StoreInfo from '@common/models/StoreInfo';
import { RouteMap } from '@common/RouteMap';
import { RootState } from '@common/store';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { fetchProductsRequest, getProductQueryParamsInitialState } from '@common/store/products/slice';
import { fetchSearchRequest, SearchSliceType } from '@common/store/search/slice';
import { UserProfileSliceType } from '@common/store/userProfile/slice';
import { isBrowser } from '@common/utils';
import { handleSignOut } from '@common/utils/auth';
// import { handleSignOut } from '@common/utils/auth';
import { computeRedirectUrl } from '@common/utils/computation';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { getBuyerJWTToken } from '@common/utils/token';
import FilterBar from '@components/FilterBar';
import Search from '@components/Search';
import Cart from '@containers/Cart';
// import Back from '@images/BackArrow.svg';
import CartImg from '@images/CartImg.svg';
import Close from '@images/Close.svg';
// import { ReactComponent as CloseImg } from '@images/Close.svg';
// import Contact from '@images/Chat.svg';
import { ReactComponent as MenuIcon } from '@images/Menu.svg';
import NoUser from '@images/no-profile.png';
// import Track from '@images/Target.svg';
import searchImg from '@images/Searching.svg';
import User from '@images/UserLogo.png';
import Image from '@lib/Image';
import Link from '@lib/Link';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import classnames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getActiveNavbar } from '../../../../common/utils';
import CartModal from './Cart';
export interface NavBarProps {
  userProfile: UserProfileSliceType;
  cart: CartSliceType;
  storeInfo: StoreInfo;
  filterBar: FilterBarSliceType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  fetchSearchRequest: typeof fetchSearchRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  search: Record<string, SearchSliceType>;
  categories: CategoriesSliceType;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  filterBarType?: string;
  storeType: string;
  features: FeaturesSliceType;
}

const Navbar: React.FC<NavBarProps> = ({
  storeInfo,
  fetchSearchRequest,
  search,
  cart,
  features,
  categories,
  filterBar,
  filterBarType,
}) => {
  const [ShowSearch, SetShowSearch] = useState(false);
  const products = Object.values(cart.products);
  const totalProducts = products.reduce((sum, product) => sum + product.cartQuantity, 0);
  const redirectUrl = computeRedirectUrl();
  const [showCartModal, setShowCartModal] = useState(false);
  const [focus, setfocus] = useState(false);
  const [isMobileSidebarShown, setIsMobileSidebarShown] = useState(false);
  if (!categories.isFetched) {
    fetchCategoriesRequest({
      queryParams: {
        ...getCategoryQueryParamsInitialState(),
        ordering: 'created',
      },
    });
  }

  return (
    <>
      {showCartModal ? (
        <CartModal
          heading=""
          closeModal={() => {
            setShowCartModal(false);
          }}
          zIndex="z-999"
          className={classnames(
            'container px-0 zoomIn rounded-md showClose relative md:w-fit mx-0 mt-50 md:my-0 z-999',
          )}
        >
          <Cart hideShowCartModal={() => setShowCartModal(false)} />
        </CartModal>
      ) : null}
      <div
        className={classnames('w-full z-40 ', {
          'bg-white': filterBarType != 'home',
        })}
        color-customisation="header-bg"
      >
        <div
          className={classnames('', {
            'fixed inset-0 bg-textColor bg-opacity-70 z-80 w-full h-full': focus,
          })}
        ></div>
        <div
          className={classnames(
            'flex justify-between items-center container pl-15 pr-15 md:pl-42 md:pr-30 xl:pr-42 mx-auto relative',
            {
              'md:h-80': filterBarType == 'home',
              'md:h-70 py-10': filterBarType != 'home',
            },
          )}
        >
          <div
            className={classnames('flex flex-col md:flex-row w-full gap-10', {
              'md:h-160 -mt-40 md:-mt-80 items-end': filterBarType == 'home',
              'md:h-70 items-center': filterBarType != 'home',
            })}
          >
            <div
              className={classnames('w-full md:w-fit flex justify-center md:justify-start', {
                ' hidden': filterBarType != 'home',
              })}
            >
              <Link to="/" className="flex items-center cursor-pointer" id="header-logo">
                {storeInfo?.logo ? (
                  <div className="border-4 cursor-pointer h-80 md:min-h-160 w-80 md:min-w-160 border-white">
                    <img src={storeInfo?.logo} alt={storeInfo?.storename} className="h-full w-full" />
                  </div>
                ) : (
                  <div className="border-4 cursor-pointer h-80 md:h-160 w-80 md:w-160 border-white">
                    <img src={NoUser} alt="profile" className="h-full w-full" />
                  </div>
                )}
              </Link>
            </div>
            <div
              className={classnames('flex  gap-20 md:gap-0  items-center justify-between w-full', {
                ' flex-col md:flex-row mb-30 md:mb-0': filterBarType == 'home',
                ' flex-row': filterBarType != 'home',
              })}
            >
              <div
                className={classnames('font-normal text-storeName', {
                  'text-30 md:text-40': filterBarType == 'home',
                  'text-18 uppercase font-bold': filterBarType != 'home',
                })}
              >
                <Link to="/">{storeInfo?.storename}</Link>
              </div>
              <div className="flex gap-10 md:gap-20 justify-center md:justify-end items-center">
                <div
                  className={classnames({
                    ' hidden md:flex': filterBarType != 'home',
                    ' flex ': filterBarType == 'home',
                    'gap-10 md:gap-20': true,
                  })}
                >
                  {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                    <a
                      href={RouteMap.CONTACT}
                      className="text-nav text-16 md:h-70 md:flex md:items-center"
                      font-customisation="section-heading"
                      color-customisation="header-links"
                      id="footer-contact-us"
                    >
                      Contact
                    </a>
                  ) : null}
                  <div className="">
                    {isBrowser() && window.location.pathname.includes('account/login') ? null : (
                      <div>
                        {categories.isFetched ? (
                          <FilterBar
                            className=""
                            categories={categories?.categories?.results?.slice(0, 1) || []}
                            products={products[PRODUCTS_KEY.ALL]}
                            fetchProductsRequest={(overrideParams) =>
                              fetchProductsRequest({
                                storeKey: PRODUCTS_KEY.ALL,
                                queryParams: {
                                  ...getProductQueryParamsInitialState(),
                                  ...overrideParams,
                                },
                              })
                            }
                          />
                        ) : null}
                      </div>
                    )}
                  </div>

                  {features?.features?.buyerLogin && (
                    <div className="md:h-70 md:flex md:items-center">
                      {!getBuyerJWTToken() ? (
                        <div className="cursor-pointer text-center">
                          <CustomLink link={`/account/login?redirect_url=${redirectUrl}`}>
                            <p
                              className="text-textColor text-16 leading-19"
                              font-customisation="section-heading"
                              color-customisation="header-links"
                              id="header-log-in"
                            >
                              <img src={User} alt="user" className="min-w-20 h-20" />
                            </p>
                          </CustomLink>
                        </div>
                      ) : (
                        <div className="text-center cursor-pointer">
                          <CustomLink link={RouteMap.ACCOUNT_PROFILE}>
                            <div
                              className="text-textColor text-16 leading-19"
                              font-customisation="section-heading"
                              color-customisation="header-links"
                            >
                              <img src={User} alt="user" className="min-w-20 h-20" />
                            </div>
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="ml-40 m-auto text-center cursor-pointer"
                  onClick={() => {
                    setShowCartModal(true);
                  }}
                >
                  {/* <Link to={RouteMap.CART}> */}
                  <div className="flex border gap-6 items-center border-nav bg-white justify-center w-120 h-46 rounded-4">
                    <img className="h-26 min-h-26 w-18" src={CartImg} alt="Cart" id="header-cart" />
                    <span className="p-2 text-nav text-center text-16 font-normal">Cart ({totalProducts})</span>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-70 hidden">
          <div className="flex px-16 justify-between h-70 items-center">
            <div className="flex items-center gap-20">
              <MenuIcon
                id="mobile-menu"
                className=""
                onClick={() => {
                  setIsMobileSidebarShown(true);
                }}
              />
              <div className="flex items-center">
                <Link to="/" id="mobile-header-logo">
                  {storeInfo?.logo ? (
                    <Image
                      noDefaultDimensions
                      src={storeInfo?.logo}
                      alt={storeInfo?.storename}
                      className="h-48 w-111 rounded"
                    />
                  ) : (
                    <h1 className="font-medium text-20 text-textColor">{storeInfo?.storename}</h1>
                  )}
                </Link>
              </div>
            </div>

            <div className="flex items-center justify-end">
              <div
                onClick={() => {
                  SetShowSearch(true);
                  setfocus(true);
                }}
              >
                <img id="mobile-pre-search-icon" src={searchImg} alt="search" className="h-28 mr-20" />
              </div>
              <div className="flex flex-col relative items-center mr-8">
                <Link to={RouteMap.CART}>
                  <img src={CartImg} alt="Cart" className="h-28 lg:h-16 m-auto" id="mobile-cart" />
                  {totalProducts ? (
                    <div
                      className={classnames(
                        'bg-yellow_varients p-2 top-m17 right-m13 leading-21 text-12 font-medium text-black rounded-full text-center w-24 h-24 absolute',
                        {},
                      )}
                    >
                      {totalProducts}
                    </div>
                  ) : null}
                </Link>
              </div>
            </div>
          </div>
          {ShowSearch ? (
            <OutsideClickHandler
              onOutsideClick={() => {
                setfocus(false);
                SetShowSearch(false);
              }}
            >
              <div
                className={classnames('flex relative lg:hidden', {
                  'z-999': focus,
                })}
              >
                <Search
                  className="rounded-lg mx-10 lg:mx-16"
                  fetchSearchRequest={fetchSearchRequest}
                  search={search}
                  closeSearch={() => {
                    SetShowSearch(false);
                  }}
                  setfocus={(value) => {
                    setfocus(value);
                  }}
                />
              </div>
            </OutsideClickHandler>
          ) : null}
        </div>
        {isMobileSidebarShown ? (
          <div>
            <div
              className="fixed z-20 inset-0 overflow-y-auto min-h-screen transition-opacity bg-navbar cursor-pointer"
              onClick={() => {
                setIsMobileSidebarShown(false);
              }}
            ></div>
            <div
              className={`container overflow-y-auto mx-auto w-369 bg-navbar z-50 absolute left-0 top-0 px-24 py-16 h-screen`}
            >
              <div className="flex justify-between items-center">
                <div className="" id="mobile-sidebar-logo">
                  {storeInfo?.logo ? (
                    <Image
                      noDefaultDimensions
                      src={storeInfo?.logo}
                      alt={storeInfo?.storename}
                      className="h-48 rounded"
                    />
                  ) : (
                    <div className="font-medium text-20 text-textColor">{storeInfo?.storename}</div>
                  )}
                </div>
                <div>
                  <img
                    src={Close}
                    onClick={() => {
                      setIsMobileSidebarShown(false);
                    }}
                  />
                </div>
              </div>
              <div
                className="items-center overflow-y-scroll mt-20 max-h-full-10"
                onClick={(e) => {
                  const targetTextArea = e.target as HTMLTextAreaElement;
                  const validator = targetTextArea.className;
                  if (!validator?.includes('menu-arrow')) {
                    setIsMobileSidebarShown(false);
                  }
                }}
              >
                {categories.isFetched ? (
                  <FilterBar
                    categories={categories?.categories?.results || []}
                    products={products[PRODUCTS_KEY.ALL]}
                    fetchProductsRequest={(overrideParams) =>
                      fetchProductsRequest({
                        storeKey: PRODUCTS_KEY.ALL,
                        queryParams: {
                          ...getProductQueryParamsInitialState(),
                          ...overrideParams,
                        },
                      })
                    }
                  />
                ) : null}
                <CustomLink link={RouteMap.TRACK_ORDER}>
                  <p
                    className={'text-textColor text-18 leading-25 py-14 w-full '}
                    font-customisation="section-heading"
                    color-customisation="header-links"
                    id="mobile-track-order"
                  >
                    Track order
                  </p>
                </CustomLink>
                {features?.features?.buyerLogin && (
                  <>
                    {!getBuyerJWTToken() ? (
                      <div className="py-14">
                        <CustomLink link={`/account/login?redirect_url=${redirectUrl}`}>
                          <p
                            className="text-textColor text-18 leading-25"
                            font-customisation="section-heading"
                            color-customisation="header-links"
                            id="mobile-log-in"
                          >
                            Sign in
                          </p>
                        </CustomLink>
                      </div>
                    ) : (
                      <div className="py-14">
                        <CustomLink link={RouteMap.ACCOUNT_PROFILE}>
                          {isBrowser() && window.location.pathname.includes('account/profile') ? (
                            <div>
                              <span
                                className="text-textColor text-18 leading-25"
                                font-customisation="section-heading"
                                color-customisation="header-links"
                              >
                                Profile
                              </span>
                            </div>
                          ) : (
                            <div className="">
                              <span
                                className="text-textColor text-18 leading-25"
                                font-customisation="section-heading"
                                color-customisation="header-links"
                                id="mobile-profile"
                              >
                                Profile
                              </span>
                            </div>
                          )}
                        </CustomLink>
                      </div>
                    )}
                  </>
                )}
                {getBuyerJWTToken() ? (
                  <>
                    <CustomLink link={'/account/orders'}>
                      <p
                        className={'text-textColor text-18 leading-25 py-14 w-full'}
                        font-customisation="section-heading"
                        color-customisation="header-links"
                        id="mobile-myorders"
                      >
                        My orders
                      </p>
                    </CustomLink>
                    {features?.features?.wishList ? (
                      <CustomLink link={'/account/wishlist'}>
                        <p
                          className={'text-textColor text-18 leading-25 py-14 w-full'}
                          font-customisation="section-heading"
                          color-customisation="header-links"
                          id="mobile-mywishlist"
                        >
                          My Wishlist
                        </p>
                      </CustomLink>
                    ) : null}
                    <CustomLink link={'/account/addresses'}>
                      <p
                        className={'text-textColor text-18 leading-25 py-14 w-full '}
                        font-customisation="section-heading"
                        color-customisation="header-links"
                        id="mobile-myaddress"
                      >
                        My Addresses
                      </p>
                    </CustomLink>
                    {storeInfo?.isReferralEnabled ? (
                      <CustomLink link={'/account/invite'}>
                        <p
                          className={'text-textColor text-18 leading-25 py-14 w-full '}
                          font-customisation="section-heading"
                          color-customisation="header-links"
                        >
                          Referral
                        </p>
                      </CustomLink>
                    ) : null}
                  </>
                ) : null}

                {/* <Link to={RouteMap.ABOUT}>
                <p className={'text-textColor font-normal text-18 leading-25 py-14 w-full '}>About</p>
              </Link>
              <Link to={RouteMap.FAQ}>
                <p className={'text-textColor font-normal text-18 leading-25 py-14 w-full '}>Faq</p>
              </Link>
              {features?.features?.contactUsPage ? (
                <Link to={RouteMap.CONTACT}>
                  <p className={'text-textColor font-normal text-18 leading-25 py-14 w-full '}>Contact</p>
                </Link>
              ) : null} */}
                {getBuyerJWTToken() && (
                  <div
                    className={'text-textColor text-18 leading-25 py-14 w-full '}
                    onClick={handleSignOut}
                    font-customisation="section-heading"
                    color-customisation="header-links"
                    id="mobile-log-out"
                  >
                    Logout
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default connect(
  ({ categories, products, features, filterBar }: RootState) => ({
    categories,
    features,
    products,
    filterBar,
  }),
  {
    fetchCategoriesRequest,
    fetchProductsRequest,
  },
)(Navbar);
