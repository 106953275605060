import React from 'react';

interface SavingsProps {
  percentage: number;
  className?: string;
}

const Savings: React.FC<SavingsProps> = ({ percentage, className }) => (
  <div className={`text-savings rounded-sm px-6 text-14 border border-savings bg-white font-bold ${className}`}>
    {percentage}% off
  </div>
);

export default Savings;
