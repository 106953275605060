import MobileCarousel from '@brainhubeu/react-carousel';
import ProductVideo from '@common/components/ProductVideo';
import ZoomLens from '@common/components/ZoomLens';
import { getYoutubeVideoThumbnail, isMobile, matchYoutubeUrlRegex } from '@common/utils';
import { getLambdaLink } from '@common/utils';
import { PlayButtonIcon } from '@instamojo/icons';
import Image from '@lib/Image';
import WishlistIcon from '@lib/WishlistIcon';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface WishlistType {
  wishListProducts: Array<string>;
  isProductInWishList: boolean;
}
export interface GalleryProps {
  className?: string;
  isZoomEnabled: boolean;
  images: string[];
  onWishListClickHandler?: () => void;
  wishListFeature?: boolean;
  wishlist?: WishlistType;
  imageDescription?: string;
  productVideoUrl: string;
  productVideoEnabled: boolean;
}

const Gallery: React.FunctionComponent<GalleryProps> = ({
  className,
  images = [],
  onWishListClickHandler,
  wishListFeature,
  wishlist,
  imageDescription = '',
  productVideoUrl,
  productVideoEnabled,
}) => {
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(images[0] || '');
  const [selectedVideo, setSelectedVideo] = useState('');
  const [backgroundPosition, setBackgroundPosition] = useState('0px 0px');
  const [mouseEnter, setMouseEnter] = useState(false);
  const [lensPos, setLensPos] = useState({ left: 0, top: 0 });

  useEffect(() => {
    setSelectedImage(images[0]);
  }, [images]);

  const { isProductInWishList } = wishlist;
  const handleMouseMove = (e) => {
    const { left, top, width, height } = imageRef.current.getBoundingClientRect();
    const minX = Math.min(e.pageX - left, width - 100);
    const minY = Math.min(e.pageY - top, height - 100);
    setLensPos({
      left: minX,
      top: minY,
    });
    setBackgroundPosition(`-${minX * 4}px -${minY * 4}px`);
  };
  const zoomedImage = selectedImage.replace(/(.*)(_image\d*_)(.*)/, '$1$2zoom_$3');
  const imagesGallery = [...images];
  if (productVideoEnabled) {
    imagesGallery.push(productVideoUrl);
  }
  return (
    <div className={`w-full md:w-300 flex flex-wrap ${className || ''}`}>
      <div className="relative aspect-ratio border border-page overflow-visible rounded-md w-full md:h-300 md:h-300 md:w-300 mb-10 text-center flex justify-center items-center">
        {selectedVideo ? (
          <ProductVideo
            productVideoUrl={selectedVideo}
            height={isMobile() ? '310' : '300'}
            width={isMobile() ? '310' : '300'}
          />
        ) : (
          <div>
            <figure className="relative ">
              <img
                ref={imageRef}
                className="object-contain max-h-300"
                loading={'lazy'}
                src={getLambdaLink({
                  link: selectedImage,
                  width: 0,
                  height: 0,
                })}
                style={{
                  cursor: 'crosshair',
                }}
                alt={imageDescription}
                onMouseEnter={() => {
                  setMouseEnter(true);
                }}
                onMouseLeave={() => {
                  setMouseEnter(false);
                }}
                onTouchMove={handleMouseMove}
                onMouseMove={handleMouseMove}
              />

              <ZoomLens lensPos={lensPos} mouseEnter={mouseEnter} />
            </figure>
            {isMobile() && (
              <div className="hidden">
                <WishlistIcon
                  className="absolute top-0 right-0 m-8 h-32 w-32 focus:outline-none bg-disabled text-14 font-bold tracking-widest rounded pointer-events-auto max-w-300 flex justify-center items-center disabled:opacity-20"
                  type={isProductInWishList ? 'addWishList' : 'removeWishList'}
                  wishListFeature={wishListFeature}
                  onClick={() => {
                    onWishListClickHandler();
                  }}
                  font-customisation="para-text"
                />
              </div>
            )}
            {mouseEnter && (
              <div
                className="absolute rounded-sm z-50 overflow-hidden"
                style={{
                  left: 320,
                  top: 0,
                  height: '400px',
                  width: '400px',
                  backgroundSize: `${imageRef.current?.width * 4 || 500}px ${imageRef.current?.height * 4 || 500}px`,
                  backgroundImage: `url('${zoomedImage}'), url('${selectedImage}')`,
                  backgroundPosition,
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            )}
          </div>
        )}
      </div>
      {isMobile() ? (
        <div className="container sm:hidden md:hidden p-0">
          {imagesGallery.length > 1 || productVideoEnabled ? (
            <MobileCarousel
              slidesPerPage={4.5}
              slides={imagesGallery.map((image) => {
                return (
                  <>
                    {matchYoutubeUrlRegex(image) ? (
                      <div
                        className="relative"
                        onClick={() => {
                          setSelectedImage('');
                          setSelectedVideo(productVideoUrl);
                        }}
                      >
                        <div className="absolute w-60 h-60 zIndex-999 bg-referral-gray opacity-70 flex items-center justify-center">
                          <PlayButtonIcon size="16" color="#000" />
                        </div>
                        <Image
                          src={getYoutubeVideoThumbnail(productVideoUrl)}
                          alt={imageDescription}
                          className={classnames('mr-12 mb-20 h-60 w-60 rounded border', {
                            'border-gallery-selected shadow-gallerHighlight cursor-not-allowed':
                              selectedVideo === productVideoUrl,
                            'border-gallery-normal cursor-pointer': selectedImage !== productVideoUrl,
                          })}
                          noDefaultDimensions
                          noDefaultFit
                        />
                      </div>
                    ) : (
                      <Image
                        src={getLambdaLink({
                          link: image,
                          width: 0,
                          height: 0,
                        })}
                        alt={imageDescription}
                        className={classnames('mr-12 mb-20 h-60 w-60 rounded border', {
                          'border-gallery-selected shadow-gallerHighlight cursor-not-allowed': selectedImage === image,
                          'border-gallery-normal cursor-pointer': selectedImage !== image,
                        })}
                        key={image}
                        onClick={() => {
                          setSelectedImage(image);
                          setSelectedVideo('');
                        }}
                        noDefaultDimensions
                      />
                    )}
                  </>
                );
              })}
            />
          ) : null}
        </div>
      ) : (
        imagesGallery.map((image) => {
          return (
            <>
              {matchYoutubeUrlRegex(image) ? (
                <div
                  className="relative"
                  onClick={() => {
                    setSelectedImage('');
                    setSelectedVideo(productVideoUrl);
                  }}
                >
                  <div className="absolute w-40 h-40 zIndex-999 bg-referral-gray opacity-70 flex items-center justify-center">
                    <PlayButtonIcon size="16" color="#000" />
                  </div>
                  <Image
                    src={getYoutubeVideoThumbnail(productVideoUrl)}
                    alt={imageDescription}
                    className={classnames('mr-20 mb-20 h-40 w-40 rounded border', {
                      'border-gallery-selected shadow-gallerHighlight cursor-not-allowed': selectedVideo === image,
                      'border-gallery-normal cursor-pointer': selectedVideo !== image,
                    })}
                    noDefaultDimensions
                    noDefaultFit
                  />
                </div>
              ) : (
                <Image
                  src={getLambdaLink({
                    link: image,
                    width: 0,
                    height: 0,
                  })}
                  alt={imageDescription}
                  className={classnames('mr-10 mb-20 h-40 w-40 rounded border', {
                    'border-gallery-selected shadow-gallerHighlight cursor-not-allowed': selectedImage === image,
                    'border-gallery-normal cursor-pointer': selectedImage !== image,
                  })}
                  key={image}
                  onClick={() => {
                    setSelectedImage(image);
                    setSelectedVideo('');
                  }}
                  noDefaultDimensions
                />
              )}
            </>
          );
        })
      )}
    </div>
  );
};

export default Gallery;
