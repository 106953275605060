import classNames from 'classnames';
import React from 'react';

interface EmptyProductCard {
  dimensionW?: string;
}

export const EmptyProductCard: React.FunctionComponent<EmptyProductCard> = ({ dimensionW = 'w-full' }) => {
  return (
    <>
      <div className={classNames(`${dimensionW} h-0`)}></div>
    </>
  );
};
