import ProductBundle from '@common/components/ProductBundle';
import ProductDetailsWrapper from '@common/containers/Wrapper/ProductDetailPage';
// import StoreSpecials from '@common/components/StoreSpecials';
// import { ReactComponent as BreadcrumbRightArrow } from '@common/icons/BreadcrumbRightArrow.svg';
// import { ReactComponent as NextArrow } from '@common/icons/NextArrow.svg';
import { RootState } from '@common/store';
import {
  addBundleProductToCartSuccess,
  addProductToCart,
  CartSliceType,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  fetchProductBundleRequest,
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
} from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast } from '@common/store/toasts/slice';
import { PRODUCTS_KEY } from '@common/utils/constants';
import PageLayout from '@components/PageLayout';
import Product from '@components/Product';
import Rating from '@components/Rating';
import RecentlyViewed from '@components/RecentlyViewed';
// import Share from '@components/Share';
// import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
  productId: string;
  productSlug: string;
}

interface ProductDetailProps extends RouteComponentProps<MatchParams> {
  cart: CartSliceType;
  productDetails: ProductDetailsSliceType;
  products: Record<string, ProductsSliceType>;
  recentlyViewed: RecentlyViewedSliceType;
  addToast: typeof addToast;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  storeInfo: StoreInfoSliceType;
  fetchProductBundleRequest: typeof fetchProductBundleRequest;
  productBundle: typeof ProductDetailsSliceType;
  addBundleProductToCartSuccess: typeof addBundleProductToCartSuccess;
}

class ProductDetails extends React.Component<ProductDetailProps> {
  constructor(props) {
    super(props);
    const productSlug = this.props.match.params.productSlug;
    if (this.props.productDetails.productSlug !== productSlug) {
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          category: undefined,
        },
      });
    }
  }
  componentDidUpdate(prevProps) {
    const previousProductSlug = prevProps.productDetails.productSlug;
    const productSlug = this.props.match.params.productSlug;
    if (previousProductSlug !== productSlug) {
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          category: undefined,
        },
      });
    }
  }

  render() {
    const {
      products,
      productDetails,
      addToast,
      addProductToCart,
      cart,
      addBundleProductToCartSuccess,
      storeInfo,
    } = this.props;

    const product = productDetails.productDetails;
    const cartProductQuantity = cart.quantityByProductId[product?.id] || 0;
    const totalProductsCount = products[PRODUCTS_KEY.ALL]?.list?.results?.length;
    const features = this.props?.features?.features || {};
    return (
      <PageLayout filterBarType="product" showBranding={false}>
        <ProductDetailsWrapper
          RecentlyViewed={RecentlyViewed}
          props={this.props}
          recentlyViewedClassnames={['container px-0 md:px-42 mx-auto hidden', '', '']}
          fetchCategoryReq={false}
          mdpProductPage={true}
          mdpStorePage={true}
        >
          {
            <div className="mt-14">
              <div className="container px-0 md:px-42 mx-auto flex flex-wrap md:flex-no-wrap mb-20">
                {productDetails.isFetched ? (
                  <Product
                    subtractProductFromCart={this.props.subtractProductFromCart}
                    product={product}
                    addProductToCart={addProductToCart}
                    addToast={addToast}
                    cartProductQuantity={cartProductQuantity}
                    isZoomEnabled={false}
                    storeType={this.props.storeInfo.storeInfo?.storeType}
                    storeInfo={this.props.storeInfo.storeInfo}
                    isPaymentsDisabled={
                      !this.props.storeInfo.storeInfo?.paymentsAllowed || !this.props.storeInfo.storeInfo?.isCodEnabled
                    }
                    isStoreInfoFetching={this.props.storeInfo.isFetching}
                    wishListFeature={features?.wishList}
                    productVideoFeature={features?.productVideo}
                  />
                ) : null}
                <div className="w-full hidden md:flex p-16 md:w-1/3 max-h-170 h-fit rounded-md bg-white mt-20 md:mt-0 md:ml-12 flex-col">
                  <span className="text-18 font-bold text-prodTitleTxt mb-14 uppercase">
                    {storeInfo?.storeInfo?.storename}
                  </span>
                  <span className="text-12 text-subtext uppercase">{totalProductsCount} products on store</span>
                  <a href="/">
                    <button className="rounded-md mt-20 uppercase bg-white w-full h-36 font-bold text-12 flex justify-center items-center border border-nav text-nav">
                      VIEW STORE
                    </button>
                  </a>
                </div>
              </div>
              {features.productReview && (
                <div id="product-reviews" className="container px-0 md:px-42 mx-auto md:mb-40">
                  <Rating
                    productSlug={product?.slug}
                    isNextReviewsLoading={productDetails.isNextReviewsLoading}
                    isNextReviewsLoadingFailed={productDetails.isNextReviewsLoadingFailed}
                    nextReviewsUrl={productDetails?.nextReviewsUrl}
                    reviewsCount={productDetails?.reviewsCount}
                    reviews={productDetails.reviews}
                    productId={product?.id}
                  />
                </div>
              )}
              <div className="w-full flex md:hidden p-16 md:w-1/3 max-h-170 h-fit rounded-md bg-white mb-20 md:mt-0 md:ml-12 flex-col">
                <span className="text-18 font-bold text-prodTitleTxt mb-14 uppercase">
                  {storeInfo?.storeInfo?.storename}
                </span>
                <span className="text-12 text-subtext uppercase">{totalProductsCount} products on store</span>
                <a href="/">
                  <button className="rounded-md mt-20 uppercase bg-white w-full h-36 font-bold text-12 flex justify-center items-center border border-nav text-nav">
                    VIEW STORE
                  </button>
                </a>
              </div>
              {productDetails.bundle && (
                <div className="md:w-1140 w-full mx-auto">
                  <ProductBundle
                    productsBundle={productDetails.bundle}
                    productId={product?.id}
                    addToBundleProductInCart={addBundleProductToCartSuccess}
                  />
                </div>
              )}
            </div>
          }
        </ProductDetailsWrapper>
      </PageLayout>
    );
  }
}

export default connect(
  ({ cart, productDetails, products, recentlyViewed, features, storeInfo }: RootState) => ({
    storeInfo,
    cart,
    products,
    productDetails,
    recentlyViewed,
    features,
  }),
  {
    fetchProductDetailsRequest,
    fetchProductReviewsRequest,
    fetchProductsRequest,
    addToast,
    addProductToCart,
    subtractProductFromCart,
    fetchProductBundleRequest,
    addBundleProductToCartSuccess,
  },
)(ProductDetails);
