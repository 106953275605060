import ProductSEOTags from '@common/components/ProductSEOTags';
import { addBundleProductToCartSuccess, subtractProductFromCart } from '@common/store/cart/slice';
import { fetchCategoriesRequest, getCategoryQueryParamsInitialState } from '@common/store/categories/slice';
import {
  fetchProductBundleRequest,
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import { ProductsSliceType } from '@common/store/productList/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { smoothScroll } from '@common/utils';
import Toasts from '@components/Toasts';
import Error from '@lib/Error';
import InjectScript from '@lib/InjectScript';
import Loading from '@lib/Loading';
import MDPInjectScript from '@lib/MDPInjectScript';
import classnames from 'classnames';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MDPPageType } from '../../../models/MDPAppScript';

interface MatchParams {
  productId: string;
  productSlug: string;
}
interface ProductDetailState {
  selected: string;
}

interface ProductDetailProps extends RouteComponentProps<MatchParams> {
  productDetails: ProductDetailsSliceType;
  toasts: ToastWithKeyType[];
  products: Record<string, ProductsSliceType>;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  subtractProductFromCart: typeof subtractProductFromCart;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  addBundleProductToCartSuccess: typeof addBundleProductToCartSuccess;
  fetchProductBundleRequest: typeof fetchProductBundleRequest;
}

class ProductDetailsWrapper extends React.Component<ProductDetailProps, ProductDetailState> {
  /* eslint-disable */
  constructor(props) {
    super(props);
    const { props: currentProps } = this.props;
    const productSlug = currentProps.match.params.productSlug;
    const payload = {
      productSlugs: productSlug,
      location: 'product',
    };
    if (currentProps.productDetails.productSlug !== productSlug) {
      currentProps.fetchProductDetailsRequest(productSlug);
      currentProps.fetchProductBundleRequest(payload);
    }

    if (this.props.fetchCategoryReq) {
      currentProps.fetchCategoriesRequest({
        queryParams: getCategoryQueryParamsInitialState(),
        ordering: 'order',
      });
    }

    smoothScroll(0, 500);
  }

  componentDidUpdate(prevProps: any) {
    const { props: currentProps } = this.props;
    const previousProductSlug = prevProps.props.productDetails.productSlug;
    const productSlug = currentProps.match.params.productSlug;
    const payload = {
      productSlugs: productSlug,
      location: 'product',
    };
    if (previousProductSlug !== productSlug) {
      currentProps.fetchProductDetailsRequest(productSlug);
      currentProps.fetchProductReviewsRequest(productSlug);
      currentProps.fetchProductBundleRequest(payload);
      smoothScroll(0, 500);
    }
  }

  componentDidMount(): void {
    const { props: currentProps } = this.props;
    const productSlug = currentProps.match.params.productSlug;
    currentProps.fetchProductReviewsRequest(productSlug);
  }

  render() {
    const {
      props: currentProps,
      recentlyViewedClassnames,
      showToast,
      toastsClassName,
      RecentlyViewed,
      mdpProductPage,
      mdpStorePage,
    } = this.props;

    const { productDetails, removeToast, addToast, cart, toasts } = currentProps;

    const productId = productDetails.productDetails?.id;

    const product = productDetails.productDetails;

    const seoTags = <ProductSEOTags productDetails={productDetails} />;

    return (
      <>
        {showToast && (
          <div className={classnames(toastsClassName)}>
            <Toasts
              productImage={product?.images[0]}
              type="addedToCart"
              toasts={toasts}
              addToast={addToast}
              removeToast={removeToast}
              Details={true}
            />
          </div>
        )}

        <InjectScript others="product" product={productId} />
        {mdpProductPage && <MDPInjectScript pageType={MDPPageType.PRODUCT_PAGE} />}
        {mdpStorePage && <MDPInjectScript pageType={MDPPageType.STORE_PAGE} />}
        {!productDetails.isFetched ? (
          <Loading />
        ) : productDetails.isFetched && productDetails.error ? (
          <Error message={productDetails.error} />
        ) : (
          <>
            {seoTags}
            {this.props.children}
            {Object.keys(currentProps.recentlyViewed.products).length > 1 && (
              <div className={classnames(recentlyViewedClassnames[0])}>
                <div className={classnames(recentlyViewedClassnames[1])}>
                  <RecentlyViewed
                    className={classnames(recentlyViewedClassnames[2])}
                    products={currentProps.recentlyViewed.products}
                    ignoreProducts={new Set([product?.id])}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default ProductDetailsWrapper;
