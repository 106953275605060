import '@common/styles/tooltip.css';

import { HeartIcon } from '@instamojo/icons';
import TooltipComponent from '@instamojo/tooltip-component';
import classnames from 'classnames';
import React from 'react';

export interface WishlistProps {
  type?: 'addWishList' | 'removeWishList';
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  wishListFeature?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const WishlistIcon: React.FunctionComponent<WishlistProps> = ({
  wishListFeature,
  type,
  className,
  children,
  ...props
}) => {
  const toolTipStyles = {
    textAlign: 'center',
    background: '#333333',
    border: '5px solid white',
  };
  const customButton = type === 'addWishList' ? 'wishlist-button' : '';
  return wishListFeature ? (
    <div className="inline-block">
      <TooltipComponent
        style={toolTipStyles}
        overlay={type === 'addWishList' ? 'Remove from Wishlist' : 'Add to Wishlist'}
      >
        <button
          className={classnames(
            {
              'text-wishlistActive-text bg-wishlistActive-activeBg': type === 'addWishList',
            },
            className,
          )}
          {...props}
          button-customisation={`${customButton}`}
        >
          <HeartIcon className="fill-white" />
          {children}
        </button>
      </TooltipComponent>
    </div>
  ) : null;
};

export default WishlistIcon;
