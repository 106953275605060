import CartPageWrapper from '@common/containers/Wrapper/CartPage';
import { RootState } from '@common/store';
import {
  addProductToCart,
  addPromoCodeToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { fetchProductDetailsRequest, ProductDetailsSliceType } from '@common/store/productDetails/slice';
import { ProductsSliceType } from '@common/store/products/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { fetchPromotionsRequest } from '@common/store/promotions/slice';
import { PromotionsSliceType } from '@common/store/promotions/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { smoothScroll } from '@common/utils';
import CartPage from '@components/Cart';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getProductSlugs } from '../../../../common/utils/productIds';

export interface CartProps {
  products: Record<string, ProductsSliceType>;
  categories: CategoriesSliceType;
  removeToast: typeof removeToast;
  toasts: ToastWithKeyType[];
  productDetails: ProductDetailsSliceType;
  cart: CartSliceType;
  storeInfo: StoreInfoSliceType;
  recentlyViewed: RecentlyViewedSliceType;
  addPromoCodeToCart: typeof addPromoCodeToCart;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  addProductToCart: typeof addProductToCart;
  fetchProductsRequest: typeof fetchProductsRequest;
  subtractProductFromCart: typeof subtractProductFromCart;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  removeProductFromCart: typeof removeProductFromCart;
  fetchPromotionsRequest: typeof fetchPromotionsRequest;
  promotions: PromotionsSliceType;
  hideShowCartModal?: () => void;
}

class Cart extends Component<CartProps> {
  constructor(props) {
    super(props);
    this.props.fetchCategoriesRequest({
      queryParams: getCategoryQueryParamsInitialState(),
      ordering: 'order',
    });
  }
  state = {
    couponCode: '',
    AppliedCoupon: false,
    CouponPopUp: false,
  };

  componentDidMount() {
    smoothScroll(0, 0);
    if (!this.props.promotions.isFetched) {
      this.props.fetchPromotionsRequest();
    }
  }
  render() {
    const productSlug = getProductSlugs(this.props.cart.products);

    return (
      <CartPageWrapper>
        <CartPage
          categories={this.props.categories}
          recentlyViewed={this.props.recentlyViewed}
          promotions={this.props.promotions}
          fetchProductsRequest={this.props.fetchProductsRequest}
          cart={this.props.cart}
          addProductToCart={this.props.addProductToCart}
          subtractProductFromCart={this.props.subtractProductFromCart}
          removeProductFromCart={this.props.removeProductFromCart}
          toasts={this.props.toasts}
          removeToast={this.props.removeToast}
          productSlug={productSlug}
          hideShowCartModal={this.props.hideShowCartModal}
        />
      </CartPageWrapper>
    );
  }
}

export default connect(
  ({ products, cart, storeInfo, toasts, categories, promotions, recentlyViewed, productDetails }: RootState) => ({
    storeInfo,
    toasts,
    promotions,
    products,
    categories,
    cart,
    productDetails,
    recentlyViewed,
  }),
  {
    addProductToCart,
    subtractProductFromCart,
    fetchCategoriesRequest,
    removeProductFromCart,
    fetchPromotionsRequest,
    removeToast,
    fetchProductsRequest,
    addPromoCodeToCart,
  },
)(Cart);
