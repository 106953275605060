import { useConstructor } from '@common/hooks/useConstructor';
import { RootState } from '@common/store';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import {
  CategorySliceType,
  // fetchCategoryRequest
} from '@common/store/category/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import {
  CategoryPriceLimitsSliceType,
  fetchShopAllPriceLimitsRequest,
  setPriceLimitsByCategoryId,
} from '@common/store/priceLimits/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import {
  fetchShopAttributesRequest,
  getShopAttributesQueryParamsInitialState,
  ShopAttributesSliceType,
} from '@common/store/shopAttributes/slice';
import { isBrowser, smoothScroll } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  computeCategoryTags,
  ComputeCategoryTagsReturnType,
  computeFacetTags,
  ComputeFacetTagsReturnType,
  computePriceTag,
  ComputePriceTagReturnType,
  computeSortByOptions,
  ComputeSortByOptionsArgsReturnType,
  computeSortByTag,
} from './utils';

export const ShopAllContext = createContext(({} as unknown) as ShopAllContextType);

interface ShopAllProviderProps {
  children: React.ReactNode;
  sortDefaultName?: string;
  sortDefaultUnselectedName?: string;
  limit?: number;
}

interface ShopAllContextType {
  category: CategorySliceType;
  categories: CategoriesSliceType;
  shopAttributes: ShopAttributesSliceType;
  priceLimits: CategoryPriceLimitsSliceType;
  shopAllProductsSlice: ProductsSliceType;
  computedSortOptions: ComputeSortByOptionsArgsReturnType;
  computedFacetProperties: ComputeFacetTagsReturnType;
  computedPriceProperties: ComputePriceTagReturnType;
  TOTAL_ATTRIBUTES_APPLIED: number;
  computedCategoryProperties: ComputeCategoryTagsReturnType;
  featureSlice: FeaturesSliceType;
}

export const ShopAllProvider: React.FC<ShopAllProviderProps> = ({
  children,
  sortDefaultName,
  sortDefaultUnselectedName,
  limit = 12,
}) => {
  const category = useSelector((state: RootState) => state.category);
  const categories = useSelector((state: RootState) => state.categories);
  const shopAttributes = useSelector((state: RootState) => state.shopAttributes);
  const priceLimits = useSelector((state: RootState) => state.priceLimits);
  const shopAllProductsSlice = useSelector((state: RootState) => {
    return state.products[PRODUCTS_KEY.SHOP];
  });

  const featureSlice = useSelector((state: RootState) => {
    return state.features;
  });

  const dispatch = useDispatch();
  const computedSortOptions = computeSortByOptions({
    shopAllProductsSlice,
    dispatch,
    sortDefaultName,
    sortDefaultUnselectedName,
  });
  const computedFacetProperties = computeFacetTags({ shopAllProductsSlice, dispatch });
  const computedCategoryProperties = computeCategoryTags({ shopAllProductsSlice, dispatch, allCategories: categories });

  const computedPriceProperties = computePriceTag({ shopAllProductsSlice, shopAttributes, dispatch });
  const computedSortByProperties = computeSortByTag({
    shopAllProductsSlice,
    dispatch,
    sortOptions: computedSortOptions.ALL_SORT_OPTIONS,
  });
  const TOTAL_ATTRIBUTES_APPLIED =
    computedFacetProperties.NUMBER_OF_FACETS_APPLIED + (computedPriceProperties.PRICE_TAG ? 1 : 0);

  useConstructor(() => {
    if (!categories.isFetched) {
      dispatch(
        fetchCategoriesRequest({
          queryParams: getCategoryQueryParamsInitialState(),
        }),
      );
    }

    if (!priceLimits.isFetched) {
      dispatch(fetchShopAllPriceLimitsRequest());
    }
  }, []);

  useEffect(() => {
    if (shopAllProductsSlice?.isFetched) {
      dispatch(setPriceLimitsByCategoryId({ ids: shopAllProductsSlice?.currentQueryParams?.category || '' }));
    }
  }, [shopAllProductsSlice?.isFetched]);

  useConstructor(() => {
    dispatch(resetProductsStore(PRODUCTS_KEY.SHOP));
    dispatch(
      fetchProductsRequest({
        storeKey: PRODUCTS_KEY.SHOP,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          limit: limit,
        },
      }),
    );
    if (isBrowser()) {
      dispatch(
        fetchShopAttributesRequest({
          queryParams: getShopAttributesQueryParamsInitialState(),
        }),
      );
    }
    smoothScroll(0, 0);
  }, []);

  return (
    <ShopAllContext.Provider
      value={{
        category,
        shopAttributes,
        shopAllProductsSlice,
        computedSortOptions,
        computedFacetProperties,
        computedPriceProperties,
        TOTAL_ATTRIBUTES_APPLIED,
        categories,
        priceLimits,
        computedCategoryProperties,
        featureSlice,
        computedSortByProperties,
      }}
    >
      {children}
    </ShopAllContext.Provider>
  );
};
