import CategorySeo from '@common/components/CategorySeo';
import ProductCardLoading from '@common/components/ProductCard/ProductCardLoading';
import { RootState } from '@common/store';
import { addProductToCart, subtractProductFromCart } from '@common/store/cart/slice';
import { fetchProductsRequest } from '@common/store/products/slice';
import { addToast } from '@common/store/toasts/slice';
import { PRODUCTS_KEY } from '@common/utils/constants';
// import CategorySidebar from '@components/CategorySidebar';
import PageLayout from '@components/PageLayout';
import ProductCard from '@components/ProductCard';
// import { ReactComponent as BreadcrumbRightArrow } from '@images/WhiteRightArrow.svg';
import Rupee from '@components/Rupee';
import Arrow from '@images/downVector.svg';
import Close from '@images/facetClose.svg';
import Filter from '@images/Filter.svg';
import CartImg from '@images/noProducts.svg';
// import { ReactComponent as BreadcrumbRightArrow } from '@images/sArrow.svg';
import Sort from '@images/Sort.svg';
import Error from '@lib/Error';
import Image from '@lib/Image';
import InfiniteScroll from '@lib/InfiniteScroll';
import InjectScript from '@lib/InjectScript';
// import Link from '@lib/Link';
// import Link from '@lib/Link';
import Loading from '@lib/Loading';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import AttributesModal from './AttributesModal';
import { CategoryContext, CategoryProvider } from './CategoryContext';
import SortByModal, { SortOptions } from './SortByModal';

const Category = () => {
  const {
    categoryId,
    category,
    categoryAttributes,
    priceLimits,
    categoryProductsSlice,
    computedSortOptions: { SELECTED_SORT_OPTION, UNSELECTED_SORT_OPTIONS },
    TOTAL_ATTRIBUTES_APPLIED,
    computedFacetProperties: { FACET_TAGS, onFacetTagClick },
    computedPriceProperties: { PRICE_TAG, onPriceTagClick },
  } = useContext(CategoryContext);
  const cart = useSelector(({ cart }: RootState) => cart);
  const storeInfo = useSelector(({ storeInfo }: RootState) => storeInfo);
  const dispatch = useDispatch();
  const [showAttributesModal, setShowAttributesModal] = useState(false);
  const [showwebAttributesModal, setShowwebAttributesModal] = useState(false);
  const [showSortOptionsModal, setShowSortOptionsModal] = useState(false);
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const productIds = {};
  const noProducts = () => {
    if (
      categoryAttributes?.categoryAttributes?.attribute_data?.results?.length ||
      Object.keys(priceLimits?.priceLimits).length ||
      categoryProductsSlice?.list?.count
    ) {
      return true;
    }
    return false;
  };
  return (
    <PageLayout hideToast filterBarType="category" showBranding={false}>
      <InjectScript others="category" category={categoryId} />
      <div className="container mt-20 flex md:mx-auto min-h-screen px-0 sm:px-16 xl:px-42">
        <CategorySeo categoryName={category?.category?.name} />
        {category?.isFetched && !category?.error ? (
          <Helmet>
            <title>{category?.category?.pageTitle || category?.category?.name}</title>
            {category?.category?.description ? (
              <meta name="description" content={category?.category?.description} />
            ) : null}
          </Helmet>
        ) : null}
        <div
          className={classnames('', {
            'fixed inset-0 mt-100 md:mt-0 right-0 overflow-y-auto bg-textColor bg-opacity-20 z-999': showwebAttributesModal,
          })}
        >
          {!category.isFetched ? (
            <Loading />
          ) : category.isFetched && category.error ? (
            <Error message={category.error} />
          ) : (
            <OutsideClickHandler onOutsideClick={() => setShowwebAttributesModal(false)}>
              <div
                className={classnames(
                  'w-0 fixed top-0 bottom-0 mt-100 md:mt-0 left-0 overflow-y-auto bg-white z-999 shadow-modal',
                  {
                    'w-full md:w-435 p-30': showwebAttributesModal,
                  },
                )}
              >
                {showwebAttributesModal ? (
                  <AttributesModal closeModal={() => setShowwebAttributesModal(false)} />
                ) : null}
              </div>
            </OutsideClickHandler>
          )}
        </div>

        {showAttributesModal ? <AttributesModal closeModal={() => setShowAttributesModal(false)} /> : null}
        {showSortOptionsModal ? <SortByModal closeModal={() => setShowSortOptionsModal(false)} /> : null}

        <div className="relative w-full">
          <div className="flex flex-col w-full">
            <div
              className={classnames('justify-between items-center md:mt-0 mb-20 w-full', {
                'hidden leading-17 ': !noProducts(),
                'hidden md:flex ': noProducts(),
              })}
            >
              <div className="h-40 hidden cursor-pointer items-center ">
                <div
                  onClick={() => {
                    setShowwebAttributesModal(true);
                  }}
                  className="w-90 md:w-88 h-30 md:h-40 border-btnBorder border rounded-3 flex justify-center items-center"
                  button-customisation="page-button"
                >
                  <span
                    className="text-14 md:text-16 font-semibold text-textColor leading-24"
                    font-customisation="para-text"
                    button-customisation="page-button"
                  >
                    Filters
                  </span>
                </div>
              </div>
              <div className="hidden md:flex">
                {!categoryProductsSlice?.isFetching && categoryProductsSlice?.list?.count ? (
                  <h1
                    className="text-textColor leading-22 text-18 font-medium"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    {category?.category?.name}
                  </h1>
                ) : null}
              </div>
              <div
                className={classnames('', {
                  'hidden leading-10': !noProducts(),
                  'justify-between items-center md:mt-0 hidden md:flex': noProducts(),
                })}
              >
                <div
                  className="cursor-pointer focus:outline-none "
                  tabIndex={0}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setIsSortDropdownOpen(false);
                  }}
                >
                  <div className="flex items-center bg-white border border-dropDownBorder rounded-md">
                    <span
                      className="text-13 font-normal text-dropDownText mx-10 "
                      font-customisation="para-text"
                      color-customisation="page-body-text"
                    >
                      Sort By :
                    </span>
                    <div
                      className="text-13 text-dropDownText flex justify-between px-6 w-200 h-40 items-center rounded-3 focus:outline-none focus:h-200 font-normal"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSortDropdownOpen(!isSortDropdownOpen);
                      }}
                    >
                      <span>
                        <span className="" font-customisation="para-text" color-customisation="page-body-text">
                          {SELECTED_SORT_OPTION?.name === 'Sort' ? 'Select' : SELECTED_SORT_OPTION?.name}
                        </span>
                      </span>
                      <div className="cursor-pointer mr-4">
                        <Image
                          className={classnames('', {
                            'transform rotate-180': isSortDropdownOpen,
                          })}
                          src={Arrow}
                          noDefaultDimensions
                          alt="Down"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {isSortDropdownOpen && (
                      <div className="flex absolute flex-col shadow-sm w-200 border border-dropDownBorder bg-white z-40 rounded-3 right-0">
                        {UNSELECTED_SORT_OPTIONS.map((sort) => (
                          <div
                            className="flex text-dropDownText rounded hover:bg-bgLight hover:text-nav text-13 font-normal pl-10 min-h-30 items-center focus:bg-opacity-15 focus:bg-textColor"
                            key={sort.key}
                            onClick={() => {
                              sort.onClick();
                              setIsSortDropdownOpen(false);
                            }}
                            font-customisation="para-text"
                            color-customisation="page-body-text"
                          >
                            <SortOptions item={sort} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="hidden md:mt-16 mb-22 justify-center">
                {!categoryProductsSlice?.isFetching && categoryProductsSlice?.list?.count ? (
                  <div
                    className="text-black leading-18 text-18 font-normal"
                    font-customisation="section-heading"
                    color-customisation="page-heading"
                  >
                    {categoryProductsSlice?.list?.count} {categoryProductsSlice?.list?.count > 1 ? 'results' : 'result'}{' '}
                    for <h1 className="font-semibold inline-block">&quot;{category?.category?.name}&quot;</h1>
                  </div>
                ) : null}
              </div>
              <div className="w-full fixed bottom-0 z-999 left-0 flex md:hidden">
                <div
                  className="border w-full rounded-md h-40 bg-white justify-center items-center border-nav font-semibold text-16 flex"
                  onClick={() => setShowSortOptionsModal(true)}
                >
                  <div>
                    <img src={Sort} alt="Sort" className="m-4" />
                  </div>
                  <div className="ml-10">
                    <span
                      className="text-18 leading-19 font-semibold text-textColor"
                      font-customisation="section-heading"
                      color-customisation="page-heading"
                    >
                      Sort
                    </span>
                    <div
                      className="text-16 hidden leading-16 text-disabled font-semibold"
                      font-customisation="para-text"
                    >
                      {SELECTED_SORT_OPTION?.name === 'Sort' ? 'Select' : SELECTED_SORT_OPTION?.name}
                    </div>
                  </div>
                </div>
                <div
                  className="px-8 hidden cursor-pointer"
                  onClick={() => {
                    setShowAttributesModal(true);
                  }}
                >
                  <div className="flex">
                    <div>
                      <img src={Filter} alt="filter" className="m-4" />
                    </div>
                    <div className="ml-10">
                      <span
                        className="text-18 leading-19 font-semibold text-textColor"
                        font-customisation="section-heading"
                        color-customisation="page-heading"
                      >
                        Filter
                      </span>
                      <div className="text-16 leading-16 text-disabled font-semibold" font-customisation="para-text">
                        Apply filter
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className={classnames('hidden mt-20 mx-20 md:mx-0')}>
              <div
                className={classnames('flex flex-col', {
                  'mb-20 md:mb-30': TOTAL_ATTRIBUTES_APPLIED,
                  'mb-20': !TOTAL_ATTRIBUTES_APPLIED,
                })}
              >
                <div className="flex flex-wrap gap-16 md:gap-10 justify-start">
                  {FACET_TAGS.map((facetTag, index) => (
                    <div
                      key={index}
                      className="flex rounded-full border border-optionBorder px-10 flex-no-wrap text-dark w-fit font-semibold h-30 min-h-30 bg-white justify-between cursor-pointer text-16 leading-22 items-center"
                      onClick={() => {
                        onFacetTagClick(facetTag);
                      }}
                    >
                      <div className="justify-self-start pr-10" font-customisation="para-text">
                        {facetTag.facetValue}
                      </div>
                      <div className="justify-self-end mx-6 ">
                        <img src={Close} alt="close" className="" />
                      </div>
                    </div>
                  ))}
                  {PRICE_TAG ? (
                    <div
                      className="flex rounded-full border border-optionBorder px-10 flex-no-wrap text-dark w-fit font-semibold h-30 min-h-30 bg-white justify-between cursor-pointer text-16 leading-22 items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        onPriceTagClick();
                      }}
                    >
                      <div className="justify-self-start pr-10 flex" font-customisation="para-text">
                        <Rupee value={PRICE_TAG.minPrice} defaultClass={false} /> &nbsp;<span>-</span> &nbsp;
                        <Rupee value={PRICE_TAG.maxPrice} defaultClass={false} />
                      </div>
                      <div className="justify-self-end mx-6 ">
                        <img src={Close} alt="close" className="" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:hidden mb-20 px-16">
            {!categoryProductsSlice?.isFetching && categoryProductsSlice?.list?.count ? (
              <h1
                className="text-textColor text-18 font-medium"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                {category?.category?.name}
              </h1>
            ) : null}
          </div>

          {categoryProductsSlice?.error ? (
            <div className="text-center text-bold border rounded m-100 p-25">{categoryProductsSlice?.error}</div>
          ) : null}
          <div className="container px-0">
            {categoryProductsSlice?.isFetching && !categoryProductsSlice?.fetchedProducts.length ? (
              <div className="flex flex-col md:flex-row justify-between items-center w-full">
                <ProductCardLoading />
                <ProductCardLoading />
                <ProductCardLoading />
                <ProductCardLoading />
              </div>
            ) : (
              <div className="px-16 md:px-0 mb-20">
                {categoryProductsSlice?.fetchedProducts.length ? (
                  <InfiniteScroll
                    dataLength={categoryProductsSlice?.fetchedProducts?.length || 0} //This is important field to render the next data
                    next={() => {
                      dispatch(
                        fetchProductsRequest({
                          storeKey: PRODUCTS_KEY.CATEGORY,
                          queryParams: {
                            ...categoryProductsSlice?.currentQueryParams,
                            ordering: '-created',
                            page: (categoryProductsSlice?.currentQueryParams?.page ?? 0) + 1,
                          },
                        }),
                      );
                    }}
                    hasMore={categoryProductsSlice?.fetchedProducts.length < categoryProductsSlice?.list?.count}
                    loader={<h4></h4>}
                    scrollThreshold={0.8}
                  >
                    <div className="flex flex-wrap items-stretch justify-center md:justify-start md:gap-30">
                      {categoryProductsSlice?.fetchedProducts?.map((product) => {
                        const isProductIdAvailable = productIds[product.id];
                        productIds[product.id] = true;
                        return !isProductIdAvailable ? (
                          <div className="block mb-10 min-w-full md:min-w-312" key={product.id}>
                            <ProductCard
                              addToast={(...args) => dispatch(addToast(...args))}
                              product={product}
                              addProductToCart={(...args) => dispatch(addProductToCart(...args))}
                              subtractProductFromCart={(...args) => dispatch(subtractProductFromCart(...args))}
                              cartProductQuantity={cart.quantityByProductId[product.id] || 0}
                              storeInfo={storeInfo}
                              // dimensionH={'h-145 md:h-280'}
                              // dimensionW={'w-145 md:w-280'}
                              // text={'text-16 md:text-21'}
                              // truncate={18}
                              // padding={''}
                              // shopAll={'shopAll'}
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  </InfiniteScroll>
                ) : categoryProductsSlice?.isFetched && !categoryProductsSlice?.fetchedProducts.length ? (
                  <div className="flex flex-col md:mt-40 justify-center items-center h-60v px-20 md:px-0 w-full">
                    <div className="flex">
                      <img className="w-full min-w-full md:h-360 md:mt-20" src={CartImg} alt="Empty cart" />
                    </div>
                    <div className="mt-20 flex flex-col justify-center items-center">
                      <div
                        className="text-textColor text-21 md:text-28 leading-25 md:leading-28 font-bold"
                        font-customisation="section-heading"
                        color-customisation="page-heading"
                      >
                        Coming Soon!
                      </div>
                      <div
                        className="mt-20 md:mt-12 text-disabled text-17 md:text-16 font-normal leading-24 md:leading-22 font-regular text-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        We are yet to add products to this category.
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

// eslint-disable-next-line
export default () => {
  return (
    <CategoryProvider sortDefaultName="Sort" sortDefaultUnselectedName="Clear All">
      <Category />
    </CategoryProvider>
  );
};
