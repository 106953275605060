import FolderDiamond from '@common/icons/FolderDiamond.svg';
import { trackEvent } from '@common/utils/analytics';
import Image from '@lib/Image';
import Modal from '@lib/Modal';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const callClickedOfferAdMixpanelEvent = (pathname: string, props?: Record<string, unknown>): void => {
  if (Object.keys(props || {}).length) {
    trackEvent('Clicked Offer Ad', {
      feature_name: 'premium_store',
      source: 'storefront',
      ...props,
    });
  }
};

export const callViewedOfferAdsMixpanelEvent = (pathname: string, props?: Record<string, unknown>): void => {
  if (Object.keys(props || {}).length) {
    trackEvent('Viewed Offer Ads', {
      features: 'premium_store',
      source: 'storefront',
      ...props,
    });
  }
};

interface UpgradeModalNudge {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  modalTitle: string;
  modalText?: string;
  modalCtaText?: string;
  planAvailability: string;
  modalCtaUrl?: string;
  commonOfferAdProps?: Record<string, unknown>;
  viewedOfferAdsProps?: Record<string, unknown>;
  clickedOfferAdProps?: Record<string, unknown>;
}

const UpgradeModalNudge: React.FC<UpgradeModalNudge> = ({
  isOpen,
  onClose,
  name,
  modalTitle,
  modalText,
  modalCtaText,
  planAvailability,
  modalCtaUrl,
  commonOfferAdProps,
  viewedOfferAdsProps,
  clickedOfferAdProps,
}) => {
  const location = useLocation();
  useEffect(() => {
    if (isOpen) {
      callViewedOfferAdsMixpanelEvent(location.pathname, {
        ...commonOfferAdProps,
        ...viewedOfferAdsProps,
      });
    }
  }, [isOpen]);

  const handleModalCtaClick = () => {
    const refParam = modalCtaUrl.includes('subscription') ? 'source' : 'ref';
    callClickedOfferAdMixpanelEvent(location.pathname, {
      ...commonOfferAdProps,
      ...clickedOfferAdProps,
    });
    window.open(`${modalCtaUrl}?${refParam}=${name}`, '_blank', 'noopener,noreferrer');
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      noPadding
      closeModal={onClose}
      className="bg-white max-w-360 text-nudge-instamojoText text-14 mx-auto text-center"
      zIndex="z-120"
    >
      <div className="p-24 pb-16">
        <Image noDefaultDimensions className="w-64 h-64 mb-24 mx-auto" src={FolderDiamond} alt="Premium Feature" />
        <div className="mb-8 text-theme leading-48p text-30">{modalTitle}</div>
        <div
          className="nudge-text text-onboarding-heading2 text-left leading-22p"
          dangerouslySetInnerHTML={{ __html: modalText }}
        ></div>
        <div className="mb-16 text-onboarding-heading1 font-semibold text-13 leading-22p">{planAvailability}</div>
        <button
          className="font-medium mb-16 bg-theme text-white h-42 py-12 px-36 flex justify-center items-center w-full rounded"
          onClick={() => {
            handleModalCtaClick();
            onClose();
          }}
        >
          {modalCtaText}
        </button>
        <button
          className="hover:bg-nudge-hoverButton font-medium  text-theme h-42 py-12 px-36 flex justify-center items-center w-full"
          onClick={onClose}
        >
          Ask Me Later
        </button>
      </div>
    </Modal>
  );
};

export default UpgradeModalNudge;
