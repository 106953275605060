import Nudge from '@common/components/Nudge';
import UpgradeNudgeModal from '@common/components/Nudge/UpgradeNudgeModal';
import Toasts from '@common/components/Toasts';
import NudgeList from '@common/models/NudgeList';
import OnboardStatus from '@common/models/OnboardStatus';
import { RootState } from '@common/store';
import { toggleEditMode, updateFilterBarTopPosition } from '@common/store/global/slice';
import { fetchNudgesRequest } from '@common/store/nudges/slice';
import { fetchOnboardStatusRequest } from '@common/store/onboardStatus/slice';
import { addToast, removeToast, ToastWithKeyType } from '@common/store/toasts/slice';
import { isBrowser, isMobile as isMobileUsingAgent } from '@common/utils';
import { NO_OF_ONBOARDING_STEPS } from '@common/utils/constants';
import { inIframe } from '@common/utils/history';
import { isMerchantView } from '@common/utils/token';
import { getMerchantJWTToken } from '@common/utils/token';
import cn from 'classnames';
import queryString from 'query-string';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
const AdminNav = React.lazy(() => import('../Nav/AdminNav'));
const AdminSideBar = React.lazy(() => import('../SideBar/AdminSideBar'));

// To Enable Generic Onboarding feature for a theme, follow these steps
// 1. Use BasicLayout component in the PageLayout as a wrapper.
// 2. Pass the props:
//       navbarHeight -> Height of the NavBar in normal view.
//       navbarMobileHeight -> Height of the NavBar in Mobile view.
//       className -> classNames (Ex: bg-page)
//       navbar -> Navbar component of the theme
// 3. Add css styles required in tailwind.config for the theme
// 4. If needed, use the filterBarTopPosition value to position the FilterBar from the top

type TQuerySearchParams = {
  view?: string;
  sideBar?: string;
  search?: string;
};

const toQuerySearch = ({ view, sideBar, search = '' }: TQuerySearchParams) => {
  const parsedQuery = {
    ...queryString.parse(search),
    ...(view && { view: view }),
    ...(sideBar && { sideBar: sideBar }),
  };
  if (!view && parsedQuery?.view) {
    delete parsedQuery.view;
  }
  if (!sideBar && parsedQuery?.sideBar) {
    delete parsedQuery.sideBar;
  }
  return queryString.stringify(parsedQuery);
};

type BasicLayoutProps = {
  children: React.ReactNode;
  toggleEditMode: typeof toggleEditMode;
  onboardStatus: OnboardStatus;
  fetchOnboardStatusRequest?: typeof fetchOnboardStatusRequest;
  updateFilterBarTopPosition?: typeof updateFilterBarTopPosition;
  isOnboardStatusFetched?: boolean;
  className: string;
  Navbar: React.ReactNode;
  navbarHeight: number;
  navbarMobileHeight: number;
  theme: string;
  fetchNudgesRequest: typeof fetchNudgesRequest;
  nudgeList: NudgeList;
  isNudgesFetched?: boolean;
  demoUser: boolean;
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  toasts: ToastWithKeyType[];
};

const BasicLayout: React.FC<BasicLayoutProps> = ({
  toggleEditMode,
  onboardStatus,
  children,
  fetchOnboardStatusRequest,
  className = '',
  Navbar,
  navbarHeight,
  navbarMobileHeight,
  updateFilterBarTopPosition,
  theme,
  demoUser,
  fetchNudgesRequest,
  nudgeList,
  isOnboardStatusFetched,
  isNudgesFetched,
  toasts,
  addToast,
  removeToast,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { view, sideBar } = queryString.parse(location.search);
  const [sideBarContent, setSideBarContent] = useState(sideBar ? String(sideBar) : null);
  const [viewMode, setViewMode] = useState(view ? String(view) : 'desktop');
  const [setupProgress, setSetupProgress] = useState(0);
  const [showMerchantBar, setShowMerchantBar] = useState(false);
  const [isOnboardStatusTriggered, setIsOnboardStatusTriggered] = useState(false);
  const [nudgeHeight, setNudgeHeight] = useState(0);
  const adminNavHeight = 48;
  const [selectedNudge, setSelectedNudge] = useState(null);

  const isMobile = isMobileUsingAgent() || (isBrowser() && window?.innerWidth < 768);

  useEffect(() => {
    const handleIframeMessage = (e) => {
      const { type, slug } = e.data;
      if (type === 'nudge') {
        const nudge = nudgeList?.nudges.find((nudge) => nudge?.slug === slug);
        setSelectedNudge(nudge);
      }
    };
    window.addEventListener('message', handleIframeMessage);
    return () => window.removeEventListener('message', handleIframeMessage);
  }, [nudgeList]);

  const handleNudgeModalClose = () => {
    setSelectedNudge(null);
  };

  useEffect(() => {
    if (sideBarContent === 'editPage') {
      toggleEditMode(true);
    } else {
      toggleEditMode(false);
    }
  }, [sideBarContent]);

  useEffect(() => {
    if (nudgeList) {
      const isNudgePresent = nudgeList?.nudges.find((nudge) =>
        [
          'upgrade-store-url-paid-plan-desktop',
          'upgrade-store-url-free-plan-desktop',
          'upgrade-store-url-free-plan-mobile-web',
          'upgrade-store-url-paid-plan-mobile-web',
        ].includes(nudge.slug),
      );
      isNudgePresent ? setNudgeHeight(isMobile ? 40 : 56) : setNudgeHeight(0);
    }
  }, [nudgeList]);

  const adminNavWithNudgeHeight = adminNavHeight + nudgeHeight;

  useEffect(() => {
    if (getMerchantJWTToken()) {
      fetchOnboardStatusRequest();
      if (theme === 'cipher' && !isNudgesFetched && !demoUser) {
        fetchNudgesRequest();
      }
    }
  }, [theme]);

  useEffect(() => {
    if (isBrowser()) {
      if (isOnboardStatusFetched && isMerchantView()) {
        if (theme === 'cipher' && (isNudgesFetched || demoUser)) {
          setShowMerchantBar(true);
        } else if (theme !== 'cipher') {
          setShowMerchantBar(true);
        }
      }
    }
  }, [theme, isOnboardStatusFetched, isNudgesFetched]);

  useEffect(() => {
    let top = 0;
    const extraHeight = isMerchantView() && showMerchantBar ? adminNavHeight + nudgeHeight : 0;
    if (isMobile) {
      top = navbarMobileHeight + extraHeight;
    } else {
      top = navbarHeight + extraHeight;
    }
    updateFilterBarTopPosition(top);
  }, [nudgeHeight, showMerchantBar]);

  useEffect(() => {
    if (!inIframe()) {
      window.addEventListener('message', (e) => {
        e?.data?.type === 'toasts/addToast' && addToast(e.data);
      });
    }
    function handleOnFocus() {
      if (isMerchantView()) {
        fetchOnboardStatusRequest();
      }
    }
    window.addEventListener('focus', handleOnFocus);
    return () => {
      window.removeEventListener('focus', handleOnFocus);
    };
  }, []);

  useEffect(() => {
    function handleOnMessage(e) {
      if (e?.data?.location) {
        const { pathname, search } = e?.data?.location;
        history.push({
          pathname: pathname,
          search: toQuerySearch({ view: viewMode, sideBar: sideBarContent, search }),
        });
      }
    }
    window.addEventListener('message', handleOnMessage);
    return () => {
      window.removeEventListener('message', handleOnMessage);
    };
  });

  useEffect(() => {
    if (onboardStatus) {
      let stepsCompleted = 0;
      Object.values(onboardStatus || {}).forEach((value) => {
        if (typeof value == 'boolean' && value) {
          stepsCompleted = stepsCompleted + 1;
        }
      });
      if (!isOnboardStatusTriggered) {
        if (stepsCompleted < NO_OF_ONBOARDING_STEPS && !sideBarContent) {
          setSideBarContent('setup');
        }
        setIsOnboardStatusTriggered(true);
      }
      setSetupProgress((stepsCompleted * 100) / NO_OF_ONBOARDING_STEPS);
      if (stepsCompleted === NO_OF_ONBOARDING_STEPS) {
        if (sideBarContent === 'setup') {
          history.push({
            pathname: location.pathname,
            search: toQuerySearch({ view: viewMode, search: location.search }),
          });
          setSideBarContent(null);
        }
      }
    }
  }, [onboardStatus]);

  const handleSideBarOpen = (sideBar) => {
    setSideBarContent(sideBar);
    history.push({
      pathname: location.pathname,
      search: toQuerySearch({ view: viewMode, sideBar: sideBar, search: location.search }),
    });
  };

  const handleSideBarClose = () => {
    if (setupProgress !== 100 && !isMobile) {
      setSideBarContent('setup');
      history.push({
        pathname: location.pathname,
        search: toQuerySearch({ view: viewMode, sideBar: 'setup', search: location.search }),
      });
    } else if (sideBarContent === 'editPage') {
      window.location.href = `${location.pathname}?${toQuerySearch({ view: viewMode, search: location.search })}`;
    } else {
      setSideBarContent(null);
      history.push({
        pathname: location.pathname,
        search: toQuerySearch({ view: viewMode, search: location.search }),
      });
    }
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    history.push({
      pathname: location.pathname,
      search: toQuerySearch({ view: mode, sideBar: sideBarContent, search: location.search }),
    });
  };

  const path = `${location.pathname}?${toQuerySearch({
    view: viewMode,
    sideBar: sideBarContent,
    search: location.search,
  })}`;

  const renderMobileView = () => {
    return (
      <div style={{ height: `calc(100vh - ${adminNavWithNudgeHeight + 40}px)` }} className="mt-50">
        <iframe
          src={path}
          className="mx-auto shadow border-border"
          name="merchant-mobile-view"
          width="360px"
          height="600px"
        />
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <iframe
        src={path}
        className="mx-auto shadow border-border"
        style={{ height: `calc(100vh - ${adminNavWithNudgeHeight}px)`, paddingTop: 10 }}
        width="100%"
        name="merchant-desktop-view"
      />
    );
  };

  if (showMerchantBar) {
    let extraHeight = 0;
    // if navbar is visible, an extra navbar height of padding is needed.
    // navbar is hidden when sideBarContent or viewMode=mobile in desktop view.
    if (sideBarContent || viewMode === 'mobile') {
      extraHeight = isMobile ? navbarMobileHeight : 0;
    } else {
      extraHeight = isMobile ? navbarMobileHeight : navbarHeight;
    }
    const paddingTop = adminNavWithNudgeHeight + extraHeight;
    return (
      <Suspense fallback={<div></div>}>
        <div className={className} style={{ paddingTop: !inIframe() ? paddingTop : '' }}>
          {!inIframe() && toasts.length > 0 ? (
            <div className="mt-80 md:mt-0">
              <Toasts toasts={toasts} addToast={addToast} removeToast={removeToast} />
            </div>
          ) : null}
          <div className="fixed top-0 z-50">
            {theme === 'cipher' && (
              <div
                className={cn('mx-auto bg-white  border-b border-onboarding-divider', {
                  'md:py-8': nudgeHeight > 0,
                })}
              >
                <div className="mx-auto max-w-940">
                  {isMobile ? (
                    <>
                      <Nudge slug="upgrade-store-url-free-plan-mobile-web" />
                      <Nudge slug="upgrade-store-url-paid-plan-mobile-web" />
                    </>
                  ) : (
                    <>
                      <Nudge slug="upgrade-store-url-paid-plan-desktop" />
                      <Nudge slug="upgrade-store-url-free-plan-desktop" />
                    </>
                  )}
                </div>
              </div>
            )}
            <AdminNav
              onViewModeChange={handleViewModeChange}
              viewMode={viewMode}
              handleSideBarOpen={handleSideBarOpen}
              sideBar={sideBarContent}
              setupProgress={setupProgress}
              fetchOnboardStatusRequest={fetchOnboardStatusRequest}
            />
            {/* Show Navbar in Generic Onboarding Screens if the browser is mobile and
            sidebarContent is absent, and hide if the viewMode is mobile */}
            {(!sideBarContent || isMobile) && viewMode !== 'mobile' && Navbar}
          </div>

          {sideBarContent && (
            <>
              <AdminSideBar
                sideBar={sideBarContent}
                onClose={handleSideBarClose}
                onboardStatus={onboardStatus}
                marginTop={isMobile ? nudgeHeight : adminNavWithNudgeHeight}
                setupProgress={setupProgress}
              />
              <div className="hidden md:block md:ml-360">
                {viewMode === 'desktop' && renderDesktopView()}
                {viewMode === 'mobile' && renderMobileView()}
              </div>
              <div className="md:hidden">{children}</div>
            </>
          )}
          {!sideBarContent && (viewMode === 'mobile' ? renderMobileView() : <div>{children}</div>)}
          <UpgradeNudgeModal
            isOpen={selectedNudge}
            onClose={handleNudgeModalClose}
            name={selectedNudge?.name}
            modalTitle={selectedNudge?.modalTitle}
            modalText={selectedNudge?.modalText}
            modalCtaText={selectedNudge?.modalCtaText}
            planAvailability={selectedNudge?.planAvailability}
            modalCtaUrl={selectedNudge?.modalCtaUrl}
          ></UpgradeNudgeModal>
        </div>
      </Suspense>
    );
  }
  return (
    <div className={className} color-customisation="page-bg">
      <div className="fixed top-0 w-full z-60" color-customisation="header-bg">
        {Navbar}
      </div>
      <div style={{ paddingTop: `${isMobile ? navbarMobileHeight : navbarHeight}px` }}>{children}</div>
    </div>
  );
};

export default connect(
  ({ onboardStatus, storeInfo, nudges, toasts }: RootState) => ({
    onboardStatus: onboardStatus.onboardStatus,
    isOnboardStatusFetched: onboardStatus.isFetched,
    theme: storeInfo.storeInfo?.theme?.name.toLowerCase() || '',
    demoUser: storeInfo.storeInfo?.demoUser,
    nudgeList: nudges.results,
    isNudgesFetched: nudges.isFetched,
    toasts,
  }),
  {
    addToast,
    removeToast,
    toggleEditMode,
    fetchOnboardStatusRequest,
    updateFilterBarTopPosition,
    fetchNudgesRequest,
  },
)(BasicLayout);
