import { ShopAllContext } from '@common/context/ShopAllContext';
import { useProductAttributesForm } from '@common/context/ShopAllContext/hooks';
import { shopAttributesSubmitHandler } from '@common/context/ShopAllContext/utils';
import filter from '@common/images/Filter.svg';
import { isMobile } from '@common/utils';
import ShopAllSidebar from '@components/ShopAllSidebar';
import Close from '@images/Close.svg';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

interface AttributesModalProps {
  closeModal: () => void;
}

const AttributesModal: React.FC<AttributesModalProps> = ({ closeModal }) => {
  const { category, shopAllProductsSlice, shopAttributes, categories } = useContext(ShopAllContext);
  const dispatch = useDispatch();
  const shopAttributesForm = useProductAttributesForm({
    shopAllProductsSlice,
    shopAttributes,
    dispatch,
    shouldFetchOnFacetChange: false,
    shouldFetchOnPriceChange: false,
    categories,
  });

  return (
    <div className="flex flex-col w-full h-full fixed inset-0 md:static bg-white z-80 top-0 p-20 md:p-0 overflow-y-scroll overflow-x-hidden">
      <img
        src={Close}
        alt="close"
        className="h-14 w-14 absolute right-0 top-0 m-16 cursor-pointer hidden md:block"
        onClick={(e) => {
          e.preventDefault();
          closeModal();
        }}
      />
      <div className="flex justify-between md:justify-center items-center pb-20 md:pb-0 mb-40 md:mb-28 mt-14 border-slideBorder border-b md:border-none">
        <div className="h-40 flex items-center justify-center">
          <div
            className=" leading-25 text-21 font-semibold text-textColor"
            font-customisation="section-heading"
            color-customisation="page-heading"
          >
            Filters
          </div>
        </div>
        <div>
          <div className="flex md:hidden items-center min-w-118 h-40 border-optionBorder border rounded-5 justify-center">
            <span
              className="flex cursor-pointer text-14 text-textColor leading-14 font-semibold"
              onClick={() => {
                shopAttributesSubmitHandler({ values: {} });
                closeModal();
              }}
              font-customisation="para-text"
              color-customisation="page-body-text"
            >
              <img src={filter} alt="filter" className="mr-10" />
              Clear All
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-y-scroll h-80v">
        <ShopAllSidebar hideCategory category={category.category} shopAllAttributesForm={shopAttributesForm} />
      </div>
      <br />

      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 md:gap-14 w-full md:mt-50">
        <button
          className="font-semibold text-16 leading-16 text-white h-44 bg-themeBg mb-15 rounded-3"
          onClick={(e) => {
            e.preventDefault();
            shopAttributesSubmitHandler({ values: shopAttributesForm.form.values });
            closeModal();
          }}
          font-customisation="para-text"
          button-customisation="buyNow-button"
        >
          Apply
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (!isMobile()) {
              shopAttributesSubmitHandler({ values: {} });
            }
            closeModal();
          }}
          className="border border-btnBorder font-semibold text-16 leading-16 text-textColor h-44 rounded-3 bg-navbar"
          font-customisation="para-text"
          button-customisation="page-button"
        >
          {isMobile() ? 'Cancel' : 'Clear All'}
        </button>
      </div>
    </div>
  );
};

export default AttributesModal;
