// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import Rupees, { rupees } from '@instamojo/rupee';
import classnames from 'classnames';
import React from 'react';

export interface RupeeProps {
  value?: string;
  defaultClass?: boolean;
}

const Rupee: React.FC<RupeeProps> = ({ value, defaultClass = true }) => {
  return (
    <div className="flex justify-end items-start">
      <div className={classnames('', { 'font-normal': defaultClass })}>
        <Rupees className="" />
      </div>
      <div>{rupees(value, false)}</div>
    </div>
  );
};

export default Rupee;
