import StoreInfo from '@common/models/StoreInfo';
import { RootState } from '@common/store';
import {
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { isMobile } from '@common/utils';
import Rupee from '@components/Rupee';
import Link from '@lib/Link';
import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import Image from '../../../lib-components/Image';
import Product, { computeProductLink, PRODUCT_REFERRED_FROM } from '../../models/Product';

export interface ProductCardProps {
  product: Product;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  dimensionH?: string;
  dimensionW?: string;
  dimensionQ?: string;
  hideQuickView?: boolean;
  hideCTA?: boolean;
  padding?: string;
  referredFrom?: PRODUCT_REFERRED_FROM;
  text?: string;
  shopAll?: string;
  truncate?: number;
  category?: boolean;
  mainDivClassNames?: string;
  imageDivClassNames?: string;
  discountPercentClassNames?: string;
  priceClassName?: string;
  titleClassName?: string;
  discountPriceClassName?: string;
  quickViewDiv?: ReactNode;
  quickViewModal?: ReactNode;
  closeModal: () => void;
  modalClassName: string;
  closeDetails: boolean;
  productDetails: ProductDetailsSliceType;
  infoMainDivClassName?: string;
  includeTaxClassName?: string;
  dimension?: string;
  viewDetailsButton?: ReactNode;
  recentlyViewed?: string;
  shrinkCardSize: string;
  customDiscountComponent?: boolean;
  storeInfo: StoreInfo;
}

const ProductCards: React.FunctionComponent<ProductCardProps> = ({
  product,
  dimensionH = '',
  dimensionW = 'w-full',
  dimension = 'w-full h-full',
  text = '',
  referredFrom,
  padding = 'p-0',
  // truncate = 28,
  category,
  mainDivClassNames = '',
  imageDivClassNames,
  discountPercentClassNames,
  priceClassName,
  titleClassName,
  discountPriceClassName,
  quickViewDiv,
  infoMainDivClassName,
  includeTaxClassName,
  viewDetailsButton,
  recentlyViewed,
  shrinkCardSize,
  customDiscountComponent = false,
  storeInfo,
}) => {
  const computedProductLink = computeProductLink({ product, referredFrom });
  const offPercent = product?.savings?.percentage;
  return (
    <div
      data-product-id={product?.id}
      className={
        isMobile()
          ? recentlyViewed
            ? 'w-132'
            : shrinkCardSize
            ? 'w-160'
            : classnames(`${dimensionW} ${padding} ${mainDivClassNames}`, {})
          : classnames(`${dimensionW} ${padding} ${mainDivClassNames}`, {})
      }
    >
      <div
        className={
          isMobile() && recentlyViewed
            ? 'w-132 relative'
            : classnames(`${dimensionH} relative overflow-hidden ${imageDivClassNames}`)
        }
      >
        <div
          className={isMobile() && recentlyViewed ? 'h-132 w-132 mb-10' : classnames(`${dimensionH} overflow-hidden`)}
        >
          <Link to={referredFrom ? computedProductLink : product?.storeLink} className="cursor-pointer overflow-hidden">
            <Image
              src={product.images[0]}
              alt={product.title}
              className={classnames(`overflow-hidden object-contain ${dimension}`, {
                'rounded-lg': (isMobile() && recentlyViewed) || shrinkCardSize,
              })}
              noDefaultDimensions
              noDefaultFit
            />
            {Number(offPercent) && !customDiscountComponent ? (
              <div className={classnames(`absolute flex justify-center items-center ${discountPercentClassNames}`)}>
                {offPercent}% OFF
              </div>
            ) : null}
          </Link>
        </div>
        {quickViewDiv}
      </div>
      <div
        className={
          isMobile() && recentlyViewed ? 'w-132' : classnames(`flex rounded-b-4 flex-col ${infoMainDivClassName}`)
        }
      >
        <div className="flex-grow" font-customisation="section-heading">
          <Link to={computedProductLink} className="cursor-pointer">
            <div className={`${text} ${titleClassName}`} color-customisation="page-heading">
              {product.title}
            </div>
          </Link>
        </div>
        <div className="flex flex-wrap justify-between flex-col" font-customisation="para-text">
          <div
            className={classnames('flex items-center', {
              ' justify-center md:justify-start': category,
              '': !category,
            })}
          >
            <div className={classnames(`rupeeSymbol ${priceClassName}`, {})} color-customisation="page-body-text">
              <Rupee value={product.finalPrice} />
            </div>
            {product.hasDiscount ? (
              <div className={classnames(`line-through ${discountPriceClassName}`)}>
                <Rupee value={product.price} />
              </div>
            ) : (
              <div className="font-normal text-13">&nbsp;</div>
            )}
            {Number(offPercent) && customDiscountComponent ? (
              <div className={classnames(`flex justify-center items-center ${discountPercentClassNames}`)}>
                {offPercent}% OFF
              </div>
            ) : null}
          </div>
          {viewDetailsButton}
          <div
            className={classnames(`text-12 leading-17 text-disabled mt-2 ${includeTaxClassName}`, {
              'text-center md:text-left': category,
              '': !category,
            })}
          >
            {storeInfo?.storeInfo?.tax_included_inventory ? 'Incl. of taxes' : 'Excl. of taxes'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ storeInfo }: RootState) => ({
    storeInfo,
  }),
  null,
)(ProductCards);
