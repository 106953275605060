import { isMobile } from '@common/utils';
import CategorySidebar from '@components/CategorySidebar';
// import Close from '@images/Close.svg';
import filter from '@images/Filter.svg';
import Error from '@lib/Error';
import Loading from '@lib/Loading';
import OutsideClickHandler from '@lib/OutsideClickHandler';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { CategoryContext } from './CategoryContext';
import { useCategoryAttributesForm } from './CategoryContext/hooks';
import { categoryAttributesSubmitHandler } from './CategoryContext/utils';
interface AttributesModalProps {
  closeModal: () => void;
}

const AttributesModal: React.FC<AttributesModalProps> = ({ closeModal }) => {
  const { category, categoryProductsSlice, categoryAttributes } = useContext(CategoryContext);
  const dispatch = useDispatch();
  const categoryAttributesForm = useCategoryAttributesForm({
    categoryProductsSlice,
    categoryAttributes,
    dispatch,
    shouldFetchOnFacetChange: false,
    shouldFetchOnPriceChange: false,
  });

  return (
    <div className="flex flex-col w-full h-full fixed bg-black md:bg-transparent bg-opacity-70 inset-0 md:static z-80 top-0 overflow-x-hidden">
      <div className="bg-white overflow-y-scroll mt-60 md:mt-0 rounded-t-lg md:rounded-none h-full w-full p-20 md:p-0">
        <OutsideClickHandler onOutsideClick={() => closeModal()}>
          <div className="flex justify-between md:justify-center items-center pb-20 md:pb-0 mb-40 md:mb-28 mt-14 border-slideBorder border-b md:border-none">
            <div className="h-40 flex items-center justify-center">
              <div
                className=" leading-25 text-21 font-bold text-textColor"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                Filters
              </div>
            </div>
            <div>
              {/* {categoryAttributesForm.TOTAL_FORM_ATTRIBUTES_APPLIED ? ( */}
              <div className="flex md:hidden items-center min-w-118 h-40 border-optionBorder border rounded-5 justify-center">
                <span
                  className="flex cursor-pointer text-14 text-textColor leading-14 font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    categoryAttributesSubmitHandler({ values: {} });
                    closeModal();
                  }}
                  font-customisation="para-text"
                  color-customisation="page-body-text"
                >
                  <img src={filter} alt="filter" className="mr-10" />
                  Clear All
                </span>
              </div>
              {/* ) : null} */}
            </div>
          </div>

          {!category.isFetched ? (
            <Loading />
          ) : category.isFetched && category.error ? (
            <Error message={category.error} />
          ) : (
            <div className="overflow-y-scroll h-50v md:h-70v">
              <CategorySidebar
                hideCategory
                category={category.category}
                categoryAttributesForm={categoryAttributesForm}
              />
            </div>
          )}
          <br />

          <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 md:gap-14 w-full md:mt-50">
            <button
              className="font-semibold text-16 leading-16 text-white h-44 bg-themeBg mb-15 rounded-3"
              onClick={(e) => {
                e.preventDefault();
                categoryAttributesSubmitHandler({ values: categoryAttributesForm.form.values });
                closeModal();
              }}
              font-customisation="para-text"
              button-customisation="buyNow-button"
            >
              Apply
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (!isMobile()) {
                  categoryAttributesSubmitHandler({ values: {} });
                }
                closeModal();
              }}
              className="border border-btnBorder font-semibold text-16 leading-16 text-textColor h-44 rounded-3 bg-navbar"
              font-customisation="para-text"
              button-customisation="page-button"
            >
              {isMobile() ? 'Cancel' : 'Clear All'}
            </button>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default AttributesModal;
