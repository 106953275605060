import ProductBundle from '@common/models/ProductBundle';
import { CloseCircleIcon } from '@instamojo/icons';
import Rupee from '@instamojo/rupee';
import classnames from 'classnames';
import React from 'react';

import ProductBundleCard from '../ProductBundleCard';

export interface ProductBundleProps {
  className?: string;
  priceClassName?: string;
  borderClassName?: string;
  productsBundle: ProductBundle;
  addToBundleProductInCart: (bundle: ProductBundle) => void;
  deletePopupShow: boolean;
  setIsBundleHide: (flag: boolean) => void;
  discountPercentageColor?: string;
}

const ProductBundleData: React.FunctionComponent<ProductBundleProps> = ({
  productsBundle,
  addToBundleProductInCart,
  setIsBundleHide,
  priceClassName,
  className,
  borderClassName,
  discountPercentageColor,
}) => {
  const calcPercentage = Math.round(
    ((productsBundle.bundlePriceWithoutDiscount - productsBundle.bundleDiscountedPrice) /
      productsBundle.bundlePriceWithoutDiscount) *
      100,
  );
  return (
    <>
      <div className="bundle-cart mb-24">
        <span
          onClick={() => {
            window.sessionStorage.setItem('isBundleHide', 'true');
            setIsBundleHide(true);
          }}
          className="inline-block float-right cursor-pointer mt-4"
        >
          <CloseCircleIcon />
        </span>
        <div className="w-full custom-scroll">
          <h3 className="md:text-32 text-18 font-semibold text-productBundle-heading mb-8">{productsBundle.title}</h3>
          <p className="md:text-24 text-15 text-productBundle-gray md:mb-50 mb-16">{productsBundle.description}</p>
          <div className="overflow-y-scroll">
            <div className="flex w-1140 text-center">
              {productsBundle?.products?.map(
                (product) =>
                  product.bundleDetails.primaryProduct && (
                    <div className="w-3/12 opacity-50" key={product.id}>
                      <ProductBundleCard
                        product={product}
                        priceClassName={priceClassName}
                        discountPercentageColor={discountPercentageColor}
                        calcPercentage={calcPercentage}
                        isBaseProduct={true}
                        borderClassName={borderClassName}
                        cartAlign={'justify-center'}
                      />
                    </div>
                  ),
              )}
              <span className="flex min-h-full items-center pr-20 pl-12 text-32">+</span>
              <div className="flex flex-wrap items-stretch bg-productBundle-lightGray p-32 rounded-lg bundleproduct-list">
                {productsBundle?.products?.map(
                  (product) =>
                    !product.bundleDetails.primaryProduct && (
                      <div className="md:block md:w-220 bundle-product" key={product.id}>
                        <ProductBundleCard
                          product={product}
                          priceClassName={priceClassName}
                          discountPercentageColor={discountPercentageColor}
                          calcPercentage={calcPercentage}
                          isBaseProduct={false}
                          cartAlign={'justify-center'}
                        />
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-end mt-50 pt-8 flex-col">
            <div className="flex text-right w-full md:w-7/12 flex-col">
              <div className="flex-row flex justify-between mb-16">
                <div className="text-16 font-medium text-productBundle-grayText">Original Price</div>
                <div className="flex flex-row-reverse items-center">
                  <div className="flex items-center">
                    <div className="text-theme font-medium text-16 md:mr-14 mr-0">
                      <span className="rupee-container text-productBundle-heading" data-testid="rupee-value-container">
                        <Rupee value={productsBundle?.bundlePriceWithoutDiscount} removeSpaceAfterRupee />
                      </span>
                      <p className="text-productBundle-contentLight text-16">
                        All {productsBundle?.products.length} products
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-row flex justify-between mb-16">
                <div className="text-16 font-medium text-productBundle-grayText">Discount</div>
                <div className="flex flex-row-reverse items-center justify-end">
                  <>
                    <div className="flex items-center">
                      <div className="text-productBundle-heading font-medium text-16 md:mr-14">
                        <span className="rupee-container " data-testid="rupee-value-container">
                          <Rupee
                            value={productsBundle.bundlePriceWithoutDiscount - productsBundle.bundleDiscountedPrice}
                            removeSpaceAfterRupee
                          />
                          {/* ₹
                          {Math.round(
                            productsBundle.bundlePriceWithoutDiscount - productsBundle.bundleDiscountedPrice,
                          ).toFixed(2)} */}
                        </span>
                        <p className="text-productBundle-contentLight text-16">{calcPercentage}% on all products</p>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="flex-row flex justify-between">
                <div className="text-18 font-semibold text-productBundle-heading">Total</div>
                <div className="flex flex-row-reverse items-center justify-end">
                  <div className="flex items-center">
                    <div
                      className={classnames(
                        'font-medium text-20 md:mr-14',
                        priceClassName ? priceClassName : 'text-theme',
                      )}
                    >
                      <span className="rupee-container " data-testid="rupee-value-container">
                        <Rupee value={productsBundle?.bundleDiscountedPrice} removeSpaceAfterRupee />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center md:justify-between w-full">
              <button
                type="submit"
                className={classnames(
                  'rounded min-w-260 h-40 px-12 flex justify-center items-center text-white disabled:cursor-not-allowed disabled:bg-disabled mt-24 w-full',
                  className ? className : 'bg-theme',
                )}
                onClick={() => addToBundleProductInCart({ ...productsBundle, location: 'Cart Page' })}
              >
                <span>Add This Deal To Cart</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBundleData;
