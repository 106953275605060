import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-181 min-w-181 min-h-44 py-8 px-12 text-center ripple rounded-5 text-18 md:text-16 leading-19 md:leading-16 text-dark bg-yellowVariant focus:outline-none font-semibold pointer-events-auto flex justify-center items-center disabled:cursor-not-allowed disabled:opacity-50 border border-transparent ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
